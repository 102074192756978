import React, { useContext, useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import Logo from "../../Assets/Images/Login/logo.png"
import WarehouseLogo from "../../Assets/Images/_warehouse.png"
import { BarLoader } from "react-spinners"
import { useNavigate } from "react-router-dom"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { UseGetRequest } from "../../Helpers/hooks/GetRequests"
import { Context } from "../../Helpers/Context/Context"

export default function WarehouseList() {
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const { setWarehouseId } = useContext(Context)
  const [warehouses, setWarehouses] = useState([
    {
      id: 1,
      name: "Warehouse 1",
      logo: WarehouseLogo,
    },
  ])

  // async function fetchWarehouses() {
  //   setWarehouses([])
  //   setLoading(true)
  //   const response = await UseGetRequest("warehouse/get_all_warehouses")
  //   if (response.data) {
  //     const formattedData = response.data.data.map((user) => ({
  //       ...user,
  //       user: `${user.first_name} ${user.last_name}`,
  //       type_value:
  //         user.type === "business_unit_head"
  //           ? "Business Unit Head"
  //           : user.type === "business_unit_director"
  //           ? "Business Unit Director"
  //           : user.type === "finance_1"
  //           ? "Finance 1"
  //           : user.type === "admin"
  //           ? "Admin"
  //           : user.type === "finance_2"
  //           ? "Finance 2"
  //           : user.type === "requester"
  //           ? "Requester"
  //           : "",
  //     }))
  //     setLoading(formattedData)
  //   }
  //   setLoading(false)
  // }

  useEffect(() => {
    // fetchWarehouses()
    setTimeout(() => {
      setLoading(false)
    }, 3000)
  }, [])

  return (
    <div className="landing-page-container">
      <div className="fixed-top landing-page-header">
        <Row className="d-flex flex-row justify-content-center align-items-center">
          <Col
            className="d-flex flex-row text-white justify-content-start align-items-center pe-4 font-primary cursor gap-2 me-5 px-5 pt-3"
            onClick={() => navigate(`/landing-page`)}
          >
            <ArrowBackIcon />
            <p className="font-primary m-0 text-white">BACK TO LANDING</p>
          </Col>
          <Col className="w-full d-flex landing-page-logo ms-2 px-5">
            {/* <img src={Logo} alt="" /> */}
          </Col>
        </Row>
      </div>
      <div className="bu-units-container">
        {loading ? (
          <Row className="d-flex flex-row justify-content-center align-items-center w-full mt-5 pt-5">
            <BarLoader color="white" />
          </Row>
        ) : (
          <Row className="d-flex flex-wrap px-5 pt-2 card-row overflow-scroll">
            {Array.isArray(warehouses) &&
              warehouses.length !== 0 &&
              warehouses.map((warehouse) => (
                <Col
                  key={warehouse.id}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  className="p-3 position-relative"
                  onClick={() => {
                    setWarehouseId(warehouse.id)
                    navigate(`/bu-per-warehouse`)
                  }}
                >
                  <div className="card-container d-flex flex-column justify-content-center align-items-center">
                    <img src={warehouse.logo} alt="" className="m-3" />
                    <h6 className="m-0 px-3 pb-2 text-center font-primary">
                      {warehouse.name}
                    </h6>
                  </div>
                </Col>
              ))}
          </Row>
        )}
      </div>
    </div>
  )
}
