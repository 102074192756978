import React, { useContext, useState } from "react"
import { useLocation } from "react-router-dom"
import { Button, Col, Form, Row, Table } from "react-bootstrap"
import { Select as AntSelect, DatePicker, Select } from "antd"
import { useNavigate, useParams } from "react-router-dom"
import Navbar from "../../Components/Navbar/Navbar"
import trash from "../../Assets/Images/trash.png"
import { refreshPage, toastStyle } from "../../Helpers/Utils/Common"
import toast from "react-hot-toast"
import { UsePostRequest } from "../../Helpers/hooks/UsePostApiCall"
import { UseGetRequest } from "../../Helpers/hooks/GetRequests"
import {
  getAllProjects,
  getLastSubtypes,
} from "../../Helpers/apiCalls/projectApi"
// import InputError from "../../Components/InputError/InputError"
// import { GeneralValidation } from "../../Helpers/Validation/GeneralValidation"
import ReactLoading from "react-loading"
import dayjs from "dayjs"
import { Context } from "../../Helpers/Context/Context"

function JournalEntryForm({ add, edit, view, replicate }) {
  const [isSubmitClicked, setIsSubmitClicked] = useState(false)
  const { state } = useLocation()
  const navigate = useNavigate()
  const { selectedBuId } = useContext(Context)
  const { id, type } = useParams()
  const [openForm, setOpenForm] = useState(add || edit)
  const [inactive, setInactive] = useState(true)
  const [formValues, setFormValues] = useState({})
  const [accountTypeOptions, setAccountTypeOptions] = useState([])
  const [lastSubtype, setLastSubtypes] = useState([])
  const [projectOptions, setProjectOptions] = useState([])
  const [classOptions, setClassOptions] = useState([])
  const [balanced, setBalance] = useState(false)
  const [journalEntries, setJournalEntries] = useState([
    {
      credit: 0,
      debit: 0,
      project_id: "",
      class_id: "",
      account_title: "",
      remarks: "",
    },
    {
      credit: 0,
      debit: 0,
      project_id: "",
      class_id: "",
      account_title: "",
      remarks: "",
    },
  ])

  const [isError, setIsError] = useState({})
  const toggleOpenForm = () => {
    setOpenForm((prevOpenForm) => !prevOpenForm)
  }

  function AddItem() {
    const newItem = [
      {
        credit: 0,
        debit: 0,
        project_id: "",
        class_id: "",
        account_title: "",
        remarks: "",
      },
      {
        credit: 0,
        debit: 0,
        project_id: "",
        class_id: "",
        account_title: "",
        remarks: "",
      },
    ]
    setJournalEntries((prevItems) => [...prevItems, ...newItem])
  }

  function handleItemChange(field, value, index) {
    const tempItems = [...journalEntries]
    if (field === "debit") {
      tempItems[index][field] = value
      if (tempItems[index].debit > 0) {
        tempItems[index].credit = 0
      }
    } else if (field === "credit") {
      tempItems[index][field] = value
      if (tempItems[index].credit > 0) {
        tempItems[index].debit = 0
      }
    } else if (field === "class_id") {
      // update their pair
      if (index % 2 === 1) {
        tempItems[index][field] = value
        tempItems[index - 1][field] = value
      } else {
        tempItems[index][field] = value
        tempItems[index + 1][field] = value
      }
    } else if (field === "project_id") {
      // update their pair
      if (index % 2 === 1) {
        tempItems[index][field] = value
        tempItems[index - 1][field] = value
      } else {
        tempItems[index][field] = value
        tempItems[index + 1][field] = value
      }
    } else if (field === "account_title") {
      // update their pair
      if (index % 2 === 1) {
        tempItems[index][field] = value
        tempItems[index - 1][field] = value
      } else {
        tempItems[index][field] = value
        tempItems[index + 1][field] = value
      }
    } else {
      tempItems[index][field] = value
    }
    setJournalEntries(tempItems)
  }

  function handleRemoveItem(index) {
    const newItemList = [...journalEntries]
    newItemList.splice(index, 1)
    newItemList.splice(index - 1, 1)
    setJournalEntries(newItemList)
  }

  function handleSEChange(e) {
    const { name, value } = e.target
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  function validateJournalEntryBalancing(entries) {
    const totalDebit = entries.reduce(
      (sum, entry) => sum + parseFloat(entry.debit || 0),
      0
    )
    const totalCredit = entries.reduce(
      (sum, entry) => sum + parseFloat(entry.credit || 0),
      0
    )
    return totalDebit === totalCredit
  }

  function handleSubmit() {
    if (add || replicate) handleCreate()
    if (edit || (view && openForm)) handleUpdate()
  }

  /** POST API - Save new opex purchase invoice **/
  async function handleCreate() {
    if (isSubmitClicked) {
      return
    }

    if (validateJournalEntryBalancing(journalEntries) === false) {
      toast.error("CREDIT AND DEBIT MUST BE BALANCE", { style: toastStyle() })
      return
    }

    const tempValues = {
      business_unit_id: selectedBuId,
      date: formValues.date,
      reference_no: formValues.reference_no,
      remark: formValues.remark,
      date: formValues.date ? formValues.date.format("YYYY-MM-DD") : "",
    }

    setIsSubmitClicked(true)
    const tempJournalEntries = {
      debit: journalEntries.map((row) => row.debit),
      credit: journalEntries.map((row) => row.credit),
      // cost_center: journalEntries.map((row) => row.cost_center),
      remarks: journalEntries.map((row) => row.remarks),
      project_ids: journalEntries.map((row) => row.project_id),
      class_ids: journalEntries.map((row) => row.class_id),
      expense_type_ids: journalEntries.map((row) => row.account_title),
    }
    const response = await UsePostRequest(
      "journal_entries/create",
      {
        ...tempValues,
        ...tempJournalEntries,
      },
      false
    )
    if (response.data) {
      toast.success("Successfully created journal entry", {
        style: toastStyle(),
      })
      setTimeout(() => {
        navigate("/journalentry")
      }, 500)
    } else {
      setIsSubmitClicked(false)
      toast.error("Error in creating journal entry", { style: toastStyle() })
    }
    // } else {
    //   toast.error("Required fields cannot be empty", { style: toastStyle() })
    // }
  }

  /** POST API - Edit old opex purchase invoice **/
  async function handleUpdate() {
    if (isSubmitClicked) {
      return
    }
    if (validateJournalEntryBalancing(journalEntries) === false) {
      toast.error("CREDIT AND DEBIT MUST BE BALANCE", { style: toastStyle() })
      return
    }
    // const entryValidation = journalEntries.some(
    //   (entry) =>
    //     entry.debit === "" || entry.credit === "" || entry.account_title === ""
    // )
    // setIsError({ ...isError, journal_entries_content: entryValidation })
    // const tempValues = { ...formValues }
    const tempValues = {
      journal_entry_id: formValues.id,
      date: formValues.date,
      reference_no: formValues.reference_no,
      remark: formValues.remark,
      date: formValues.date ? formValues.date.format("YYYY-MM-DD") : "",
    }

    // if (
    //   GeneralValidation(tempValues, setIsError, requiredFields) &&
    //   !entryValidation
    // ) {
    // tempValues.journal_entry_id = formValues.id
    const tempJournalEntries = {
      debit: journalEntries.map((row) => row.debit),
      credit: journalEntries.map((row) => row.credit),
      // cost_center: journalEntries.map((row) => row.cost_center),
      remarks: journalEntries.map((row) => row.remarks),
      project_ids: journalEntries.map((row) => row.project_id),
      class_ids: journalEntries.map((row) => row.class_id),
      expense_type_ids: journalEntries.map((row) => row.account_title),
    }
    const response = await UsePostRequest(
      "journal_entries/update",
      {
        ...tempValues,
        ...tempJournalEntries,
      },
      false
    )
    if (response.data) {
      toast.success("Successfully updated journal entry", {
        style: toastStyle(),
      })
      setTimeout(() => {
        navigate("/journalentry")
      }, 500)
    } else {
      toast.error("Error in updating journal entry", { style: toastStyle() })
    }
    // } else {
    //   toast.error("Required fields cannot be empty", { style: toastStyle() })
    // }
  }

  async function fetchAllProjects() {
    setProjectOptions([])
    const response = await getAllProjects({})
    if (response.data) {
      const result = response.data.data.map((item) => {
        return {
          value: item.id,
          label: item.name,
        }
      })
      setProjectOptions(result)
      // setAllProjects(response)
    }
  }

  async function fetchLastSubtypes() {
    const response = await getLastSubtypes()
    if (response.data) {
      const ceNos = response.data.data.flatMap((item) => {
        return item.last_types.map((subtype) => ({
          name: "ce_no",
          value: subtype.id,
          label: subtype.name,
        }))
      })
      setLastSubtypes(ceNos)
    } else {
      setLastSubtypes([])
    }
  }

  async function fetchDropdownOptions() {
    const accountTypes = await UseGetRequest(
      "expense_types/get_all_expense_type"
    )

    if (accountTypes.data && Array.isArray(accountTypes.data.data)) {
      let res = []

      accountTypes.data.data.forEach((account) => {
        if (Array.isArray(account.expense_type_subtypes)) {
          const subtypes = account.expense_type_subtypes.map((row) => ({
            value: row.id,
            label: row.name,
          }))
          res = [...res, ...subtypes]
        }
      })

      setAccountTypeOptions(res)
    } else {
      setAccountTypeOptions([])
    }

    const costCenters = await UseGetRequest("cost_centers/search")
    if (costCenters.data) {
      const res = costCenters.data.data.map((item) => {
        return {
          value: item.id,
          label: item.name,
        }
      })
      setClassOptions(res)
    } else {
      setClassOptions([])
    }
  }

  async function fetchDetails(reference_no) {
    const response = await UseGetRequest(
      "journal_entries/get_all_journal_entry",
      { reference_no: reference_no }
    )
    if (response.data) {
      const result = response.data.data[0]
      setFormValues({ ...result, date: dayjs(result.date), reference_no: "" })
      setJournalEntries(result.journal_entry_items)
      setOpenForm(true)
    } else {
      toast.error("Reference Number not found")
      setTimeout(() => {
        refreshPage()
      }, 500)
    }
  }

  React.useEffect(() => {
    if ((edit || view) && state) {
      setFormValues({ ...state, date: dayjs(state.date) })
      setJournalEntries(state.journal_entry_items)
    } else if (state && replicate) {
      fetchDetails(state.reference_no)
    }
  }, [edit, view, state])

  React.useEffect(() => {
    fetchLastSubtypes()
    fetchDropdownOptions()
    fetchAllProjects()
  }, [])

  // React.useEffect(() => {
  //   const requiredFieldKeys = ["reference_no", "date"]
  //   requiredFieldKeys.forEach((key) => {
  //     if (
  //       !formValues[key] ||
  //       (formValues[key] &&
  //         typeof formValues[key] === "string" &&
  //         formValues[key]?.trim() === "")
  //     ) {
  //       setSubmittableForm(false)
  //     }
  //   })
  // }, [formValues])

  // React.useEffect(() => {
  //   const itemInvalid = journalEntries.some(
  //     (entry) =>
  //       entry.debit === "" ||
  //       entry.credit === "" ||
  //       entry.account_title === "" ||
  //       (entry.project_id === "" && entry.class_id === "")
  //   )
  //   setSubmittableForm(!itemInvalid)
  // }, [journalEntries])

  //VALIDATION
  const [submittableForm, setSubmittableForm] = useState(false)
  React.useEffect(() => {
    const requiredFieldKeys = ["reference_no", "date"]

    const hasEmptyRequiredFields = requiredFieldKeys.some(
      (key) =>
        !formValues[key] ||
        (typeof formValues[key] === "string" && formValues[key].trim() === "")
    )

    const hasInvalidJournalEntry = journalEntries.some(
      (entry) =>
        entry.debit === "" ||
        entry.credit === "" ||
        entry.account_title === "" ||
        (entry.project_id === "" && entry.class_id === "")
    )

    const totalDebit = journalEntries.reduce(
      (sum, entry) => sum + parseFloat(entry.debit || 0),
      0
    )
    const totalCredit = journalEntries.reduce(
      (sum, entry) => sum + parseFloat(entry.credit || 0),
      0
    )
    const isBalanced = totalDebit === totalCredit
    setBalance(isBalanced)

    setSubmittableForm(
      !hasEmptyRequiredFields && !hasInvalidJournalEntry && isBalanced
    )
  }, [formValues, journalEntries])

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive)
          }}
          active={"OTHERS"}
        />
      </div>
      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        <div className="row">
          <h1 className="page-title mb-4">
            {(add || replicate) && "ADD JOURNAL ENTRY"}
            {edit && "EDIT JOURNAL ENTRY"}
          </h1>
        </div>

        {/* content */}

        <div className="edit-form mt-2">
          <Row className="pt-3 mb-2">
            <Col xs={3}>
              <span className="edit-label">
                Date <label className="badge-required">{` *`}</label>
              </span>
              <DatePicker
                format="YYYY-MM-DD"
                className="nc-modal-custom-text w-100"
                value={formValues.date}
                onChange={(e) =>
                  setFormValues((prev) => ({
                    ...prev,
                    date: e,
                  }))
                }
                disabled={!openForm}
              />
              {/* <InputError
                isValid={isError.date}
                message={"Request date is required"}
              /> */}
            </Col>
            <Col xs={3}>
              <span className="edit-label">
                Reference No <label className="badge-required">{` *`}</label>
              </span>
              <Form.Control
                className="nc-modal-custom-input"
                number="text"
                name="reference_no"
                value={formValues.reference_no}
                onChange={(e) => handleSEChange(e)}
                disabled={!openForm}
              />
              {/* <InputError
                isValid={isError.reference_no}
                message={"Reference no. is required"}
              /> */}
            </Col>
            {/* <Col xs={3}>
              <span className="edit-label">
                Amount
                <span className="edit-optional px-2"></span>
              </span>
              <Form.Control
                className="nc-modal-custom-input"
                type="number"
                name="amount"
                value={formValues.amount}
                onChange={(e) => handleSEChange(e)}
                disabled
              />
              <InputError
                isValid={isError.amount}
                message={"Amount is required"}
              />
            </Col> */}
            {/* <Col xs={3}>
              <span className="edit-label">
                Particulars
                <span className="edit-optional px-2"></span>
              </span>
              <Form.Control
                className="nc-modal-custom-input"
                number="text"
                name="particulars"
                value={formValues.particulars}
                onChange={(e) => handleSEChange(e)}
                disabled={!openForm}
              />
              <InputError
                isValid={isError.particulars}
                message={"Amount is required"}
              />
            </Col> */}
          </Row>
          {/* <Row className="pt-2">
            <Col xs={3} className="d-flex flex-column">
              <span className="edit-label">Project</span>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                placeholder="Select Project..."
                value={projectValue}
                options={projectOptions}
                disabled={!openForm}
                onChange={(e) => handleSelectChange(e, "project_id")}
              />
            </Col>
            <Col xs={3} className="d-flex flex-column">
              <span className="edit-label">PC Number</span>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                placeholder="Select Project..."
                value={ceValue}
                disabled={!openForm}
                // options={ceOptions}
                options={ceOptions?.map((ce) => ({
                  value: ce.id,
                  label: ce.label,
                }))}
                onChange={(e) => handleSelectChange(e, "ce_number")}
              />
            </Col>
          </Row> */}

          <Row className="mt-4">
            <Col xs={12}>
              <span className="edit-label">
                Remarks
                <span className="edit-optional px-2">(Optional)</span>
              </span>
              <Form.Control
                className="nc-modal-custom-input"
                as="textarea"
                name="remark"
                value={formValues.remark}
                onChange={(e) => handleSEChange(e)}
                disabled={!openForm}
              />
            </Col>
          </Row>

          <Row className="mt-4 pt-3">
            <Col xs={12} md={6}>
              <span className="edit-label mb-2">Journal Entries</span>
              <label className="badge-required">{` *`}</label>
            </Col>
            <Col xs={12} md={6} className="text-end">
              {!balanced && (
                <label className="badge-required">{`Note: Debit and Credit must be balance`}</label>
              )}
            </Col>
          </Row>
          <Row>
            <div className="purchased-items-table-container">
              {journalEntries?.length === 0 ? (
                <span className="">NO ENTRY FOUND</span>
              ) : (
                <>
                  <Table className="purchased-items-table">
                    <thead>
                      <tr>
                        <th>Debit</th>
                        <th>Credit</th>
                        <th>Project Code</th>
                        <th>Class</th>
                        <th>Account Type</th>
                        <th>Remarks</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {journalEntries?.map((item, index) => {
                        return (
                          <tr key={item.id}>
                            <td style={{ width: "15%" }}>
                              <Form.Control
                                type="number"
                                name="debit"
                                className="w-mini"
                                value={item.debit}
                                disabled={
                                  !openForm ||
                                  (item.credit !== "" && item.credit > 0)
                                }
                                onChange={(e) =>
                                  handleItemChange(
                                    "debit",
                                    e.target.value,
                                    index
                                  )
                                }
                              />
                            </td>
                            <td style={{ width: "15%" }}>
                              <Form.Control
                                type="number"
                                name="credit"
                                className="w-mini"
                                value={item.credit}
                                disabled={
                                  !openForm ||
                                  (item.debit !== "" && item.debit > 0)
                                }
                                onChange={(e) =>
                                  handleItemChange(
                                    "credit",
                                    e.target.value,
                                    index
                                  )
                                }
                              />
                            </td>
                            <td className="" style={{ width: "15%" }}>
                              <Select
                                showSearch
                                placeholder="SELECT"
                                className="w-90"
                                optionFilterProp="label"
                                value={item.project_id}
                                options={projectOptions}
                                disabled={!openForm}
                                onChange={(e) =>
                                  handleItemChange("project_id", e, index)
                                }
                              />
                            </td>
                            <td className="" style={{ width: "10%" }}>
                              <Select
                                showSearch
                                placeholder="SELECT"
                                className="w-90"
                                optionFilterProp="label"
                                value={item.class_id}
                                options={classOptions}
                                disabled={!openForm}
                                onChange={(e) =>
                                  handleItemChange("class_id", e, index)
                                }
                              />
                            </td>
                            <td className="" style={{ width: "20%" }}>
                              <Select
                                showSearch
                                placeholder="SELECT"
                                className="w-90"
                                optionFilterProp="label"
                                value={item.account_title}
                                options={lastSubtype}
                                disabled={!openForm}
                                onChange={(e) =>
                                  handleItemChange("account_title", e, index)
                                }
                              />
                            </td>
                            <td>
                              <Form.Control
                                type="text"
                                name="remarks"
                                value={item.remarks}
                                disabled={!openForm}
                                onChange={(e) =>
                                  handleItemChange(
                                    "remarks",
                                    e.target.value,
                                    index
                                  )
                                }
                              />
                            </td>
                            {journalEntries?.length > 2 &&
                              index % 2 === 1 && // check if index is odd
                              openForm === true && (
                                <td>
                                  <img
                                    src={trash}
                                    onClick={() => handleRemoveItem(index)}
                                    className="cursor-pointer"
                                    alt=""
                                  />
                                </td>
                              )}
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                  {openForm && (
                    <Row className="pt-3 PO-add-item">
                      <Button type="button" onClick={() => AddItem()}>
                        Add Item
                      </Button>
                    </Row>
                  )}
                </>
              )}
              {/* <InputError
                isValid={isError.journal_entries}
                message={"Please fill in required fields"}
              /> */}
            </div>
          </Row>

          <div
            className={`d-flex pt-5 pb-3 ${
              view ? "justify-content-between" : "justify-content-end"
            }`}
          >
            {view && (
              <button
                type="button"
                className="button-secondary me-3"
                onClick={toggleOpenForm}
                disabled={formValues.status !== "pending"}
              >
                {!openForm ? " Edit Journal" : " Close Edit"}
              </button>
            )}
            <div className="d-flex flex-row">
              <button
                type="button"
                className="button-secondary me-3"
                onClick={() => navigate("/journalentry")}
              >
                Close
              </button>
              {(add || edit) && !isSubmitClicked && (
                <button
                  type="button"
                  className="button-primary"
                  onClick={() => {
                    formValues["is_save"] = 0
                    handleSubmit()
                  }}
                  disabled={!submittableForm}
                >
                  {type === "for_approval" ? "Done" : "Submit"}
                </button>
              )}
              {(add || edit) && isSubmitClicked && (
                <div className="button-primary d-flex justify-content-center">
                  <ReactLoading
                    type="bubbles"
                    color="#FFFFFF"
                    height={50}
                    width={50}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

JournalEntryForm.defaultProps = {
  add: false,
  edit: false,
  defaultValues: {},
}

export default JournalEntryForm
