import React, { useContext, useState } from "react"
import { Col, Row, Form, Modal, Container } from "react-bootstrap"
import { DatePicker, Modal as AntModal, Row as AntRow } from "antd"
import { useNavigate } from "react-router-dom"
import Navbar from "../../Components/Navbar/Navbar"
import Table from "../../Components/TableTemplate/DataTable"
import { UseGetRequest } from "../../Helpers/hooks/GetRequests"
import { UsePostRequest } from "../../Helpers/hooks/UsePostApiCall"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import {
  capitalizeFirstLetter,
  dateFormat,
  refreshPage,
} from "../../Helpers/Utils/Common"
import toast from "react-hot-toast"
import { toastStyle, selectDropdownStyle } from "../../Helpers/Utils/Common"
import ReplicateModal from "../../Components/Modals/ReplicateModal"
import Select from "react-select"
import { Context } from "../../Helpers/Context/Context"

const { RangePicker } = DatePicker
export default function JournalEntry() {
  let navigate = useNavigate()
  const { selectedBuId } = useContext(Context)
  const [modal, contextHolder] = AntModal.useModal()
  const [inactive, setInactive] = useState(true)
  const [filterConfig, setFilterConfig] = useState({
    business_unit_id: selectedBuId,
    // date_from: moment(dateToday).format('YYYY-MM-DD'),
    // date_to: moment(dateToday).format('YYYY-MM-DD'),
  })
  const [showLoader, setShowLoader] = useState(false)
  const [tableData, setTableData] = useState([])
  const [openReviewModal, setOpenReviewModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState({})
  const [showReplicateModal, setShowReplicateModal] = useState(false)
  const [replicateValue, setReplicateValue] = useState("")

  const confirmDelete = (row) => {
    modal.confirm({
      title: `Are you sure you want to delete this journal entry?`,
      icon: <ExclamationCircleOutlined />,
      content: "You cannot undo this action.",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: () => handleDelete(row.id),
      okType: "danger",
    })
  }

  const confirmPost = (row) => {
    modal.confirm({
      title: `Are you sure you want to post this journal entry?`,
      icon: <ExclamationCircleOutlined />,
      content: "You cannot undo this action.",
      okText: "Post",
      cancelText: "Cancel",
      onOk: () => handlePost(row.id),
      okType: "primary",
    })
  }

  async function handleDelete(id) {
    const response = await UsePostRequest("journal_entries/delete", {
      journal_entry_id: id,
    })
    if (response.data) {
      toast.success("Successfully deleted journal entry", {
        style: toastStyle(),
      })
      setTimeout(() => {
        refreshPage()
      }, 500)
    } else {
      toast.error("Error in deleting journal entry", { style: toastStyle() })
    }
  }

  async function handlePost(id) {
    const response = await UsePostRequest("journal_entries/change_status", {
      journal_entry_id: id,
      new_status: "post",
    })
    if (response.data) {
      toast.success("Successfully posted journal entry", {
        style: toastStyle(),
      })
      setTimeout(() => {
        refreshPage()
      }, 500)
    } else {
      toast.error("Error in posting journal entry", { style: toastStyle() })
    }
  }

  async function fetchTableData() {
    setShowLoader(true)
    setTableData([])
    const response = await UseGetRequest(
      "journal_entries/get_all_journal_entry",
      filterConfig
    )
    if (response.data) {
      var allData = response.data.data.map((data) => {
        let info = data
        info.formatted_status =
          info.status === "post" ? "Posted" : capitalizeFirstLetter(info.status)
        info.entry_date = dateFormat(data.date)
        return info
      })
      setTableData(allData)
    }
    setShowLoader(false)
  }

  React.useEffect(() => {
    fetchTableData()
  }, [filterConfig])

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive)
          }}
          active={"OTHERS"}
        />
      </div>

      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        <Row className="mb-1">
          <Col xs={6}>
            <h1 className="page-title"> JOURNAL ENTRY </h1>
          </Col>
          <Col xs={6} className="d-flex justify-content-end mt-4">
            <Select
              className="dropsearch-filter px-0 py-0 me-2"
              classNamePrefix="react-select"
              placeholder="Select"
              styles={selectDropdownStyle}
              onChange={(e) => {
                if (e.value === "add") {
                  navigate(`/journalentry/add`)
                } else {
                  setShowReplicateModal(true)
                }
              }}
              options={[
                { value: "add", label: "Add Journal Entry" },
                { value: "replicate", label: "Replicate" },
              ]}
            />
          </Col>
        </Row>
        <div className="tab-content mt-2">
          <div className="my-2 mt-2 px-2 PO-filters">
            <RangePicker
              onChange={(e) => {
                if (e) {
                  setFilterConfig((prev) => ({
                    ...prev,
                    date_from: e[0].format("YYYY-MM-DD"),
                    date_to: e[1].format("YYYY-MM-DD"),
                  }))
                } else {
                  setFilterConfig((prev) => ({
                    ...prev,
                    date_from: "",
                    date_to: "",
                  }))
                }
              }}
            />
          </div>
          <div className="below">
            <Table
              tableHeaders={[
                "DATE",
                "REFERENCE NO",
                "STATUS",
                "REMARKS",
                "AMOUNT",
              ]}
              headerSelector={[
                "entry_date",
                "reference_no",
                "formatted_status",
                "remark",
                "amount",
              ]}
              tableData={tableData}
              showLoader={showLoader}
              handleRowClick={(row) => {
                setSelectedRow(row)
                setOpenReviewModal(true)
              }}
            />
          </div>
          <div className="mb-2" />
        </div>

        <Modal
          footer={null}
          show={openReviewModal}
          onHide={() => setOpenReviewModal(false)}
          size="lg"
          centered
        >
          <Modal.Body className="">
            <div className="col-sm-12">
              <span className="custom-modal-body-title">
                REVIEW JOURNAL ENTRY
              </span>
            </div>
            <Container fluid className="modal-cont justify-content-center px-4">
              <Row className="mt-3">
                <Col xs={4}>
                  <span className="review-form-label">Date</span>
                  <Form.Control
                    className="review-form-input"
                    type="date"
                    value={selectedRow.date ?? ""}
                    disabled
                  />
                </Col>
                <Col xs={4}>
                  <span className="review-form-label">Reference No</span>
                  <Form.Control
                    className="review-form-input"
                    type="text"
                    value={selectedRow.reference_no ?? ""}
                    disabled
                  />
                </Col>
                <Col xs={4}>
                  <span className="review-form-label">Amount</span>
                  <Form.Control
                    className="review-form-input"
                    type="text"
                    value={selectedRow.amount ?? ""}
                    disabled
                  />
                </Col>
              </Row>
            </Container>

            <AntRow className="d-flex justify-content-between mt-5 px-4">
              <button
                className="button-primary"
                onClick={() => {
                  navigate("/journalentry/view", { state: selectedRow })
                }}
              >
                View
              </button>
              <div>
                <button
                  onClick={() => {
                    setSelectedRow({})
                    setOpenReviewModal(false)
                  }}
                  className="me-2 button-default"
                >
                  Cancel
                </button>
                {selectedRow.status === "pending" && (
                  <button
                    className="button-warning-fill me-2"
                    onClick={() => {
                      confirmDelete(selectedRow)
                    }}
                  >
                    Delete
                  </button>
                )}
                {selectedRow.status === "pending" && (
                  <button
                    className="button-primary me-2"
                    onClick={() => {
                      setOpenReviewModal(false)
                      navigate("/journalentry/edit", { state: selectedRow })
                    }}
                  >
                    Edit
                  </button>
                )}
                {selectedRow.status === "pending" && (
                  <button
                    className="button-primary"
                    onClick={() => {
                      confirmPost(selectedRow)
                    }}
                  >
                    Post
                  </button>
                )}
              </div>
            </AntRow>
          </Modal.Body>
        </Modal>

        <ReplicateModal
          withButtons
          size="md"
          showViewBtn={true}
          show={showReplicateModal}
          onHide={() => {
            setReplicateValue("")
            setShowReplicateModal(false)
          }}
          onProceed={() => {
            navigate("/journalentry/replicate", {
              state: { reference_no: replicateValue },
            })
          }}
          disableSave={
            !tableData.some((row) => row.reference_no === replicateValue)
          }
        >
          <div>
            <p className="font-primary fs-5">REPLICATE JOURNAL ENTRY</p>
            <Row className="nc-modal-custom-row">
              <Col>
                Reference No. <span className="color-red"> *</span>
                <Form.Control
                  type="text"
                  name="name"
                  className="nc-modal-custom-input"
                  value={replicateValue}
                  onChange={(e) => setReplicateValue(e.target.value)}
                />
              </Col>
            </Row>
          </div>
        </ReplicateModal>
      </div>
      {contextHolder}
    </div>
  )
}
