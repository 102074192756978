import React, { useState, useEffect, useContext } from "react"
import { Col, Form, Row, Modal, Container } from "react-bootstrap"
import { Row as AntRow } from "antd"

import { useNavigate } from "react-router-dom"
import toast from "react-hot-toast"
import Navbar from "../../Components/Navbar/Navbar"
import Table from "../../Components/TableTemplate/DataTable"
import DeleteModal from "../../Components/Modals/DeleteModal"
import Select from "react-select"

// api calls and utils
import {
  refreshPage,
  toastStyle,
  TokenExpiry,
  formatDateNoTime,
  getType,
  formatAmount,
  selectDropdownStyle,
  numberFormat,
} from "../../Helpers/Utils/Common"

// css
import "./Project.css"
import ConfirmPaymentModal from "./ConfirmPaymentModal"
import {
  searchProject,
  deleteProject,
} from "./../../Helpers/apiCalls/projectApi"
import { getAllCustomer } from "./../../Helpers/apiCalls/CustomerAPI"
import { getDistributor } from "../../Helpers/apiCalls/DistributorApi"
import { Context } from "../../Helpers/Context/Context"

export default function Project() {
  let navigate = useNavigate()
  const { selectedBuId } = useContext(Context)
  const userType = getType()
  const [inactive, setInactive] = useState(true)
  const [allData, setAllData] = useState([])
  // const [projects, setProjects] = useState([]);
  const [customers, setCustomers] = useState([])
  const [allDistributors, setAllDistributors] = useState([])
  const [showLoader, setShowLoader] = useState(false)
  /* add payment modal handler */
  const [showAddPaymentModal, setShowAddPaymentModal] = useState(false)
  const handleShowAddPaymentModal = () => setShowAddPaymentModal(true)

  /* add payment modal handler */
  const [showConfirmPaymentModal, setShowConfirmPaymentModal] = useState(false)
  const handleCloseConfirmPaymentModal = () => setShowConfirmPaymentModal(false)

  /* delete modal handler */
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const handleCloseDeleteModal = () => setShowDeleteModal(false)
  const [searchText, setSearchText] = useState("")
  const [openViewModal, setOpenViewModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState({})

  /* FILTER CONFIGS */
  const [filterConfig, setFilterConfig] = useState({
    business_unit_id: selectedBuId,
  })

  async function fetchCustomers() {
    setCustomers([])

    const response = await getAllCustomer()
    if (response.error) {
      if (response.error.data.status !== 404) {
        TokenExpiry(response.error)
      }
    } else {
      var projects = response.data.data.data.map((data) => {
        var info = {}
        info.label = data.name
        info.value = data.id
        return info
      })
      setCustomers([{ label: "All Customers", value: "" }, ...projects])
    }
  }

  async function fetchDistributors() {
    setAllDistributors([])
    const response = await getDistributor()
    if (response.data) {
      const res = response.data.data.map((row) => {
        return {
          value: row.id,
          label: row.name,
        }
      })
      setAllDistributors([{ value: "", label: "All Distributors" }, ...res])
    }
  }

  async function fetchData() {
    setShowLoader(true)
    setAllData([])

    const response = await searchProject(filterConfig)
    if (response.data) {
      var allData = response.data.data.map((data) => {
        return {
          ...data,
          cost_estimate: formatAmount(
            parseFloat(data.ce_amount ?? 0).toFixed(2)
          ),
          balance: formatAmount(
            parseFloat(data.internal_budget) -
              (parseFloat(
                data.project_expense !== null ? data.project_expense : 0
              ) -
                parseFloat(
                  data.expense_budget !== null ? data.expense_budget : 0
                ))
          ),
          expense_budget: formatAmount(data.expense_budget ?? 0),
          internal_budget: formatAmount(data.internal_budget ?? 0),

          actual_expense: formatAmount(
            (
              parseFloat(data.project_expense ?? 0) -
              parseFloat(data.total_expense ?? 0)
            ).toFixed(2)
          ),
        }
      })
      setAllData(allData)
    } else if (response.error.data.status !== 404) {
      TokenExpiry(response.error)
    }
    setShowLoader(false)
  }

  async function handleDeletePI() {
    const response = await deleteProject(selectedRow.id)

    if (response.data) {
      toast.success("Project Deleted Successfully!", {
        style: toastStyle(),
      })
      setTimeout(() => refreshPage(), 1000)
    } else {
      toast.error("Error Deleting Project", {
        style: toastStyle(),
      })
    }
  }

  useEffect(() => {
    // fetchProjects();
    fetchCustomers()
    fetchDistributors()
  }, [])

  useEffect(() => {
    fetchData()
  }, [filterConfig])

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive)
          }}
          active={"PROJECT FOLDER"}
        />
      </div>

      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        <Row className="mb-4">
          <Col xs={6}>
            <h1 className="page-title">PROJECT REGISTER</h1>
          </Col>
          <Col xs={6} className="d-flex justify-content-end">
            <input
              type="search"
              name="name"
              placeholder="Search project..."
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setFilterConfig((prev) => {
                    return { ...prev, name: searchText }
                  })
                }
              }}
              className="search-bar"
            />
            <button
              className="add-btn"
              onClick={() => navigate("/projectfolder/add")}
            >
              Add
            </button>
          </Col>
        </Row>

        <div className="tab-content">
          <div className="my-2 ms-2 PO-filters PI-filters d-flex">
            <Select
              className="dropsearch-filter px-0 py-0 me-2"
              classNamePrefix="react-select"
              placeholder="Select Customer"
              styles={selectDropdownStyle}
              options={customers}
              onChange={(e) => {
                setFilterConfig((prev) => {
                  return { ...prev, customer_id: e.value }
                })
              }}
            />{" "}
          </div>

          <Table
            tableHeaders={[
              "PROJECT NAME",
              "COST ESTIMATE",
              // "CUSTOMER",
              "INTERNAL BUDGET",
              "ACTUAL COST",
              // "COST ESTIMATE",
              "BALANCE",
            ]}
            headerSelector={[
              "name",
              "cost_estimate",
              // "customer_name",
              "internal_budget",
              "actual_expense",
              // "cost_estimate",
              "balance",
            ]}
            tableData={allData}
            // ViewBtn={(row) => ViewBtn(row)}
            // ActionBtn={(row) => ActionBtn(row, "open_bill")}
            showLoader={showLoader}
            // withActionData={false}
            conditionalRowStyles={[
              {
                when: (row) =>
                  parseFloat(row.project_price || 0) >
                  parseFloat(row.expense_budget || 0),
                style: {
                  backgroundColor: "white",
                  color: "red !important",
                },
              },
            ]}
            handleRowClick={(row) => {
              setSelectedRow(row)
              setOpenViewModal(row)
            }}
            isPointer
          />
        </div>
        <div className="mb-2" />
      </div>

      <DeleteModal
        show={showDeleteModal}
        onHide={() => handleCloseDeleteModal()}
        text="project"
        onDelete={() => handleDeletePI()}
      />

      <ConfirmPaymentModal
        // id={projectId}
        // balance={balance}
        // franchiseeID={franchiseeID}
        show={showConfirmPaymentModal}
        onHide={handleCloseConfirmPaymentModal}
        handler={handleShowAddPaymentModal}
      />

      <Modal
        footer={null}
        show={openViewModal}
        onHide={() => setOpenViewModal(false)}
        size="lg"
        centered
      >
        <Modal.Body className="">
          <div className="col-sm-12">
            <span className="custom-modal-body-title">REVIEW PROJECT</span>
          </div>
          <Container fluid className="modal-cont justify-content-center px-4">
            <Row className="mt-3">
              <Col xs={4}>
                <span className="review-form-label">Project Name</span>
                <Form.Control
                  className="review-form-input"
                  type="text"
                  value={selectedRow.name ?? ""}
                  disabled
                />
              </Col>
              <Col xs={4}>
                <span className="review-form-label">Customer</span>
                <Form.Control
                  className="review-form-input"
                  type="text"
                  value={selectedRow.customer_name ?? ""}
                  disabled
                />
              </Col>
              <Col xs={4}>
                <span className="review-form-label">Internal Budget</span>
                <Form.Control
                  className="review-form-input"
                  type="text"
                  value={selectedRow.internal_budget ?? ""}
                  disabled
                />
              </Col>
            </Row>
          </Container>

          <AntRow className="d-flex justify-content-between mt-5 px-4">
            <button
              className="button-primary me-2"
              onClick={() => {
                navigate("print/" + selectedRow.id)
              }}
            >
              View
            </button>
            <div>
              <button
                onClick={() => {
                  setSelectedRow({})
                  setOpenViewModal(false)
                }}
                className="me-2 button-default"
              >
                Cancel
              </button>
              <button
                className="button-warning-fill me-2"
                onClick={() => {
                  setShowDeleteModal(true)
                }}
              >
                Delete
              </button>
              <button
                className="button-primary me-2"
                onClick={() => {
                  setOpenViewModal(false)
                  navigate("edit/" + selectedRow.id)
                }}
              >
                Edit
              </button>
            </div>
          </AntRow>
        </Modal.Body>
      </Modal>
    </div>
  )
}
