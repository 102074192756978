import React, { useContext, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { DatePicker, Table } from "antd"
import Navbar from "../../Components/Navbar/Navbar"
import { formatAmount, toastStyle } from "../../Helpers/Utils/Common"
import { UseGetRequest } from "../../Helpers/hooks/GetRequests"
import dayjs from "dayjs"
import { Context } from "../../Helpers/Context/Context"
import downloadIcon from "../../Assets/Images/download_icon.png"
import toast from "react-hot-toast"
import { UsePostRequest } from "../../Helpers/hooks/UsePostApiCall"

const { RangePicker } = DatePicker
export default function IncomeStatementReport() {
  const [inactive, setInactive] = useState(true)
  const { selectedBuId, primaryColor } = useContext(Context)
  const [exportToExcelLink, setExportToExcelLink] = useState("")
  const [filterConfig, setFilterConfig] = useState({
    date_from: dayjs().startOf("month").format("YYYY-MM-DD"),
    date_to: dayjs().endOf("month").format("YYYY-MM-DD"),
    business_unit_id: selectedBuId,
  })
  const [showLoader, setShowLoader] = useState(false)
  const [tableData, setTableData] = useState([])

  const tableColumns = [
    {
      title: "Account",
      key: "name",
      dataIndex: "name",
      align: "left",
      render: (text, record) => {
        const isCustom = text.toLowerCase().includes("total")
        // const isParents =
        //   text === "assets" || text === "liabilities" || text === "equity"
        return (
          <span
            style={{
              fontWeight: isCustom ? "bold" : "normal",
              textTransform: "uppercase",
              color: isCustom ? primaryColor : "inherit",
            }}
          >
            {text}
          </span>
        )
      },
    },
    {
      title: "Jan",
      key: "jan",
      dataIndex: "jan",
      align: "right",
    },
    {
      title: "Feb",
      key: "feb",
      dataIndex: "feb",
      align: "right",
    },
    {
      title: "Mar",
      key: "mar",
      dataIndex: "mar",
      align: "right",
    },
    {
      title: "Apr",
      key: "apr",
      dataIndex: "apr",
      align: "right",
    },
    {
      title: "May",
      key: "may",
      dataIndex: "may",
      align: "right",
    },
    {
      title: "Jun",
      key: "jun",
      dataIndex: "jun",
      align: "right",
    },
    {
      title: "Jul",
      key: "jul",
      dataIndex: "jul",
      align: "right",
    },
    {
      title: "Aug",
      key: "aug",
      dataIndex: "aug",
      align: "right",
    },
    {
      title: "Sept",
      key: "sep",
      dataIndex: "sep",
      align: "right",
    },
    {
      title: "Oct",
      key: "oct",
      dataIndex: "oct",
      align: "right",
    },
    {
      title: "Nov",
      key: "nov",
      dataIndex: "nov",
      align: "right",
    },
    {
      title: "Dec",
      key: "dec",
      dataIndex: "dec",
      align: "right",
    },
    {
      title: "Total",
      key: "total",
      dataIndex: "total",
      align: "right",
      render: (text, record) => {
        // const monthlyFields = [
        //   "jan",
        //   "feb",
        //   "mar",
        //   "apr",
        //   "may",
        //   "jun",
        //   "jul",
        //   "aug",
        //   "sep",
        //   "oct",
        //   "nov",
        //   "dec",
        // ]

        // const isMonthZero = monthlyFields.some(
        //   (month) => record[month] === "0.00"
        // )
        const isCustom = record.name.toLowerCase().includes("total")
        // const isZero = record.total === "0.00"
        return (
          <span
            style={{
              fontWeight: isCustom ? "bold" : "normal",
              color: isCustom ? primaryColor : "inherit",
            }}
          >
            {text}
          </span>
        )
      },
    },
  ]

  async function fetchTableData() {
    setShowLoader(true)
    const response = await UseGetRequest(
      "reports/get_profit_and_loss",
      filterConfig
    )

    if (response.data) {
      const formattedData = response.data.account_types.map((category) => {
        const monthlyFields = [
          "jan",
          "feb",
          "mar",
          "apr",
          "may",
          "jun",
          "jul",
          "aug",
          "sep",
          "oct",
          "nov",
          "dec",
        ]
        return {
          ...category,
          key: category.name,
          children: category?.children?.map((main_type) => {
            monthlyFields.forEach((month) => {
              if (main_type[month] !== undefined) {
                main_type[month] = formatAmount(
                  parseFloat(main_type[month]).toFixed(2)
                )
              }
            })
            return {
              ...main_type,
              key: `${main_type.name}${main_type.id}`,
              total: main_type.total_amount
                ? formatAmount(main_type.total_amount?.toFixed(2))
                : "0.00",
              children: main_type?.children?.map((subtype) => {
                monthlyFields.forEach((month) => {
                  if (main_type[month] !== undefined) {
                    main_type[month] = formatAmount(
                      parseFloat(main_type[month]).toFixed(2)
                    )
                  }
                })
                return {
                  ...subtype,
                  key: `${subtype.name}${subtype.id}`,
                  total: main_type.total_amount
                    ? formatAmount(main_type.total_amount?.toFixed(2))
                    : "",
                  children: subtype?.children?.map((sub_subtype) => {
                    monthlyFields.forEach((month) => {
                      if (sub_subtype[month] !== undefined) {
                        sub_subtype[month] = formatAmount(
                          parseFloat(sub_subtype[month]).toFixed(2)
                        )
                      }
                    })
                    return {
                      ...sub_subtype,
                      key:
                        sub_subtype.id !== undefined
                          ? `${sub_subtype.name}_${sub_subtype.id}`
                          : `total_${subtype.name}`,
                      name:
                        sub_subtype.name === "total"
                          ? `TOTAL (${subtype.name})`
                          : sub_subtype.name,
                      total: sub_subtype.total_amount
                        ? formatAmount(sub_subtype.total_amount.toFixed(2))
                        : "0.00",
                    }
                  }),
                }
              }),
            }
          }),
        }
      })
      setTableData(formattedData || [])
    } else {
      setTableData([])
    }
    setShowLoader(false)
  }

  async function exportExcel() {
    const response = await UsePostRequest(
      "reports/create_profit_and_loss_excel",
      filterConfig,
      false
    )

    if (response.data) {
      setExportToExcelLink(response.data.url)
    } else {
      toast.error("Something went wrong", {
        style: toastStyle(),
      })
    }
  }

  React.useEffect(() => {
    fetchTableData()
    exportExcel()
  }, [filterConfig])

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive)
          }}
          active={"FINANCIAL STATEMENTS"}
        />
      </div>

      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        <Row className="mb-1">
          <Col>
            <h1 className="page-title"> PROFIT AND LOSS </h1>
          </Col>
        </Row>

        <div className="mt-2">
          <Row className="my-2 mt-2 px-2 d-flex flex-row justify-content-between gap-3">
            <Col xs={12} md={6} lg={3} className="mt-2">
              <RangePicker
                defaultValue={[
                  dayjs(filterConfig.date_from, "YYYY-MM-DD"),
                  dayjs(filterConfig.date_to, "YYYY-MM-DD"),
                ]}
                onChange={(e) => {
                  if (e) {
                    setFilterConfig((prev) => ({
                      ...prev,
                      date_from: e[0].format("YYYY-MM-DD"),
                      date_to: e[1].format("YYYY-MM-DD"),
                    }))
                  } else {
                    setFilterConfig((prev) => ({
                      ...prev,
                      date_from: "",
                      date_to: "",
                    }))
                  }
                }}
              />
            </Col>
            <Col xs={12} md={6} lg={2}>
              <div className="button-primary py-3 w-full text-center">
                <span className="me-2">
                  <img width={20} height={20} src={downloadIcon} alt=""></img>
                </span>
                <a href={exportToExcelLink} className="pointer custom-anchor">
                  Export To Excel
                </a>
              </div>
            </Col>
          </Row>

          <div className="mt-4">
            <div className="below income-statement">
              <div className="px-2">
                <Table
                  dataSource={tableData}
                  columns={tableColumns}
                  loading={showLoader}
                  pagination={false}
                />
              </div>
            </div>
            <div className="mb-2" />
          </div>
        </div>
      </div>
    </div>
  )
}
