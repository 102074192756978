import axios from "axios"
import toast from "react-hot-toast"
import { refreshPage } from "../Utils/Common"

// Set up a global response interceptor
axios.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    // Handle 401 error
    if (error.response && error.response.status === 401) {
      localStorage.clear()
      toast.error("Unauthorized. Logging you out...", { autoClose: 5000 })
      setTimeout(() => {
        window.location.href = "/" // Redirect to the login page
        refreshPage()
      }, 1000)
    }

    // Propagate the error to the calling code
    return Promise.reject(error)
  }
)

const getAPIKey = () => {
  const userStr = localStorage.getItem("api-key")
  if (userStr) return userStr
  else return null
}
const getAPIKey2 = () => {
  const userStr = localStorage.getItem("api-key2")
  if (userStr) return userStr
  else return null
}

var config = {
  "api-key": "daccfc89-ff47-4ce1-99bf-5ad2d8f57282",
  "user-key": getAPIKey(),
  "Content-Type": "application/json",
}
var config2 = {
  "api-key": "daccfc89-ff47-4ce1-99bf-5ad2d8f57282",
  "user-key": getAPIKey2(),
  "Content-Type": "application/json",
}

var configFormData = {
  "api-key": "daccfc89-ff47-4ce1-99bf-5ad2d8f57282",
  "user-key": getAPIKey(),
  "Content-Type": "multipart/form-data",
}

// API Axios Get Call.
export const getAPICall = (url, data, type) => {
  if (type === "potato") {
    return axios.get(url, { headers: config2, params: data })
  } else {
    return axios.get(url, { headers: config, params: data })
  }
}
// Potato Corner
export const getAPICall2 = (url, data) => {
  return axios.get(url, { headers: config2, params: data })
}
// API Axios Post Call.
export const postAPICall = (url, data, isFormData = false) => {
  if (isFormData) {
    return axios.post(url, data, { headers: configFormData })
  } else {
    return axios.post(url, data, { headers: config })
  }
}

// API Axios Post Call.
export const postAPICall2 = (url, data) => {
  return axios.post(url, data, { headers: config })
}

// API Axios Put Call.
export const putAPICall = (url, data, type) => {
  if (type === "potato") {
    return axios.put(url, data, { headers: config2 })
  } else {
    return axios.put(url, data, { headers: config })
  }
}
// API Axios Delete Call.
export const deleteAPICall = (url, type) => {
  if (type === "potato") {
    return axios.delete(url, { headers: config2 })
  } else {
    return axios.delete(url, { headers: config })
  }
}
