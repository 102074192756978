import React, { useEffect, useState } from "react";
import { Table, Row, Col } from "react-bootstrap";
import { formatAmount } from "../../Helpers/Utils/Common";
import cleanLogo from "../../Assets/Images/Login/logo.png";
import { dateFormat, getName } from "../../Helpers/Utils/Common";
import { getSingleProjectPI } from "../../Helpers/apiCalls/projectApi";

export default function PiPdfContent(props) {
  const { invoiceDetails, items } = props;
  const [itemArray, setItemArray] = useState(items)

  const headersIncompleteOrComplete = [
    "Item",
    "Qty",
    "Unit",
    "Price",
    "Dsc",
    "VAT In",
    "WHT (%)",
    "WHT Amt",
    "Grand Total",
    "Account Type",
    "Remarks",
  ];

  const selectorsIncompleteOrComplete = [
    "name",
    "qty",
    "unit",
    "price",
    "discount",
    "vat_in",
    "wht_percent",
    "wht_amount",
    "grand_total",
    "expense_type_name",
    "ce_no_name",
    "class_name",
    "remarks",
  ];

  const headers = [
    "Item",
    "Qty",
    "Unit",
    "Price",
    "Dsc",
    "VAT In",
    "WHT (%)",
    "WHT Amt",
    "Grand Total",
    "Account Type",
    "PC Number",
    "Class",
    "Remarks",
  ];

  const selectors = [
    "name",
    "qty",
    "unit",
    "price",
    "discount",
    "vat_in",
    "wht_percent",
    "wht_amount",
    "grand_total",
    "expense_type_name",
    "ce_no",
    "class_name",
    "remarks",
  ];

  async function fetchDetails() {
    const response = await getSingleProjectPI(invoiceDetails.id);
    if (response.data) {
      const data = response.data.data[0];
      const tempItems = data.project_invoice_items.map((item) => {
        var info = item;
        info.price = `PHP ${formatAmount(item.price)}`;
        info.amount = `PHP ${formatAmount(item.total)}`;
        info.remarks = item.remarks;
        return info;
      });
      setItemArray(tempItems)
    }
  }

  useEffect(() => {
    if (!items) {
      fetchDetails();
    }
  }, [items])

  return (
    <div className="print-container print-pdf-container" id="printablediv">
          <div className="text-end print-header">
            <span>PURCHASE INVOICE NO. {invoiceDetails.id}</span>
          </div>
          <div className="d-flex justify-content-center py-1">
            <img src={cleanLogo} className="print-logo" alt=""/>
          </div>
          <div className="d-flex justify-content-center py-1 mt-1">
            <h5 className="print-shop-header">WISHCRAFT</h5>
          </div>

          {/* content */}
          <div className="print-body mt-5">
            <Row>
              <Col xs={7} className="justify-content-start">
                <div className="d-flex my-2 align-items-center ms-5">
                  <Col xs={4} className="print-label">
                    Supplier:
                  </Col>
                  <Col xs={6} className="print-data">
                    {invoiceDetails.supplier_trade_name ||
                      "N/A"}
                  </Col>
                </div>
                <div className="d-flex my-2 align-items-center ms-5">
                  <Col xs={4} className="print-label">
                    Request Date:
                  </Col>
                  <Col xs={6} className="print-data">
                    {dateFormat(invoiceDetails.request_date)}
                  </Col>
                </div>
              </Col>

              <Col xs={5} className="justify-content-start">
                <div className="d-flex my-2 align-items-center">
                  <Col xs={4} className="print-label">
                    Due Date:
                  </Col>
                  <Col xs={6} className="print-data">
                    {dateFormat(invoiceDetails.due_date)}
                  </Col>
                </div>
                <div className="d-flex my-2 align-items-center">
                  <Col xs={4} className="print-label">
                    Requisitioner:
                  </Col>
                  <Col xs={6} className="print-data">
                    {invoiceDetails.requisitioner_name}
                  </Col>
                </div>
                <div className="d-flex my-2 align-items-center">
                  <Col xs={4} className="print-label">
                    {" "}
                  </Col>
                  <Col xs={6} className="print-data"></Col>
                </div>
              </Col>
            </Row>
            <div className="d-flex mt-5 mb-2 justify-content-evenly">
              {/* table */}
              <div className="print-table mt-3 mx-2">
              <Table>
          <thead>
            <tr>
              {invoiceDetails.order_status === "incomplete" ||
              invoiceDetails.order_status === "complete" ? (
                <>
                  {headersIncompleteOrComplete.map((header) => {
                    return <th>{header}</th>;
                  })}
                </>
              ) : (
                <>
                  {headers.map((header) => {
                    return <th>{header}</th>;
                  })}
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {itemArray && itemArray.map((item) => {
              return invoiceDetails.order_status === "incomplete" ||
                invoiceDetails.order_status === "complete" ? (
                <tr key={item.id}>
                  {selectorsIncompleteOrComplete.map((selector) => {
                    return <td>{item[selector]}</td>;
                  })}
                </tr>
              ) : (
                <tr key={item.id}>
                  {selectors.map((selector) => {
                    return <td>{item[selector]}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
              </div>
            </div>
            <Row className="align-right pt-3 me-3">
              <Col xs={2} className="text-end">
                <span className="print-table-footer-label color-gray">SUBTOTAL</span>
              </Col>
              <Col xs={1} className="text-end">
                <span className="print-table-footer-label vat-total-text align-middle">
                  PHP
                </span>
              </Col>
              <Col xs={1} className="text-end">
                <span className="print-table-footer-label align-middle vat-total-text text-end">
                  {formatAmount(invoiceDetails.subtotal)}
                </span>
              </Col>
            </Row>
            <Row className="align-right pt-1 me-3">
              <Col xs={2} className="text-end">
                <span className="print-table-footer-label color-gray">DISCOUNT</span>
              </Col>
              <Col xs={1} className="text-end">
                <span className="print-table-footer-label vat-total-text align-middle">
                  PHP
                </span>
              </Col>
              <Col xs={1} className="text-end">
                <span className="print-table-footer-label align-middle vat-total-text text-end">
                  {formatAmount(invoiceDetails.discount)}
                </span>
              </Col>
            </Row>
            <Row className="align-right pt-1 me-3">
              <Col xs={2} className="text-end">
                <span className="print-table-footer-label color-gray">WHT %</span>
              </Col>
              <Col xs={1} className="text-end">
                <span className="print-table-footer-label vat-total-text align-middle">
                </span>
              </Col>
              <Col xs={1} className="text-end">
                <span className="print-table-footer-label align-middle vat-total-text text-end">
                  {invoiceDetails.wht_percent??0}
                </span>
              </Col>
            </Row>
            <Row className="align-right pt-1 me-3">
              <Col xs={2} className="text-end">
                <span className="print-table-footer-label color-gray">WHT AMOUNT</span>
              </Col>
              <Col xs={1} className="text-end">
                <span className="print-table-footer-label vat-total-text align-middle">
                  PHP
                </span>
              </Col>
              <Col xs={1} className="text-end">
                <span className="print-table-footer-label align-middle vat-total-text text-end">
                  {formatAmount(invoiceDetails.wht_amount)}
                </span>
              </Col>
            </Row>
            <Row className="align-right pt-1 me-3">
              <Col xs={2} className="text-end">
                <span className="print-table-footer-label color-gray">OTHER FEES</span>
              </Col>
              <Col xs={1} className="text-end">
                <span className="print-table-footer-label vat-total-text align-middle">
                  PHP
                </span>
              </Col>
              <Col xs={1} className="text-end">
                <span className="print-table-footer-label align-middle vat-total-text text-end">
                  {formatAmount(invoiceDetails.other_fees)}
                </span>
              </Col>
            </Row>
            <Row className="align-right pt-1 me-3">
              <Col xs={2} className="text-end">
                <span className="print-table-footer-label color-gray">GRAND TOTAL</span>
              </Col>
              <Col xs={1} className="text-end">
                <span className="print-table-footer-label vat-total-text align-middle">
                  PHP
                </span>
              </Col>
              <Col xs={1} className="text-end">
                <span className="print-table-footer-label align-middle vat-total-text text-end">
                  {formatAmount(invoiceDetails.grand_total)}
                </span>
              </Col>
            </Row>
            <Row className="mt-4 ms-5">
              <span className="print-footer">Remarks: {invoiceDetails.remarks}</span>
            </Row>

            <div className="print-signatures">
              <div className="d-flex align-items-end justify-content-end flex-column">
                <span className="text-center text-uppercase nc-modal-custom-text">
                  {getName()}
                </span>
              </div>
              <div className="d-flex align-items-center justify-content-center flex-column">
                {/* <img src={signature} className="print-logo" /> */}
                <span className="text-center text-uppercase nc-modal-custom-text">
                  {invoiceDetails.approved_by_name}
                </span>
              </div>
            </div>
            <div className="print-signatories pb-4 mb-4">
              <span className="">Prepared by</span>
              <span>Approved by</span>
            </div>
          </div>
    </div>
  );
}
