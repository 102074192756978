import React, { useContext, useEffect, useState } from "react"
import { getDistributor } from "../../Helpers/apiCalls/DistributorApi"
import { Col, Row } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import Logo from "../../Assets/Images/Login/logo.png"
import { Context } from "../../Helpers/Context/Context"
import ColorThief from "colorthief"
import { BarLoader } from "react-spinners"
import Warehouse from "../../Assets/Images/warehouse.png"
import Manage from "../../Assets/Images/manage-system.png"
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined"
import {
  getType,
  removeUserSession,
  showWarningToast,
  toastStyle,
} from "../../Helpers/Utils/Common"
import toast from "react-hot-toast"

const LandingPage = () => {
  const [businessUnits, setBusinesUnits] = useState([])
  const {
    setSelectedBuId,
    setPrimaryColor,
    setBusinessUnitLogo,
    setOption,
    setBuName,
  } = useContext(Context)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)

  const defaultColor = "#c4e093"

  async function fetchBusinessUnits() {
    setLoading(true)
    try {
      const response = await getDistributor({ name: "" })
      if (response.data && Array.isArray(response.data.data)) {
        let data = response.data.data.map((bu) => {
          let info = bu
          info.logo = bu.distributor_attachment[0].base_64
          return info
        })
        setBusinesUnits(data)
      } else {
        setBusinesUnits([])
      }
    } catch (error) {
      console.error("Failed to fetch business units:", error)
      setBusinesUnits([])
    }
    setLoading(false)
  }

  function changeTheme(image) {
    const img = new Image()
    img.crossOrigin = "Anonymous"
    img.src = image

    img.onload = () => {
      const colorThief = new ColorThief()
      const dominantColor = colorThief.getColor(img)
      const hexColor = rgbToHex(dominantColor)
      setPrimaryColor(hexColor)
      localStorage.setItem("primaryColor", hexColor)
    }
  }

  const rgbToHex = (rgb) => {
    return `#${rgb.map((x) => x.toString(16).padStart(2, "0")).join("")}`
  }

  const handleBuClick = (bu) => {
    setSelectedBuId(bu.id)
    changeTheme(bu.logo)
    setBusinessUnitLogo(bu.logo)
    setBuName(bu.name)
    navigate("/dashboard")
  }

  function handleWarehouseClick() {
    changeTheme(Warehouse)
    convertToBase64(Warehouse)
      .then((base64) => {
        setBusinessUnitLogo(base64)
        setSelectedBuId("")
        setOption("warehouse")
        navigate(`/warehouse-list`)
      })
      .catch((error) => {
        console.error("Error converting image to Base64:", error)
      })
  }

  function handleManageClick() {
    changeTheme(Manage)
    convertToBase64(Manage)
      .then((base64) => {
        setBusinessUnitLogo(base64)
        setSelectedBuId("")
        setOption("manage")
        navigate(`/customers`)
      })
      .catch((error) => {
        console.error("Error converting image to Base64:", error)
      })
  }

  async function convertToBase64(imageUrl) {
    return fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onloadend = () => {
            resolve(reader.result) // Get Base64 string after comma
          }
          reader.onerror = (error) => {
            reject(error)
          }
          reader.readAsDataURL(blob)
        })
      })
  }

  async function handleLogout() {
    removeUserSession()
    window.localStorage.clear()
    toast.success("Logging you out...", {
      style: toastStyle(),
    })

    setTimeout(() => {
      navigate(`/`)
    }, 2000)
  }

  useEffect(() => {
    localStorage.removeItem("primaryColor")
    setPrimaryColor(defaultColor)
    fetchBusinessUnits()
  }, [])

  return (
    <div className="landing-page-container">
      <div className="fixed-top landing-page-header">
        <Row className="d-flex flex-row justify-content-center align-items-center">
          <Col className="w-full d-flex landing-page-logo ms-2 px-5">
            <img src={Logo} alt="" />
          </Col>
          <Col
            className="d-flex flex-row text-white justify-content-end align-items-center pe-4 font-primary cursor gap-2 me-5 px-5 pt-3"
            onClick={handleLogout}
          >
            <p className="font-primary m-0 text-white">LOGOUT</p>
            <LogoutOutlinedIcon />
          </Col>
        </Row>
      </div>
      <div className="bu-units-container">
        {loading ? (
          <Row className="d-flex flex-row justify-content-center align-items-center w-full mt-5 pt-5">
            <BarLoader color="white" />
          </Row>
        ) : (
          <Row className="d-flex flex-wrap px-5 pt-2 card-row overflow-scroll">
            {Array.isArray(businessUnits) &&
              businessUnits.length !== 0 &&
              businessUnits.map((bu) => (
                <Col
                  key={bu.id}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  className="p-3 position-relative"
                  onClick={() => {
                    if (bu.assigned === 1) {
                      handleBuClick(bu)
                    } else {
                      showWarningToast(`You do not have access to ${bu.name}`)
                    }
                  }}
                >
                  {bu.assigned === 0 && <div className="_inactive"></div>}
                  <div className="card-container d-flex flex-column justify-content-center align-items-center">
                    <img src={bu.logo} alt="" className="m-3" />
                    <h6 className="m-0 px-3 pb-2 text-center font-primary">
                      {bu.name}
                    </h6>
                  </div>
                </Col>
              ))}
            {getType() === "admin" && (
              <Col xs={12} sm={6} md={4} lg={3}>
                <div className="card-container d-flex flex-column justify-content-center align-items-center h-full py-2">
                  <img
                    src={Warehouse}
                    alt=""
                    className="m-3 py-1"
                    onClick={() => {
                      handleWarehouseClick()
                    }}
                  />
                  <h6 className="m-0 px-3 pb-2 text-center font-primary">
                    WAREHOUSE
                  </h6>
                </div>
              </Col>
            )}
            {getType() === "admin" && (
              <Col xs={12} sm={6} md={4} lg={3}>
                <div className="card-container d-flex flex-column justify-content-center align-items-center h-full py-2">
                  <img
                    src={Manage}
                    alt=""
                    className="m-3 py-2"
                    onClick={() => {
                      handleManageClick()
                    }}
                  />
                  <h6 className="m-0 px-3 pb-2 text-center font-primary">
                    MANAGE SYSTEM
                  </h6>
                </div>
              </Col>
            )}
          </Row>
        )}
      </div>
    </div>
  )
}

export default LandingPage
