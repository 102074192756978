import moment from "moment";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import cleanLogo from "../../Assets/Images/Login/logo.png";
import Navbar from "../../Components/Navbar/Navbar";
import {
  refreshPage,
  toastStyle,
  getName,
  formatNum,
} from "../../Helpers/Utils/Common";

export default function PrintDistributor(edit) {
  const { id } = useParams();
  let navigate = useNavigate();
  const location = useLocation();
  const distributorDetails = location.state.distributorDetails;
  const [inactive, setInactive] = useState(true);

  async function handlePrint() {
    toast.loading("Printing distributor details...", { style: toastStyle() });
    setTimeout(() => {
      toast.dismiss();
      Print();
    }, 1000);
  }

  function Print() {
    let printContents = document.getElementById("printablediv").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print(printContents);
    document.body.innerHTML = originalContents;
    refreshPage();
  }

  // DO NOT DELETE THIS PART OF THE CODE BECOS GIPAHIDE RA NI SIYA FOR NOW
  // const CustomerProjectsTable = () => {
  //   return (
  //       <Table className="">
  //         <thead>
  //           <tr>
  //             <th className="w-30 text-start">Customer</th>
  //             <th className="w-30 text-start">Project</th>
  //             <th className="w-30 text-start">Project Date</th>
  //           </tr>
  //         </thead>
  //         <tbody>
  //           {(distributorDetails.distributor_clients&&distributorDetails.distributor_clients.length>0) && distributorDetails.distributor_clients.map((client, customerIndex) => (
  //             <>
  //             <tr key={customerIndex}>
  //                 <td className="w-30 text-start">{client.customer_name}</td>
  //                 <td className="w-30 text-start">{client.project_name}</td>
  //                 <td className="w-30 text-start">{dateFormat(client.distributor_client_date)}</td>
  //             </tr>
  //             </>
  //           ))}
  //         </tbody>
  //       </Table>
  //   );
  // };

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"SALES"}
        />
      </div>
      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        <div className="print-container px-3 py-2" id="printablediv">
          <Row className="print-grand-total my-3 text-end print-header d-flex flex-column">
            <span>BUSINESS UNIT NO. {id}</span>
            <span className="text-uppercase">
              {moment().format("MMMM DD, YYYY")}
            </span>
          </Row>
          <div className="d-flex justify-content-center py-1">
            <img src={cleanLogo} className="print-logo" alt="" />
          </div>

          <div className="print-body mt-5">
            <Row className="px-5">
              <Col>
                <div className="d-flex my-2 align-items-center">
                  <Col xs={4} className="print-label">
                    Business Unit:
                  </Col>
                  <Col xs={7} className="print-data">
                    {distributorDetails.name ? distributorDetails.name : ""}
                  </Col>
                </div>

                <div className="d-flex my-2 align-items-center">
                  <Col xs={4} className="print-label">
                    Contact Person:
                  </Col>
                  <Col xs={7} className="print-data">
                    {distributorDetails.contact_person}
                  </Col>
                </div>
              </Col>
              <Col>
                <div className="d-flex my-2 align-items-center">
                  <Col xs={4} className="print-label">
                    Address:
                  </Col>
                  <Col xs={7} className="print-data">
                    {distributorDetails.address}
                  </Col>
                </div>
                <div className="d-flex my-2 align-items-center">
                  <Col xs={4} className="print-label">
                    Contact No.:
                  </Col>
                  <Col xs={7} className="print-data">
                    {distributorDetails.contact_no}
                  </Col>
                </div>
              </Col>
            </Row>
         
            {/* DO NOT DELETE COZ TEMPORARILY HIDDEN RA NI WHATEVER WORKS FOR THE USER */}
            {/* <div className="d-flex mt-5 px-5 justify-content-evenly">
                  <div className="print-table mt-3 mx-2">
                    <CustomerProjectsTable/>
                  </div>
              </div> */}

            <div className="print-signatures mt-5">
              <span className="text-center text-uppercase print-label">
                {getName()}
              </span>
              <span className="text-center text-uppercase print-label fw-bold">
                {distributorDetails.prepared_by}
              </span>
            </div>
            <div className="print-signatories pb-4 mb-4">
              <span>Received by</span>
              <span>Prepared by</span>
            </div>
          </div>
        </div>

        {/* footer */}
        <div className="d-flex justify-content-end my-4 pb-5 d-flex-responsive">
          <button
            className="button-secondary me-3"
            onClick={() => navigate("/businessunits")}
          >
            Close
          </button>
          <button
            className="button-tertiary me-3"
            onClick={() =>
              navigate("/businessunits/form", {
                state: {
                  formPurpose: "EDIT",
                  passedId: id,
                  distributorDetails: distributorDetails,
                },
              })
            }
          >
            Edit
          </button>
          <button className="button-primary" onClick={handlePrint}>
            Print
          </button>
        </div>
      </div>
    </div>
  );
}
