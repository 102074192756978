import React, { useEffect, useState } from "react"
import Navbar from "../../Components/Navbar/Navbar"
import { Col, Form, Row } from "react-bootstrap"
import AddModal from "../../Components/Modals/AddModal"
import EditModal from "../../Components/Modals/EditModal"
import ViewModal from "../../Components/Modals/ViewModal"
import Table from "../../Components/TableTemplate/DataTable"
import DeleteModal from "../../Components/Modals/DeleteModal"

//API CALL HELPERS
import { UsePostRequest } from "../../Helpers/hooks/UsePostApiCall"
import { UseGetRequest } from "../../Helpers/hooks/GetRequests"
import Select from "react-select"
import { dateFormat, refreshPage, toastStyle } from "../../Helpers/Utils/Common"
import toast from "react-hot-toast"

const StockIn = () => {
  const [inactive, setInactive] = useState(true)
  const [isClicked, setIsClicked] = useState(false)
  const [showModal, setShowModal] = useState("")
  const [itemOptions, setItemOptions] = useState([])
  const [submittableForm, setSubmittableForm] = useState(false)
  const [details, setDetails] = useState({
    name: "",
  })

  const [tableData, setTableData] = useState([])
  const [showLoader, setShowLoader] = useState(false)

  function handleChange(e, fieldType) {
    const { name, value } = e.target || {}
    let updatedDetails = {}

    if (fieldType === "input") {
      updatedDetails = { ...details, [name]: value }
    } else if (fieldType === "dropdown") {
      updatedDetails = { ...details, [e.for]: e.value }
    }
    setDetails(updatedDetails)
    validate(updatedDetails)
  }

  function validate(formDetails) {
    const { item_id, quantity } = formDetails
    const isFormValid = item_id && quantity && quantity > 0
    setSubmittableForm(isFormValid)
  }

  async function handleCreate() {
    setIsClicked(true)
    const response = await UsePostRequest(
      "stock_ins/create",
      { ...details },
      false
    )

    if (response.error) {
      toast.error(`Something went wrong.`, {
        style: toastStyle(),
      })
    } else {
      toast.success(response?.data?.response, {
        style: toastStyle(),
      })
      setTimeout(() => {
        refreshPage()
      }, 1000)
    }
    setIsClicked(false)
  }
  async function handleDelete() {}

  async function fetchTableData() {
    setShowLoader(true)

    const response = await UseGetRequest("stock_ins/get_all_stock_in")

    if (response.data) {
      const data = response.data.data.map((stockItem) => {
        const info = stockItem
        info.added_on = dateFormat(stockItem.added_on)
        return info
      })
      setShowLoader(false)
      setTableData(data)
    } else {
      setTableData([])
    }
  }

  async function fetchItems() {
    const response = await UseGetRequest("items/get_all_item")

    if (response.data) {
      const items = response.data.data.map((item) => ({
        ...item,
        for: "item_id",
        label: item.name,
        value: item.id,
      }))

      setItemOptions(items)
    } else {
      setItemOptions([])
    }
  }

  useEffect(() => {
    fetchItems()
    fetchTableData()
  }, [])

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => setInactive(inactive)}
          active={"OTHERS"}
        />
      </div>
      <div
        className={`manager-container pt-4 ${inactive ? "inactive" : "active"}`}
      >
        <Row className="mb-1 d-flex flex-row gap-2 gap-lg-0">
          <Col xs={12} lg={7}>
            <h1 className="page-title m-0 w-full">STOCK IN</h1>
          </Col>
          <Col xs={12} lg={3}>
            {/* <input
              type="search"
              name="name"
              placeholder="Search name"
              className="search-bar m-0 w-full"
              onChange={(e) => {}}
              onKeyDown={(e) => {
                // if (e.key === "Enter") {
                //   search(e.target.value)
                // }
              }}
            /> */}
          </Col>
          <Col xs={12} lg={2} className="d-flex justify-content-end">
            <button
              className="button-primary"
              onClick={() => {
                setShowModal("add")
              }}
            >
              Add
            </button>
          </Col>
        </Row>
        <Row className="tab-content pt-3">
          <Col>
            <div className="below">
              <Table
                tableHeaders={["DATE ADDED", "ITEM NAME", "QUANTITY"]}
                headerSelector={["added_on", "name", "quantity"]}
                tableData={tableData}
                showLoader={showLoader}
                handleRowClick={(row) => {
                  setDetails(row)
                  setShowModal("view")
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
      <AddModal
        title="STOCK IN"
        size="xl"
        type="branch"
        show={showModal === "add"}
        onHide={() => setShowModal("")}
        onSave={handleCreate}
        isClicked={isClicked}
        disableSave={!submittableForm}
      >
        <div className="d-flex justify-content-center align-items-center flex-column">
          <Row className="nc-modal-custom-row d-flex flex-column flex-md-row gap-3 gap-md-0">
            <Col>
              ITEM <span className="required-icon">*</span>
              <Select
                name="item_id"
                className="w-full"
                classNamePrefix="react-select"
                placeholder="Select item"
                onChange={(e) => handleChange(e, "dropdown")}
                options={itemOptions}
              />
            </Col>
            <Col>
              QUANTITY<span className="required-icon">*</span>
              <Form.Control
                name="quantity"
                type="number"
                onWheel={(e) => e.target.blur()}
                onChange={(e) => handleChange(e, "input")}
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col>
              REMARKS
              <Form.Control
                name="remarks"
                type="text"
                onChange={(e) => handleChange(e, "input")}
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
        </div>
      </AddModal>
      <ViewModal
        withButtons
        size="lg"
        editable={false}
        show={showModal === "view"}
        onHide={() => setShowModal("")}
        onEdit={() => {
          setShowModal("edit")
        }}
        onDelete={() => setShowModal("delete")}
      >
        <div className="d-flex justify-content-center align-items-center flex-column">
          <Row className="nc-modal-custom-row d-flex flex-column flex-md-row gap-3 gap-md-0">
            <Col>
              NAME<span className="required-icon">*</span>
              <Form.Control
                name="name"
                type="text"
                disabled
                value={details.name}
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col>
              QUANTITY<span className="required-icon">*</span>
              <Form.Control
                name="quantity"
                type="number"
                onWheel={(e) => e.target.blur()}
                disabled
                value={details.quantity}
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col>
              REMARKS
              <Form.Control
                name="remarks"
                type="text"
                disabled
                value={details.remarks}
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
        </div>
      </ViewModal>
      <DeleteModal
        text="item"
        show={showModal === "delete"}
        onHide={() => setShowModal("edit")}
        onDelete={handleDelete}
      />
    </div>
  )
}

export default StockIn
