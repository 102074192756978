import React, { useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { Select as AntSelect, Select } from "antd"
//components
import Table from "../../Components/TableTemplate/DataTable"
import Navbar from "../../Components/Navbar/Navbar"
import DeleteModal from "../../Components/Modals/DeleteModal"
import AddModal from "../../Components/Modals/AddModal"
import EditModal from "../../Components/Modals/EditModal"
import ViewModal from "../../Components/Modals/ViewModal"
import toast from "react-hot-toast"
import { showWarningToast, toastStyle } from "../../Helpers/Utils/Common"
import { UseGetRequest } from "../../Helpers/hooks/GetRequests"
import { UsePostRequest } from "../../Helpers/hooks/UsePostApiCall"
import { getDistributor } from "../../Helpers/apiCalls/DistributorApi"

export default function WarehouseManager() {
  const [inactive, setInactive] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [isClicked, setIsClicked] = useState(false)
  const [businessUnits, setBusinessUnits] = useState(false)

  // DELETE
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const handleCloseDeleteModal = () => setShowDeleteModal(false)

  // VIEW
  const [showViewCustomerModal, setShowViewCustomerModal] = useState(false)

  // EDIT
  const [showEditCustomerModal, setShowEditCustomerModal] = useState(false)
  const handleCloseEditProjectModal = () => {
    setShowEditCustomerModal(false)
    resetValues()
  }

  const [showAddCustomerModal, setShowAddCustomerModal] = useState(false)

  //API
  const [tableData, setTableData] = useState([
    { name: "Sample Warehouse", business_units: [{ name: "SAMPLE" }] },
  ])
  const [selectedRow, setSelectedRow] = useState([])
  const [formValues, setFormValues] = useState({
    name: "",
    business_unit_ids: [],
  })

  const [requiredFieldKeys, setRequiredFieldKeys] = useState([
    "name",
    "business_unit_ids",
  ])

  function resetValues() {
    setFormValues({
      name: "",
    })
  }

  const handleAddChange = (e) => {
    const { name, value } = e.target
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleEditChange = (e) => {
    const { name, value } = e.target
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  async function create() {
    if (formValues.password !== formValues.confirm_password) {
      toast.error("Password and confirm password should match")
      return
    }
    setIsClicked(true)

    const payload = {
      ...formValues,
      business_unit_ids: formValues.business_unit_ids || [],
    }

    const response = await UsePostRequest("warehouse/create", payload)
    if (response) {
      if (response?.data?.status === "success") {
        toast.success(response.message?.messages, {
          style: toastStyle(),
        })
        setShowAddCustomerModal(false)
        resetValues()
        fetchWarehouses()
      }
      if (response?.error?.data?.messages?.name) {
        toast.error(response.error.data.messages.name, {
          style: toastStyle(),
        })
      }
      if (response?.data?.message) {
        showWarningToast(response?.data?.message)
      }
      if (response?.error?.data?.messages?.account_name) {
        toast.error(response.error.data.messages.account_name, {
          style: toastStyle(),
        })
      }
    }
    setIsClicked(false)
  }

  async function fetchWarehouses() {
    // setTableData([])
    // setShowLoader(true)
    // const response = await UseGetRequest("warehouse/get_all_warehouses")
    // if (response.data) {
    //   const formattedData = response.data.data.map((warehouse) => ({
    //     ...warehouse,
    //   }))
    //   setTableData(formattedData)
    // }
    // setShowLoader(false)
  }

  async function fetchBusinessUnits() {
    try {
      const response = await getDistributor({ name: "" })
      if (response.data && Array.isArray(response.data.data)) {
        let data = response.data.data.map((bu) => {
          return {
            label: bu.name,
            value: bu.id,
          }
        })
        setBusinessUnits(data)
      } else {
        setBusinessUnits([])
      }
    } catch (error) {
      setBusinessUnits([])
    }
  }

  async function searchWarehouse(text) {
    setTableData([])
    setShowLoader(true)
    const response = await UseGetRequest("warehouse/search", { name: text })
    if (response.data) {
      const formattedData = response.data.data.map((warehouse) => ({
        ...warehouse,
      }))
      setTableData(formattedData)
    }
    setShowLoader(false)
  }

  async function del() {
    const response = await UsePostRequest("warehouse/delete", {
      id: selectedRow.id,
    })
    if (response) {
      toast.success("Warehouse deleted successfully", {
        style: toastStyle(),
      })
      handleCloseDeleteModal()
      setShowViewCustomerModal(false)
      fetchWarehouses()
    } else {
      toast.error(response.error?.data.messages.error, {
        style: toastStyle(),
      })
    }
  }

  async function editWarehouse() {
    setIsClicked(true)
    const payload = {
      name: formValues.name,
      warehouse_id: formValues.id,
      business_unit_ids: formValues.business_unit_ids,
    }
    const response = await UsePostRequest("warehouse/update", payload)
    if (response) {
      if (response) {
        toast.success("Warehouse details updates successfully", {
          style: toastStyle(),
        })
        setShowEditCustomerModal(false)
        setShowViewCustomerModal(false)
        resetValues()
        fetchWarehouses()
      } else {
        toast.error(response.error.data.messages.error, {
          style: toastStyle(),
        })
      }
    }
    setIsClicked(false)
  }

  React.useEffect(() => {
    fetchWarehouses()
    fetchBusinessUnits()
  }, [])

  const [submittableForm, setSubmittableForm] = useState(false)
  React.useEffect(() => {
    let isValid = true
    requiredFieldKeys.forEach((key) => {
      if (
        !formValues[key] ||
        (formValues[key] &&
          typeof formValues[key] === "string" &&
          formValues[key]?.trim() === "")
      ) {
        isValid = false
      }

      if (
        formValues.business_unit_ids &&
        formValues.business_unit_ids.length === 0
      ) {
        isValid = false
      }
    })
    setSubmittableForm(isValid)
  }, [formValues.name, formValues.business_unit_ids])

  console.log(formValues)
  console.log(submittableForm)

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive)
          }}
          active={"WAREHOUSES"}
        />
      </div>
      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        <Row className="mb-4">
          <Col xs={6}>
            <h1 className="page-title"> WAREHOUSES </h1>
          </Col>
          <Col className="d-flex justify-content-end">
            <input
              type="search"
              name="name"
              placeholder="Search name"
              className="search-bar"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  searchWarehouse(e.target.value)
                }
              }}
            />
            <button
              className="add-btn"
              onClick={() => setShowAddCustomerModal(true)}
            >
              Add
            </button>
          </Col>
        </Row>

        <div className="tab-content">
          <Table
            tableHeaders={["NAME"]}
            headerSelector={["name"]}
            tableData={tableData}
            showLoader={showLoader}
            handleRowClick={(row) => {
              const editcustomer = {
                ...row,
                business_unit_ids: row.business_units?.map((item) => {
                  return {
                    label: item.name,
                    value: item.business_unit_id,
                  }
                }),
              }
              setSelectedRow(row)
              setFormValues(editcustomer)
              setRequiredFieldKeys(["name", "business_unit_ids"])
              setShowViewCustomerModal(true)
            }}
            isPointer
          />
        </div>
      </div>

      {/* MODALS */}
      <DeleteModal
        text="warehouse"
        show={showDeleteModal}
        onHide={handleCloseDeleteModal}
        onDelete={del}
      />
      <AddModal
        title="WAREHOUSE"
        size="xl"
        type="branch"
        show={showAddCustomerModal}
        onHide={() => {
          setShowAddCustomerModal(false)
          resetValues()
        }}
        onSave={() => create()}
        isClicked={isClicked}
        data={formValues}
        disableSave={!submittableForm}
      >
        <div className="mt-3">
          <Row className="nc-modal-custom-row">
            <Row>
              <Col>
                NAME
                <span className="required-icon">*</span>
                <Form.Control
                  type="text"
                  name="name"
                  value={formValues.name}
                  className="nc-modal-custom-input"
                  onChange={(e) => handleAddChange(e)}
                  required
                />
              </Col>
            </Row>
            {formValues.type !== "admin" && (
              <Row className="mt-3">
                <Col>
                  BUSINESS UNITS
                  <span className="required-icon">*</span>
                  <Col className="mb-2 mb-lg-0">
                    <Select
                      showSearch
                      mode="multiple"
                      className="react-select-container w-100"
                      classNamePrefix="react-select"
                      placeholder="Select business unit(s)"
                      onChange={(e) => {
                        setFormValues((prev) => ({
                          ...prev,
                          business_unit_ids: e,
                        }))
                      }}
                      options={businessUnits}
                      dropdownStyle={{ zIndex: 10000 }}
                      filterOption={(input, option) =>
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    />
                  </Col>
                </Col>
              </Row>
            )}
          </Row>
        </div>
      </AddModal>

      <EditModal
        title="WAREHOUSE"
        size="xl"
        type="project"
        show={showEditCustomerModal}
        onHide={handleCloseEditProjectModal}
        onSave={() => editWarehouse()}
        data={formValues}
        isClicked={isClicked}
        disableSave={!submittableForm}
      >
        <div className="mt-3">
          <Row className="nc-modal-custom-row">
            <Row>
              <Col>
                NAME
                <span className="required-icon">*</span>
                <Form.Control
                  type="text"
                  name="name"
                  value={formValues.name}
                  className="nc-modal-custom-input"
                  onChange={(e) => handleEditChange(e)}
                  required
                />
              </Col>
            </Row>

            {formValues.type !== "admin" && (
              <Row className="mt-3">
                <Col>
                  BUSINESS UNITS
                  <span className="required-icon">*</span>
                  <Col className="mb-2 mb-lg-0">
                    <Select
                      showSearch
                      mode="multiple"
                      className="react-select-container w-100"
                      classNamePrefix="react-select"
                      placeholder="Select business unit(s)"
                      value={formValues.business_unit_ids}
                      onChange={(e) => {
                        setFormValues((prev) => ({
                          ...prev,
                          business_unit_ids: e,
                        }))
                      }}
                      options={businessUnits}
                      dropdownStyle={{ zIndex: 10000 }}
                      filterOption={(input, option) =>
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    />
                  </Col>
                </Col>
              </Row>
            )}
          </Row>
        </div>
      </EditModal>

      <ViewModal
        withButtons
        size="xl"
        show={showViewCustomerModal}
        onHide={() => {
          resetValues()
          setShowViewCustomerModal(false)
        }}
        onEdit={() => {
          setShowEditCustomerModal(true)
          setShowViewCustomerModal(false)
        }}
        onDelete={() => setShowDeleteModal(true)}
      >
        <div className="mt-1">
          <span className="custom-modal-body-title">WAREHOUSE DETAILS</span>
          <Row className="nc-modal-custom-row">
            <Row>
              <Col>
                NAME
                <Form.Control
                  type="text"
                  name="name"
                  value={formValues.name}
                  className="nc-modal-custom-input"
                  disabled
                />
              </Col>
            </Row>
            {formValues.type !== "admin" && (
              <Row className="mt-3">
                <Col>
                  BUSINESS UNITS
                  <span className="required-icon">*</span>
                  {formValues.business_units?.map((item, index) => (
                    <Form.Control
                      key={index}
                      type="text"
                      name="account_no"
                      defaultValue={item.name}
                      className="nc-modal-custom-input mb-2"
                      disabled
                    />
                  ))}
                </Col>
              </Row>
            )}
          </Row>
        </div>
      </ViewModal>
    </div>
  )
}
