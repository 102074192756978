import { getToken, getUser } from "../Utils/Common"
import { getAPICall } from "./axiosMethodCalls"

export const deleteBank = async (id) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "banks/delete",
      {
        requester: getUser(),
        token: getToken(),
        bank_id: id,
      }
    )

    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}
