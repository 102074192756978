import React, { useState, useEffect } from "react"
import { Col, Form, Row, Tab, Tabs } from "react-bootstrap"
import { Select as AntSelect } from "antd"
import { DatePicker } from "antd"
import { useNavigate } from "react-router-dom"
import {
  isAdmin,
  toastStyle,
  TokenExpiry,
  formatAmount,
  formatAmountInt,
  selectDropdownStyle,
} from "../../Helpers/Utils/Common"
import Moment from "moment"
import Navbar from "../../Components/Navbar/Navbar"
import Table from "../../Components/TableTemplate/DataTable"

import toast from "react-hot-toast"
import DeleteModal from "../../Components/Modals/DeleteModal"
import {
  approveOperationalExpense,
  changeStatus,
} from "../../Helpers/apiCalls/suppliesExpensesApi"
import { getType } from "../../Helpers/Utils/Common"
import "./OperationalExpenses.css"
import SEModal from "./Components/SEModal"
import { searchSE } from "../../Helpers/apiCalls/suppliesExpensesApi"
import { getAllSuppliers } from "../../Helpers/apiCalls/suppliersApi"
import POModal from "../PurchaseOrders/POModal"
import PoPaymentModal from "./Components/PoPaymentModal"
import Select from "react-select"
import moment from "moment"
import { getDistributor } from "../../Helpers/apiCalls/DistributorApi"

const { RangePicker } = DatePicker
const { Option } = AntSelect
export default function OperationalExpenses() {
  let navigate = useNavigate()
  const [showLoader, setShowLoader] = useState(false)
  const [supExpenseID, setSupExpenseID] = useState("")
  const [userType, setUserType] = useState(getType())
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showReturnModal, setShowReturnModal] = useState(false)
  const [showClosePOModal, setShowClosePOModal] = useState(false)
  const [inactive, setInactive] = useState(true)
  const [suppliesExpenseManager, setOperationalExpenseManager] = useState([])
  const [bal, setBal] = useState("")
  const [showAddPaymentModal, setShowAddPaymentModal] = useState(false)
  const [suppliers, setSuppliers] = useState([])
  const [supplierList, setSupplierList] = useState([])
  const [selectedSupplier, setSelectedSupplier] = useState("")
  const [allDistributors, setAllDistributors] = useState([])

  const [filterConfig, setFilterConfig] = useState({
    tab: "pending",
    project_id: "",
    status: "pending",
    order_status: "",
    requisitioner: "",
  })

  async function handleDeleteOperationalExpense() {
    const response1 = await approveOperationalExpense(
      supExpenseID.id,
      "deleted"
    )

    if (response1.data.response) {
      toast.success("Operational Expense Deleted Successfully!", {
        style: toastStyle(),
      })
      setShowDeleteModal(false)
      fetchSuppliers()
      searchOperationalExpense()
    } else {
      toast.error("Error Deleting Operational Expense", {
        style: toastStyle(),
      })
    }
  }

  useEffect(() => {
    setSupplierList(
      suppliers.map((supplier) => {
        return {
          label: supplier.trade_name,
          value: supplier.id + "|" + supplier.type,
        }
      })
    )
    setSupplierList((branches) => {
      var newBranches = [...branches]
      newBranches.push({ label: "All Suppliers", value: "" })
      return newBranches.reverse()
    })
  }, [suppliers])

  function handleSupplierChange(e) {
    setSelectedSupplier(e.name)
    const toFilter = { target: { name: "supplier_id", value: e.value } }
    handleFilterChange(toFilter)
  }

  async function fetchSuppliers() {
    setSuppliers([])
    const suppliersResponse = await getAllSuppliers()

    if (suppliersResponse.error) {
      TokenExpiry(suppliersResponse)
    } else {
      suppliersResponse.data.data.map((supplier) => {
        var info = supplier
        info.type = "mango|supplier"
        setSuppliers((prev) => [...prev, info])
      })
    }
  }

  async function handleReceiveSE(id) {
    navigate("/se/purchaseinvoices/add/" + id)
  }

  async function handleReturnSE() {
    const response = await approveOperationalExpense(supExpenseID.id, "pending")
    if (response.data.response) {
      toast.success("Operational Expense Returned to Pending", {
        style: toastStyle(),
      })
      // setTimeout(() => refreshPage(), 1000);
      setShowReturnModal(false)
      fetchSuppliers()
      searchOperationalExpense()
    } else {
      toast.error("Error Returning Operational Expense to Pending", {
        style: toastStyle(),
      })
    }
  }

  const [selectedSEId, setSelectedSEId] = useState("")

  async function handleSelectChange(e, suppID, type, row) {
    const { id, value } = e.target
    setSupExpenseID({
      id: id,
      supplier: suppID,
      name: row.supplier_trade_name
        ? row.supplier_trade_name
        : row.vendor_trade_name,
    })

    var _baseURL = "opexpurchaseinvoice/"

    if (value === "edit-supExpense") {
      window.open(_baseURL + "edit/" + type + "/" + id, "_blank")
    } else if (value === "delete-supExpense") {
      setShowDeleteModal(true)
    } else if (value === "return-supExpense") {
      setShowReturnModal(true)
    } else if (value === "print-supExpense") {
      navigate("print/" + id, {
        state: { type: type, suppID: suppID },
      })
    } else if (value === "review-supExpense") {
      window.open(_baseURL + "review/" + id, "_blank")
    } else if (value === "email-supExpense") {
      navigate("print/" + id, {
        state: { type: type, suppID: suppID },
      })
    } else if (value === "view-supExpense") {
      window.open(_baseURL + "review/" + id, "_blank")
    } else if (value === "receive-supExpense") {
      handleReceiveSE(id)
    } else if (value === "close-supExpense") {
      setSelectedSEId(id)
      setShowClosePOModal(true)
    } else if (value === "payment-po") {
      setBal(formatAmountInt(row.grand_total))
      setShowAddPaymentModal(true)
    }
  }

  async function handleClosePO() {
    var data = {
      se_id: selectedSEId,
      new_status: "complete",
    }
    const response = await changeStatus(data)
    if (response.data) {
      toast.success(response.data.response, {
        style: toastStyle(),
      })
      setShowClosePOModal(false)
      setFilterConfig((prev) => {
        return {
          ...prev,
          tab: "complete",
          order_status: "complete",
        }
      })
    } else {
      TokenExpiry(response)
      toast.error("Error closing supplies purchase order", {
        style: toastStyle(),
      })
    }
  }

  async function searchOperationalExpense() {
    setOperationalExpenseManager([])
    setShowLoader(true)
    const response = await searchSE(filterConfig)
    if (response.data) {
      var SE = response.data.data.map((se) => {
        var info = se
        info.request_date = Moment(se.request_date).format("MMMM DD, YYYY")
        info.due_date = Moment(se.due_date).format("MMMM DD, YYYY")
        info.supplier_trade_name = se.supplier_id
          ? se.supplier_trade_name
          : se.vendor_id
          ? se.vendor_trade_name
          : "N/A"
        info.grand_total = formatAmount(info.grand_total)
        info.invoice = se.invoice_no?.map((invoice) => {
          return invoice.invoice_no ? invoice.invoice_no : ""
        })
        info.invoice_id = se.invoice_no?.map((invoice) => {
          return invoice.id ? invoice.id : ""
        })
        info.pay_ref_no = !se.payments
          ? ""
          : se.payments[0]?.payment_mode === "check"
          ? se.payments[0]?.payment_mode + " - " + se.payments[0]?.check_no
          : se.payments[0]?.payment_mode === "bank"
          ? se.payments[0]?.payment_mode + " - " + se.payments[0]?.reference_no
          : se.payments[0]?.payment_mode
        info.pay_id_no = !se.payments
          ? ""
          : se.payments[0]?.se_id
          ? se.payments[0].se_id
          : ""
        info.created_by = `${se.added_by_name} on ${moment(se.added_on).format(
          "MMM DD, YYYY hh:mm A"
        )}`
        info.reviewed_by = `${se.reviewed_by} on ${moment(
          se.reviewed_on
        ).format("MMM DD, YYYY hh:mm A")}`
        info.approved_by = `${se.approved_by} on ${moment(
          se.approved_on
        ).format("MMM DD, YYYY hh:mm A")}`
        info.finalized_by = `${se.finalized_by} on ${moment(
          se.finalized_on
        ).format("MMM DD, YYYY hh:mm A")}`
        info.disapproved_by = `${se.disapproved_by} on ${moment(
          se.disapproved_on
        ).format("MMM DD, YYYY hh:mm A")}`
        info.sent_by = `${se.sent_by} on ${moment(se.sent_on).format(
          "MMM DD, YYYY hh:mm A"
        )}`

        return info
      })
      setOperationalExpenseManager(
        SE.sort(
          (a, b) =>
            new Date(...a.request_date?.split("/").reverse()) -
            new Date(...b.request_date?.split("/").reverse())
        ).reverse()
      )
    }
    setShowLoader(false)
  }

  const handleTabSelect = (tabKey) => {
    if (tabKey === "complete" || tabKey === "incomplete") {
      setFilterConfig((prev) => {
        return {
          ...prev,
          tab: tabKey,
          status: "",
          order_status: tabKey,
          supplier_id: "",
          vendor_id: "",
          type: "",
          date_from: "",
          date_to: "",
        }
      })
    } else {
      setFilterConfig((prev) => {
        return {
          ...prev,
          tab: tabKey,
          status: tabKey,
          order_status: "",
          supplier_id: "",
          vendor_id: "",
          type: "",
          date_from: "",
          date_to: "",
        }
      })
    }
  }

  const handleFilterChange = (e) => {
    const { name, value } = e.target
    if (name === "supplier_id") {
      var id = value.split("|")[0]
      var type = value.split("|")[1]
      var by = value.split("|")[2]
      if (by === "supplier") {
        setFilterConfig((prev) => {
          return {
            ...prev,
            supplier_id: id,
            vendor_id: "",
            type: type,
          }
        })
      } else {
        setFilterConfig((prev) => {
          return {
            ...prev,
            supplier_id: "",
            vendor_id: id,
            type: type,
          }
        })
      }
    } else {
      setFilterConfig((prev) => {
        return {
          ...prev,
          [name]: value,
        }
      })
    }
  }

  React.useEffect(() => {
    searchOperationalExpense()
  }, [filterConfig])

  function ContractBtn(row) {
    return (
      <span
        className="me-4 align-middle ps-label"
        onClick={() => handleViewContract(row.pay_id_no)}
      >
        {row.pay_ref_no}
      </span>
    )
  }

  function handleViewContract(id, type) {
    window.open("/se/purchaseinvoices/print/" + id)
  }

  function ViewBtn(row) {
    return (
      <>
        {filterConfig.tab === "complete" && (
          <button
            name="action"
            className="btn btn-sm view-btn-table me-1"
            id={row.id}
            onClick={(e) => handleSelectChange(e, row.supplier_id, "sent", row)}
            value="view-supExpense"
          >
            View
          </button>
        )}

        {filterConfig.tab === "incomplete" && (
          <>
            <button
              name="action"
              className="btn btn-sm view-btn-table me-1"
              id={row.id}
              onClick={(e) =>
                handleSelectChange(e, row.supplier_id, "sent", row)
              }
              value="view-supExpense"
            >
              View
            </button>
            <button
              name="action"
              className="btn btn-sm view-btn-table me-1"
              id={row.id}
              onClick={(e) =>
                handleSelectChange(e, row.supplier_id, "sent", row)
              }
              value="close-supExpense"
            >
              Close
            </button>
            <button
              name="action"
              className="btn btn-sm view-btn-table"
              id={row.id}
              onClick={(e) =>
                handleSelectChange(e, row.supplier_id, "sent", row)
              }
              value="receive-supExpense"
            >
              Receive
            </button>
          </>
        )}
      </>
    )
  }

  function InvoiceBtn(row) {
    return (
      <span
        className="me-4 align-middle ps-label"
        onClick={() => handleViewInvoice(row.invoice_id)}
      >
        {row.invoice}
      </span>
    )
  }

  function handleViewInvoice(id) {
    window.open("/se/purchaseinvoices/print/" + id)
  }

  function ActionBtn(row, status) {
    return (
      <Form.Select
        name="action"
        className="PO-select-action"
        id={row.id}
        onChange={(e) => handleSelectChange(e, row.supplier_id, status, row)}
        value={""}
      >
        <option value="" selected hidden>
          Select
        </option>
        {status === "finalized" && (
          <>
            <option value="print-supExpense" className="">
              {status.includes("printed") ? "Reprint" : "Print"}
            </option>
            <option value="email-supExpense" className="">
              Email to Suppliers
            </option>
          </>
        )}
        {status === "sent" && (
          <>
            <option value="print-supExpense" className="">
              Print
            </option>
          </>
        )}
        {status === "disapproved" && (
          <option value="return-supExpense" className="color-yellow">
            Return to Pending
          </option>
        )}
        {isAdmin && (status === "pending") && (
          <option value="edit-supExpense" className="">
            Edit
          </option>
        )}
        {status !== "sent" && (
          <option value="view-supExpense" className="">
            View
          </option>
        )}
        {userType === "admin" &&
          (status === "pending" || status === "reviewed") && (
            <option value="delete-supExpense" className="color-red">
              Delete
            </option>
          )}
      </Form.Select>
    )
  }

  function AddPaymentBtn(row, type) {
    if (row.can_be_paid === "0") {
      return (
        <button
          name="action"
          className="btn btn-sm view-btn-table"
          id={row.id}
          onClick={(e) => handleSelectChange(e, row.supplier_id, type, row)}
          value="payment-po"
        >
          Add Payment
        </button>
      )
    }
  }

  async function fetchDistributors() {
    setAllDistributors([])
    const response = await getDistributor()
    if (response.data) {
      const res = response.data.data.map((row) => {
        return {
          value: row.id,
          label: row.name,
        }
      })
      setAllDistributors([{ value: "", label: "All Distributors" }, ...res])
    }
  }

  React.useEffect(() => {
    fetchSuppliers()
    fetchDistributors()
  }, [])

  function commonTabContent(passedTab) {
    return (
      <div className="my-2 px-4 PO-filters d-flex">
        <Select
          className="dropsearch-filter px-0 py-0 me-2"
          classNamePrefix="react-select"
          placeholder="Select Supplier"
          styles={selectDropdownStyle}
          value={selectedSupplier}
          options={supplierList}
          onChange={handleSupplierChange}
        />
        <Select
          className="dropsearch-filter px-0 py-0 me-2"
          classNamePrefix="react-select"
          placeholder="Select Business Unit"
          styles={selectDropdownStyle}
          options={allDistributors}
          onChange={(e) => {
            setFilterConfig((prev) => {
              return { ...prev, requisitioner: e.value }
            })
          }}
        />
        <Col xs={4}>
          <RangePicker
            className="nc-modal-custom-text w-100"
            onChange={(e) => {
              if (e) {
                setFilterConfig((prev) => ({
                  ...prev,
                  date_from: e[0].format("YYYY-MM-DD"),
                  date_to: e[1].format("YYYY-MM-DD"),
                }))
              } else {
                setFilterConfig((prev) => ({
                  ...prev,
                  date_from: "",
                  date_to: "",
                }))
              }
            }}
          />
        </Col>
      </div>
    )
  }

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive)
          }}
          active={"OPERATING EXPENSES"}
        />
      </div>

      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        <Row className="mb-3">
          <Col xs={6}>
            <h1 className="page-title">OPEX PURCHASE INVOICE </h1>
          </Col>
          <Col xs={6} className="d-flex justify-content-end mt-4">
            <button
              className="add-btn"
              onClick={() => navigate("/opexpurchaseinvoice/add")}
            >
              Add
            </button>
          </Col>
        </Row>

        {/* tabs */}
        <Tabs
          activeKey={filterConfig.tab}
          defaultActiveKey={filterConfig.tab}
          id="SE-tabs"
          onSelect={handleTabSelect}
          className="TabStyle1"
        >
          <Tab eventKey="pending" title="pending" className="TabStyle2">
            {commonTabContent("pending")}
            <Table
              tableHeaders={[
                "REQUEST DATE",
                "DUE DATE",
                "BUSINESS UNIT",
                "SUPPLIER",
                "AMOUNT",
                "REMARKS",
                "ACTIONS",
              ]}
              headerSelector={[
                "request_date",
                "due_date",
                "distributor_name",
                "supplier_trade_name",
                "grand_total",
                "remarks",
              ]}
              tableData={suppliesExpenseManager}
              // PendingBtn={(row) => EditBtn(row, "pending")}
              ActionBtn={(row) => ActionBtn(row, "pending")}
              showLoader={showLoader}
              // handleRowClick={(row)=>{
              //   window.open(_baseURL + "review/" + id, "_blank");
              // }}
            />
            <div className="mb-2" />
          </Tab>

          <Tab eventKey="reviewed" title="reviewed" className="TabStyle2">
            {commonTabContent("reviewed")}
            <Table
              tableHeaders={[
                // " ",
                "REQUEST DATE",
                "DUE DATE",
                "BUSINESS UNIT",
                "SUPPLIER",
                "AMOUNT",
                "REMARKS",
                // "REVIEWED BY",
                "ACTIONS",
              ]}
              headerSelector={[
                // "",
                // "id",
                "request_date",
                "due_date",
                "distributor_name",
                "supplier_trade_name",
                "grand_total",
                "remarks",
                // "reviewed_by",
              ]}
              tableData={suppliesExpenseManager}
              // PendingBtn={(row) => ReviewBtn(row, "for approval", "View")}
              ActionBtn={(row) => ActionBtn(row, "reviewed")}
              showLoader={showLoader}
            />
            <div className="mb-2" />
          </Tab>

          <Tab eventKey="approved" title="approved" className="TabStyle2">
            {commonTabContent("approved")}
            <Table
              tableHeaders={[
                // "SE NO.",
                "REQUEST DATE",
                "DUE DATE",
                "BUSINESS UNIT",
                "SUPPLIER",
                "AMOUNT",
                "REMARKS",
                // "APPROVED BY",
                "ACTIONS",
              ]}
              headerSelector={[
                // "id",
                "request_date",
                "due_date",
                "distributor_name",
                "supplier_trade_name",
                "grand_total",
                "remarks",
                // "approved_by",
              ]}
              tableData={suppliesExpenseManager}
              ActionBtn={(row) => ActionBtn(row, "approved")}
              // PendingBtn={(row) => ReviewBtn(row, "for approval", "View")}
              showLoader={showLoader}
            />
            <div className="mb-2" />
          </Tab>

          <Tab eventKey="finalized" title="finalized" className="TabStyle2">
            {commonTabContent("finalized")}
            <Table
              tableHeaders={[
                // "SE NO.",
                "REQUEST DATE",
                "DUE DATE",
                "BUSINESS UNIT",
                "SUPPLIER",
                "AMOUNT",
                "REMARKS",
                // "FINALIZED BY",
                "ACTIONS",
              ]}
              headerSelector={[
                // "id",
                "request_date",
                "due_date",
                "distributor_name",
                "supplier_trade_name",
                "grand_total",
                "remarks",
                // "finalized_by",
              ]}
              tableData={suppliesExpenseManager}
              ActionBtn={(row) => ActionBtn(row, "finalized")}
              showLoader={showLoader}
            />
            <div className="mb-2" />
          </Tab>

          <Tab eventKey="hold" title="hold" className="TabStyle2">
            {commonTabContent("hold")}
            <Table
              tableHeaders={[
                // "SE NO.",
                "REQUEST DATE",
                "DUE DATE",
                "BUSINESS UNIT",
                "SUPPLIER",
                "AMOUNT",
                "REMARKS",
                // "FINALIZED BY",
                "ACTIONS",
              ]}
              headerSelector={[
                // "id",
                "request_date",
                "due_date",
                "distributor_name",
                "supplier_trade_name",
                "grand_total",
                "remarks",
                // "finalized_by",
              ]}
              tableData={suppliesExpenseManager}
              ActionBtn={(row) => ActionBtn(row, "hold")}
              showLoader={showLoader}
            />
            <div className="mb-2" />
          </Tab>

          <Tab eventKey="sent" title="sent" className="TabStyle2">
            {commonTabContent("sent")}
            <Table
              tableHeaders={[
                // "SE NO.",
                "REQUEST DATE",
                "DUE DATE",
                "BUSINESS UNIT",
                "SUPPLIER",
                "AMOUNT",
                "REMARKS",
                // "SENT BY",
                "ACTIONS",
              ]}
              headerSelector={[
                // "id",
                "request_date",
                "due_date",
                "distributor_name",
                "supplier_trade_name",
                "grand_total",
                "remarks",
                // "sent_by",
              ]}
              tableData={suppliesExpenseManager}
              // PendingBtn={(row) => ReceivePOBtn(row, "sent")}
              ActionBtn={(row) => ActionBtn(row, "sent")}
              PaymentBtn={(row) => AddPaymentBtn(row, "sent")}
              showLoader={showLoader}
            />
            <div className="mb-2" />
          </Tab>

          {/* <Tab eventKey="incomplete" title="incomplete" className="TabStyle2">
            {commonTabContent("incomplete")}
            <Table
              tableHeaders={[
                "   ",
                "SE NO.",
                "DATE",
                "SUPPLIER",
                "TOTAL",
                "ADDED BY",
              ]}
              headerSelector={[
                "",
                "id",
                "request_date",
                "supplier_trade_name",
                "grand_total",
                "created_by",
              ]}
              tableData={suppliesExpenseManager}
              ActionBtn={(row) => ActionBtn(row, "incomplete")}
              // PendingBtn={(row) => ViewBtn(row)}
              showLoader={showLoader}
            />
            <div className="mb-2" />
          </Tab> */}

          {/* <Tab eventKey="complete" title="completed" className="TabStyle2">
            {commonTabContent("complete")}
            <Table
              tableHeaders={[
                "SE NO.",
                "INV NO.",
                "DATE",
                "SUPPLIER",
                "TOTAL",
                "PYMT REF NO",
                "PREP BY",
                "APV BY",
                "ACTIONS",
              ]}
              headerSelector={[
                "id",
                "invoice",
                "request_date",
                "supplier_trade_name",
                "grand_total",
                "payment_ref_no",
                "prepared_by_name",
                "approved_by_name",
              ]}
              tableData={suppliesExpenseManager}
              ActionBtn={(row) => ViewBtn(row)}
              InvoiceBtn={(row) => InvoiceBtn(row)}
              newTabBtn={(row) => ContractBtn(row, row.status)}
              showLoader={showLoader}
            />
            <div className="mb-2" />
          </Tab> */}

          <Tab eventKey="disapproved" title="disapproved" className="TabStyle2">
            {commonTabContent("disapproved")}
            <Table
              tableHeaders={[
                // " ",
                // "SE NO.",
                "REQUEST DATE",
                "DUE DATE",
                "BUSINESS UNIT",
                "SUPPLIER",
                "AMOUNT",
                "REMARKS",
                // "APV BY",
                "ACTIONS",
              ]}
              headerSelector={[
                // "",
                // "id",
                "request_date",
                "due_date",
                "distributor_name",
                "supplier_trade_name",
                "grand_total",
                "remarks",
                // "prepared_by_name",
                // "approved_by_name",
              ]}
              tableData={suppliesExpenseManager}
              // PendingBtn={(row) => ReturnBtn(row, "disapproved")}
              ActionBtn={(row) => ActionBtn(row, "disapproved")}
              showLoader={showLoader}
            />
            <div className="mb-2" />
          </Tab>
        </Tabs>
      </div>

      {/* modals */}
      <DeleteModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        text="operational expense"
        onDelete={handleDeleteOperationalExpense}
      />
      <SEModal
        show={showReturnModal}
        hide={() => setShowReturnModal(false)}
        type="return"
        handler={handleReturnSE}
      />
      <POModal
        show={showClosePOModal}
        hide={() => setShowClosePOModal(false)}
        type="close"
        handler={handleClosePO}
      />
      <PoPaymentModal
        id={supExpenseID.id}
        show={showAddPaymentModal}
        onHide={() => setShowAddPaymentModal(false)}
        balance={bal}
        payee={supExpenseID.name}
      />
    </div>
  )
}
