import React, { useState, useRef, useEffect } from "react"
import { Col, Form, Row } from "react-bootstrap"
//components
import Table from "../../Components/TableTemplate/DataTable"
import Navbar from "../../Components/Navbar/Navbar"
import DeleteModal from "../../Components/Modals/DeleteModal"
import AddModal from "../../Components/Modals/AddModal"
import EditModal from "../../Components/Modals/EditModal"
import ViewModal from "../../Components/Modals/ViewModal"

import { getType, toastStyle } from "../../Helpers/Utils/Common"
import toast from "react-hot-toast"
import {
  createSupplier,
  deleteSupplier,
  updateSupplier,
  searchSupplier,
} from "../../Helpers/apiCalls/suppliersApi"
// import { validateSuppliers } from "../../Helpers/Validation/Manage/SuppliersValidation"
// import InputError from "../../Components/InputError/InputError"

export default function Suppliers() {
  const accountType = getType()
  const [inactive, setInactive] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [isClicked, setIsClicked] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [tempSearchText, setTempSearchText] = useState("")

  const [filterConfig, setFilterConfig] = useState({
    tab: "WISHCRAFT ERP",
  })
  const handleTabSelect = (tabKey) => {
    setFilterConfig((prev) => {
      return { ...prev, tab: tabKey }
    })
  }
  const isInitialMount = useRef(true)
  const filterConfigKey = "manage-suppliers-filterConfig"

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
      setFilterConfig((prev) => {
        if (window.localStorage.getItem(filterConfigKey) != null) {
          handleTabSelect(
            JSON.parse(window.localStorage.getItem(filterConfigKey)).tab
          )
          return JSON.parse(window.localStorage.getItem(filterConfigKey))
        } else {
          return { ...prev }
        }
      })
    } else {
      window.localStorage.setItem(filterConfigKey, JSON.stringify(filterConfig))
    }
  }, [filterConfig])

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showViewSupplierModal, setShowViewSupplierModal] = useState(false)
  const [showEditSupplierModal, setShowEditSupplierModal] = useState(false)
  const [showAddSupplierModal, setShowAddSupplierModal] = useState(false)

  //API
  const [tableData, setTableData] = useState([])
  // const [filteredData, setFilteredData] = useState([]);
  // const [formValues, setSupplierData] = useState({})
  // const [selectedID, setSelectedID] = useState("");
  const [formValues, setFormValues] = useState({
    trade_name: "",
    trade_address: "",
    bir_name: "",
    bir_address: "",
    tin: "",
    terms: "",
    requirements: "",
    phone_no: "",
    email: "",
    contact_person: "",
    bank_primary: "",
    bank_alternate: "",
    payee: "",
  })

  //onEdit
  function handleOnEdit() {
    setShowViewSupplierModal(false)
    setShowEditSupplierModal(true)
  }

  //DELETE or REMOVE SUPPLIER
  function handleDeleteSupplier() {
    removeSupplier(formValues.id)
  }

  function resetValues() {
    setFormValues({})
    // setSupplierData({})
  }

  //API CALL
  async function fetchAllSuppliers() {
    setShowLoader(true)
    setTableData([])
    const response = await searchSupplier(searchText)
    if (response.response) {
      var suppliersList = response.response.data
      setTableData(suppliersList)
    }

    setShowLoader(false)
  }

  async function handleSaveSupplier() {
    // if (validateSuppliers(formValues, setIsError)) {
    setIsClicked(true)
    const response = await createSupplier(formValues)
    if (response.response) {
      toast.success("Successfully created supplier!", {
        style: toastStyle(),
      })
      setShowAddSupplierModal(false)
      setSearchText("")
    } else {
      toast.error(response.error.data.messages.error, {
        style: toastStyle(),
      })
    }
    setIsClicked(false)
    // } else {
    //   toast.error("Error Creating New Supplier", {
    //     style: toastStyle(),
    //   })
    // }
  }

  async function handleEditSupplier() {
    // if (validateSuppliers(formValues, setIsError)) {
    setIsClicked(true)
    const response = await updateSupplier(formValues)
    if (response.response) {
      toast.success(response.response.response, {
        style: toastStyle(),
      })
      setShowEditSupplierModal(false)
      setShowViewSupplierModal(false)
      setSearchText("")
    } else {
      toast.error("Error Updating New Supplier", {
        style: toastStyle(),
      })
    }
    setIsClicked(false)
  }

  async function removeSupplier(id) {
    const response = await deleteSupplier(id)
    if (response.data) {
      toast.success("Successfully deleted supplier!", {
        style: toastStyle(),
      })
      setShowDeleteModal(false)
      setShowViewSupplierModal(false)
      resetValues()
      setSearchText("")
    } else {
      toast.error(response.error.data.messages.error, {
        style: toastStyle(),
      })
    }
  }

  //EDIT OR UPDATE FORWARDER
  const handleEditChange = (e) => {
    const { name, value } = e.target
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  React.useEffect(() => {
    fetchAllSuppliers()
  }, [searchText])

  const [submittableForm, setSubmittableForm] = useState(false)
  React.useEffect(() => {
    const requiredFieldKeys = ["trade_name"]
    let isValid = true
    requiredFieldKeys.forEach((key) => {
      if (
        !formValues[key] ||
        (formValues[key] &&
          typeof formValues[key] === "string" &&
          formValues[key]?.trim() === "")
      ) {
        isValid = false
      }
    })
    setSubmittableForm(isValid)
  }, [formValues.trade_name])

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive)
          }}
          active={"SUPPLIERS"}
        />
      </div>
      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        <Row className="mb-4">
          <Col xs={6}>
            <h1 className="page-title"> SUPPLIERS </h1>
          </Col>
          <Col className="d-flex justify-content-end">
            <input
              type="search"
              name="name"
              placeholder="Search company name"
              value={searchText}
              onChange={(e) => setTempSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setSearchText(tempSearchText)
                }
              }}
              className="search-bar"
            />
            <button
              className="add-btn"
              eventKey="WISHCRAFT ERP"
              onClick={() => setShowAddSupplierModal(true)}
            >
              Add
            </button>
          </Col>
        </Row>
        <div className="tab-content">
          <Table
            isPointer
            tableHeaders={
              accountType === "admin"
                ? [
                    "COMPANY NAME",
                    "ADDRESS",
                    "CONTACT PERSON",
                    "PHONE NUMBER",
                    "COMPANY EMAIL",
                    // "ACTIONS",
                  ]
                : [
                    "COMPANY NAME",
                    "ADDRESS",
                    "CONTACT PERSON",
                    "PHONE NUMBER",
                    "COMPANY EMAIL",
                  ]
            }
            headerSelector={[
              "trade_name",
              "trade_address",
              "contact_person",
              "phone_no",
              "email",
            ]}
            tableData={tableData}
            // ActionBtn={(row) => ActionBtn(row)}
            showLoader={showLoader}
            handleRowClick={(row) => {
              // setSupplierData(row)
              setFormValues(row)
              setShowViewSupplierModal(true)
            }}
            enablePagination={true}
          />
        </div>
      </div>

      {/* MODALS */}
      <DeleteModal
        text="supplier"
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onDelete={handleDeleteSupplier}
      />

      <AddModal
        title={`SUPPLIER`}
        show={showAddSupplierModal}
        onHide={() => {
          setShowAddSupplierModal(false)
          resetValues()
        }}
        onSave={handleSaveSupplier}
        isClicked={isClicked}
        data={formValues}
        disableSave={!submittableForm}
      >
        <div className="mt-3 ">
          <Row className="nc-modal-custom-row mt-0">
            <Col xs={6}>
              BIR NAME
              <Form.Control
                type="text"
                name="bir_name"
                value={formValues.bir_name}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={6}>
              TRADE NAME <label className="badge-required">{` *`}</label>
              <Form.Control
                type="text"
                name="trade_name"
                value={formValues.trade_name}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    [e.target.name]: e.target.value,
                  })
                }
                required
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={6}>
              BIR-REGISTERED ADDRESS
              <Form.Control
                type="text"
                name="bir_address"
                value={formValues.bir_address}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={6}>
              TRADE ADDRESS
              <Form.Control
                type="text"
                name="trade_address"
                value={formValues.trade_address}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              TIN NUMBER{" "}
              <Form.Control
                type="text"
                name="tin"
                value={formValues.tin}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={4}>
              BIR NUMBER
              <Form.Control
                type="text"
                name="bir_number"
                className="nc-modal-custom-input"
                value={formValues.bir_number}
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={4} className="nc-modal-custom-row-details">
              TERMS (NO. OF DAYS)
              <Form.Control
                type="text"
                name="terms"
                value={formValues.terms}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              CONTACT PERSON
              <Form.Control
                type="text"
                name="contact_person"
                value={formValues.contact_person}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={4}>
              PHONE NUMBER
              <Form.Control
                type="text"
                name="phone_no"
                value={formValues.phone_no}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={4}>
              PAYEE
              <Form.Control
                type="email"
                name="payee"
                value={formValues.payee}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    [e.target.name]: e.target.value,
                  })
                }
                required
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              PRIMARY BANK NAME
              <Form.Control
                type="text"
                name="bank_primary"
                value={formValues.bank_primary}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={4}>
              PRIMARY ACCOUNT NO.
              <Form.Control
                type="number"
                onWheel={(e) => e.target.blur()}
                name="primary_account_no"
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={4}>
              PRIMARY ACCOUNT NAME
              <Form.Control
                type="text"
                name="primary_account_name"
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              ALTERNATE BANK NAME
              <Form.Control
                type="text"
                name="bank_alternate"
                value={formValues.bank_alternate}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={4}>
              ALTERNATE ACCOUNT NO.
              <Form.Control
                type="number"
                onWheel={(e) => e.target.blur()}
                name="alternate_account_no"
                className="nc-modal-custom-input"
                value={formValues.alternate_account_no}
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={4}>
              ALTERNATE ACCOUNT NAME
              <Form.Control
                type="text"
                name="alternate_account_name"
                className="nc-modal-custom-input"
                value={formValues.alternate_account_name}
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              COMPANY EMAIL
              <Form.Control
                type="email"
                name="email"
                value={formValues.email}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
        </div>
      </AddModal>

      <EditModal
        title="SUPPLIER"
        show={showEditSupplierModal}
        onHide={() => {
          setShowEditSupplierModal(false)
          resetValues()
        }}
        onSave={handleEditSupplier}
        data={formValues}
        disableSave={!submittableForm}
        isClicked={isClicked}
      >
        <div className="mt-3 ">
          <Row className="nc-modal-custom-row mt-0">
            <Col xs={6}>
              BIR NAME
              <Form.Control
                type="text"
                name="bir_name"
                value={formValues.bir_name}
                onChange={(e) => handleEditChange(e)}
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={6}>
              TRADE NAME <label className="badge-required">{` *`}</label>
              <Form.Control
                type="text"
                name="trade_name"
                value={formValues.trade_name}
                onChange={(e) => handleEditChange(e)}
                className="nc-modal-custom-input-edit"
                required
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={6}>
              BIR-REGISTERED ADDRESS
              <Form.Control
                type="text"
                name="bir_address"
                value={formValues.bir_address}
                onChange={(e) => handleEditChange(e)}
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={6}>
              TRADE ADDRESS
              <Form.Control
                type="text"
                name="trade_address"
                value={formValues.trade_address}
                onChange={(e) => handleEditChange(e)}
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              TIN NUMBER
              <Form.Control
                type="text"
                name="tin"
                value={formValues.tin}
                onChange={(e) => handleEditChange(e)}
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={4}>
              BIR NUMBER
              <Form.Control
                type="text"
                name="bir_number"
                value={formValues.bir_number}
                onChange={(e) => handleEditChange(e)}
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              CONTACT PERSON
              <Form.Control
                type="text"
                name="contact_person"
                value={formValues.contact_person}
                className="nc-modal-custom-input"
                onChange={(e) => handleEditChange(e)}
              />
            </Col>
            <Col xs={4}>
              PHONE NUMBER
              <Form.Control
                type="text"
                name="phone_no"
                value={formValues.phone_no}
                className="nc-modal-custom-input"
                onChange={(e) => handleEditChange(e)}
              />
            </Col>
            <Col xs={4}>
              PAYEE
              <Form.Control
                type="email"
                name="payee"
                value={formValues.payee}
                className="nc-modal-custom-input"
                onChange={(e) => handleEditChange(e)}
                required
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              PRIMARY BANK NAME
              <Form.Control
                type="text"
                name="bank_primary"
                value={formValues.bank_primary}
                onChange={(e) => handleEditChange(e)}
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={4}>
              PRIMARY ACCOUNT NO.
              <Form.Control
                type="text"
                name="primary_account_no"
                value={formValues.primary_account_no}
                onChange={(e) => handleEditChange(e)}
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={4}>
              PRIMARY ACCOUNT NAME
              <Form.Control
                type="text"
                name="primary_account_name"
                value={formValues.primary_account_name}
                onChange={(e) => handleEditChange(e)}
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              ALTERNATE BANK NAME
              <Form.Control
                type="text"
                name="bank_alternate"
                value={formValues.bank_alternate}
                onChange={(e) => handleEditChange(e)}
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={4}>
              ALTERNATE ACCOUNT NO.
              <Form.Control
                type="text"
                name="alternate_account_no"
                value={formValues.alternate_account_no}
                onChange={(e) => handleEditChange(e)}
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={4}>
              ALTERNATE ACCOUNT NAME
              <Form.Control
                type="text"
                name="alternate_account_name"
                value={formValues.alternate_account_name}
                onChange={(e) => handleEditChange(e)}
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4} className="nc-modal-custom-row-details">
              TERMS (NO. OF DAYS)
              <Form.Control
                type="text"
                name="terms"
                value={formValues.terms}
                onChange={(e) => handleEditChange(e)}
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={4} className="nc-modal-custom-row-details">
              COMPANY EMAIL
              <Form.Control
                type="email"
                name="email"
                className="nc-modal-custom-input-edit"
                value={formValues.email}
                onChange={(e) => handleEditChange(e)}
              />
            </Col>
          </Row>
        </div>
      </EditModal>

      <ViewModal
        withButtons
        show={showViewSupplierModal}
        onHide={() => {
          setShowViewSupplierModal(false)
          resetValues()
        }}
        onEdit={handleOnEdit}
        onDelete={() => setShowDeleteModal(true)}
      >
        <div className="mt-1">
          <span className="custom-modal-body-title">SUPPLIER DETAILS</span>
          <Row className="nc-modal-custom-row mt-3">
            <Col xs={6}>
              BIR NAME
              <Form.Control
                type="text"
                name="bir_name"
                value={formValues.bir_name}
                disabled
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={6}>
              TRADE NAME <label className="badge-required">{` *`}</label>
              <Form.Control
                type="text"
                name="trade_name"
                value={formValues.trade_name}
                className="nc-modal-custom-input-edit"
                disabled
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={6}>
              BIR-REGISTERED ADDRESS
              <Form.Control
                type="text"
                name="bir_address"
                value={formValues.bir_address}
                disabled
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={6}>
              TRADE ADDRESS
              <Form.Control
                type="text"
                name="trade_address"
                value={formValues.trade_address}
                disabled
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              TIN NUMBER
              <Form.Control
                type="text"
                name="tin"
                value={formValues.tin}
                disabled
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={4}>
              BIR NUMBER
              <Form.Control
                type="text"
                name="bir_number"
                value={formValues.bir_number}
                disabled
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={4} className="nc-modal-custom-row-details">
              TERMS (NO. OF DAYS)
              <Form.Control
                type="text"
                name="terms"
                value={formValues.terms}
                disabled
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              CONTACT PERSON
              <Form.Control
                type="text"
                name="contact_person"
                value={formValues.contact_person}
                className="nc-modal-custom-input"
                disabled
              />
            </Col>
            <Col xs={4}>
              PHONE NUMBER
              <Form.Control
                type="text"
                name="phone_no"
                value={formValues.phone_no}
                className="nc-modal-custom-input"
                disabled
              />
            </Col>
            <Col xs={4}>
              PAYEE
              <Form.Control
                type="email"
                name="payee"
                value={formValues.payee}
                className="nc-modal-custom-input"
                disabled
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              PRIMARY BANK NAME
              <Form.Control
                type="text"
                name="bank_primary"
                value={formValues.bank_primary}
                disabled
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={4}>
              PRIMARY ACCOUNT NO.
              <Form.Control
                type="text"
                name="primary_account_no"
                value={formValues.primary_account_no}
                disabled
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={4}>
              PRIMARY ACCOUNT NAME
              <Form.Control
                type="text"
                name="primary_account_name"
                value={formValues.primary_account_name}
                disabled
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              ALTERNATE BANK NAME
              <Form.Control
                type="text"
                name="bank_alternate"
                value={formValues.bank_alternate}
                disabled
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={4}>
              ALTERNATE ACCOUNT NO.
              <Form.Control
                type="text"
                name="alternate_account_no"
                value={formValues.alternate_account_no}
                disabled
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={4}>
              ALTERNATE ACCOUNT NAME
              <Form.Control
                type="text"
                name="alternate_account_name"
                value={formValues.alternate_account_name}
                disabled
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4} className="nc-modal-custom-row-details">
              COMPANY EMAIL
              <Form.Control
                type="email"
                name="email"
                className="nc-modal-custom-input-edit"
                value={formValues.email}
                disabled
              />
            </Col>
          </Row>
        </div>
      </ViewModal>
    </div>
  )
}
