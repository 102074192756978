import React, { useEffect, useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
//components
import Table from "../../Components/TableTemplate/DataTable"
import Navbar from "../../Components/Navbar/Navbar"
import DeleteModal from "../../Components/Modals/DeleteModal"
import AddModal from "../../Components/Modals/AddModal"
import EditModal from "../../Components/Modals/EditModal"
import ViewModal from "../../Components/Modals/ViewModal"
import toast from "react-hot-toast"
import { selectDropdownStyle, toastStyle } from "../../Helpers/Utils/Common"
import { UseGetRequest } from "../../Helpers/hooks/GetRequests"
import { UsePostRequest } from "../../Helpers/hooks/UsePostApiCall"
import { deleteBank } from "../../Helpers/apiCalls/bankApi"
import Select from "react-select"
import { getLastSubtypes } from "../../Helpers/apiCalls/projectApi"

export default function Banks() {
  const [inactive, setInactive] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [isClicked, setIsClicked] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [accountTypes, setAccountTypes] = useState([])
  const [bankOptions, setBankOptions] = useState([])
  const handleCloseDeleteModal = () => setShowDeleteModal(false)
  const [showViewCustomerModal, setShowViewCustomerModal] = useState(false)
  const [showEditCustomerModal, setShowEditCustomerModal] = useState(false)
  const handleCloseEditProjectModal = () => {
    setShowEditCustomerModal(false)
    resetValues()
  }
  const [showAddCustomerModal, setShowAddCustomerModal] = useState(false)

  // const [isError, setIsError] = useState({
  //   name: false,
  //   company: false,
  // })
  const [tableData, setTableData] = useState([])
  const [selectedRow, setSelectedRow] = useState([])
  const [formValues, setFormValues] = useState({
    name: "",
    account_no: "",
    account_name: "",
  })

  function resetValues() {
    setFormValues({
      name: "",
      account_no: "",
      account_name: "",
    })
  }

  const handleAddChange = (e) => {
    const { name, value } = e.target
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleEditChange = (e) => {
    const { name, value } = e.target
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  async function fetchLastSubtypes(data) {
    const response = await getLastSubtypes()
    if (response.data) {
      const ceNos = response.data.data.flatMap((item) => {
        return item.last_types.map((subtype) => ({
          name: "ce_no",
          value: subtype.id,
          label: subtype.name,
        }))
      })

      //set original subtypes
      setAccountTypes(ceNos)

      //filter the expense subtypes, this will be used in adding banks
      const filteredData = ceNos.filter(
        (ceNo) => !data.some((tableItem) => tableItem.subtype === ceNo.label)
      )
      setBankOptions(filteredData)
    } else {
      setAccountTypes([])
      setBankOptions([])
    }
  }

  async function create() {
    // if (validateCustomer(formValues, setIsError)) {
    setIsClicked(true)
    const response = await UsePostRequest("banks/create", formValues)
    if (response) {
      if (response?.data?.status === "success") {
        toast.success(response.data.response, {
          style: toastStyle(),
        })
        setShowAddCustomerModal(false)
        resetValues()
        fetchBanks()
      }
      if (response?.error?.data?.messages?.name) {
        toast.error(response.error.data.messages.name, {
          style: toastStyle(),
        })
      }
      if (response?.error?.data?.messages?.account_name) {
        toast.error(response.error.data.messages.account_name, {
          style: toastStyle(),
        })
      }
    }
    setIsClicked(false)
    // }
  }

  async function fetchBanks() {
    setTableData([])
    // setFilteredData([]);
    setShowLoader(true)
    const response = await UseGetRequest("banks/get_all_bank", {
      term: searchText,
    })

    if (response.data) {
      setTableData(response.data.data)

      fetchLastSubtypes(response.data.data)
    }
    setShowLoader(false)
  }

  async function searchBank() {
    setTableData([])
    // setFilteredData([]);
    setShowLoader(true)
    const response = await UseGetRequest("banks/search", { term: searchText })
    if (response.data) {
      setTableData(response.data.data)
    }
    setShowLoader(false)
  }

  async function del() {
    const response = await deleteBank(selectedRow.id)
    if (response) {
      if (response?.data?.status === "success") {
        toast.success(response.data.response, {
          style: toastStyle(),
        })
        handleCloseDeleteModal()
        setShowViewCustomerModal(false)
        fetchBanks()
        // setTimeout(() => refreshPage(), 1000);
      } else {
        toast.error(response.error.data.messages.error, {
          style: toastStyle(),
        })
      }
    }
  }

  async function editCustomer() {
    // if (validateCustomer(formValues, setIsError)) {
    setIsClicked(true)
    const payload = {
      bank_id: formValues.id,
      // name: formValues.name,
      // account_name: formValues.account_name,
      // account_no: formValues.account_no,
      expense_type_id: formValues.expense_type_id,
    }
    const response = await UsePostRequest("banks/update", payload)
    if (response) {
      if (response?.data?.status === "success") {
        toast.success(response.data.response, {
          style: toastStyle(),
        })
        setShowEditCustomerModal(false)
        setShowViewCustomerModal(false)
        resetValues()
        fetchBanks()
      } else {
        toast.error(response.error.data.messages.error, {
          style: toastStyle(),
        })
      }
    }
    setIsClicked(false)
    // }
  }

  React.useEffect(() => {
    fetchBanks()
    // fetchLastSubtypes()
  }, [])

  const [submittableForm, setSubmittableForm] = useState(false)
  React.useEffect(() => {
    const requiredFieldKeys = ["expense_type_id"]
    let isValid = true
    requiredFieldKeys.forEach((key) => {
      if (
        !formValues[key] ||
        (formValues[key] &&
          typeof formValues[key] === "string" &&
          formValues[key]?.trim() === "")
      ) {
        isValid = false
      }
    })
    setSubmittableForm(isValid)
  }, [formValues.name, formValues.account_no, formValues.account_name])

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive)
          }}
          active={"BANKS"}
        />
      </div>
      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        <Row className="mb-4">
          <Col xs={6}>
            <h1 className="page-title"> BANKS </h1>
          </Col>
          <Col className="d-flex justify-content-end">
            <input
              type="search"
              name="name"
              placeholder="Search bank name"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              className="search-bar"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  searchBank()
                }
              }}
            />
            <button
              className="add-btn"
              onClick={() => setShowAddCustomerModal(true)}
            >
              Add
            </button>
          </Col>
        </Row>

        <div className="tab-content">
          <Table
            tableHeaders={[
              "NAME",
              // "ACCOUNT NUMBER",
              // "ACCOUNT NAME",
              // "ACTIONS",
            ]}
            headerSelector={[
              "subtype",
              // "account_no", "account_name"
            ]}
            tableData={tableData}
            showLoader={showLoader}
            handleRowClick={(row) => {
              setSelectedRow(row)
              setFormValues(row)
              setShowViewCustomerModal(true)
            }}
            isPointer
            // handleRowHover={handleRowHover}
            screen={"banks"}
            // withActionData={true}
          />
        </div>
      </div>

      <DeleteModal
        text="bank"
        show={showDeleteModal}
        onHide={handleCloseDeleteModal}
        onDelete={del}
      />

      <AddModal
        title="BANK"
        size="lg"
        type="branch"
        show={showAddCustomerModal}
        onHide={() => {
          setShowAddCustomerModal(false)
          resetValues()
        }}
        onSave={() => create()}
        isClicked={isClicked}
        data={formValues}
        disableSave={!submittableForm}
      >
        <div className="mt-3">
          <Row className="nc-modal-custom-row">
            <Col>
              NAME
              <span className="required-icon">*</span>
              {/* <Form.Control
                type="text"
                name="name"
                value={formValues.name}
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleAddChange(e)}
                required
              /> */}
              <Select
                name="name"
                className=""
                classNamePrefix="react-select"
                placeholder="Select"
                options={bankOptions}
                onChange={(e) => {
                  setFormValues({ expense_type_id: e.value })
                }}
              />
            </Col>
          </Row>
          {/* <Row className="nc-modal-custom-row">
            <Col>
              ACCOUNT NUMBER
              <span className="required-icon">*</span>
              <Form.Control
                type="number"
                name="account_no"
                value={formValues.account_no}
                className="nc-modal-custom-input"
                onChange={(e) => handleAddChange(e)}
                required
              />
            </Col>
            <Col>
              ACCOUNT NAME
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="account_name"
                value={formValues.account_name}
                className="nc-modal-custom-input"
                onChange={(e) => handleAddChange(e)}
                required
              />
            </Col>
          </Row> */}
        </div>
      </AddModal>

      <EditModal
        title="BANK"
        size="lg"
        type="project"
        show={showEditCustomerModal}
        onHide={handleCloseEditProjectModal}
        onSave={() => editCustomer()}
        data={formValues}
        disableSave={!submittableForm}
        isClicked={isClicked}
      >
        <div className="mt-3 ">
          <Row className="nc-modal-custom-row">
            <Col>
              NAME
              <span className="required-icon">*</span>
              {/* <Form.Control
                type="text"
                name="name"
                value={formValues.name}
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleEditChange(e)}
                required
              /> */}
              <Select
                name="name"
                className=""
                classNamePrefix="react-select"
                placeholder="Select"
                options={bankOptions}
                defaultValue={accountTypes.find(
                  (item) => formValues.expense_type_id === item.value
                )}
                value={bankOptions.find(
                  (item) => formValues.expense_type_id === item.value
                )}
                onChange={(e) => {
                  setFormValues((prev) => ({
                    ...prev,
                    expense_type_id: e.value,
                  }))
                }}
              />
            </Col>
          </Row>
          {/* <Row className="nc-modal-custom-row">
            <Col>
              ACCOUNT NAME
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="account_name"
                value={formValues.account_name}
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleEditChange(e)}
                required
              />
            </Col>
            <Col>
              ACCOUNT NUMBER <span className="required-icon">*</span>
              <Form.Control
                type="number"
                name="account_no"
                value={formValues.account_no}
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleEditChange(e)}
              />
            </Col>
          </Row> */}
        </div>
      </EditModal>

      <ViewModal
        withButtons
        size="lg"
        show={showViewCustomerModal}
        onHide={() => {
          setShowViewCustomerModal(false)
          resetValues()
        }}
        onEdit={() => {
          setShowEditCustomerModal(true)
          setShowViewCustomerModal(false)
        }}
        onDelete={() => setShowDeleteModal(true)}
      >
        <div className="mt-1">
          <span className="custom-modal-body-title">BANK DETAILS</span>
          <Row className="nc-modal-custom-row mt-3">
            <Col>
              NAME
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="name"
                value={formValues.subtype}
                className="nc-modal-custom-input-edit"
                disabled
              />
            </Col>
          </Row>
          {/* <Row className="nc-modal-custom-row">
            <Col>
              ACCOUNT NAME
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="company"
                value={formValues.account_name}
                className="nc-modal-custom-input-edit"
                disabled
              />
            </Col>
            <Col>
              ACCOUNT NUMBER
              <Form.Control
                type="text"
                name="contact_number"
                value={formValues.account_no}
                className="nc-modal-custom-input-edit"
                disabled
              />
            </Col>
          </Row> */}
        </div>
      </ViewModal>
    </div>
  )
}
