import React, { useState, useEffect } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import toast from "react-hot-toast"
import Navbar from "../../Components/Navbar/Navbar"
import Table from "../../Components/TableTemplate/DataTable"
import DeleteModal from "../../Components/Modals/DeleteModal"
import { toastStyle } from "../../Helpers/Utils/Common"
import {
  getDistributor,
  deleteDistributor,
} from "../../Helpers/apiCalls/DistributorApi"
import ViewModal from "../../Components/Modals/ViewModal"

export default function Distributor() {
  let navigate = useNavigate()
  const [inactive, setInactive] = useState(true)
  const [tableData, setTableData] = useState([])
  const [showLoader, setShowLoader] = useState(false)
  const [selectedRow, setSelectedRow] = useState({})
  const [searchText, setSearchText] = useState("")
  const [filterConfig, setFilterConfig] = useState({
    distributor_id: "",
    billing_date: "",
  })

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showViewCustomerModal, setShowViewCustomerModal] = useState(false)
  const handleCloseViewProjectModal = () => setShowViewCustomerModal(false)

  function handleRedirect() {
    const rowData = {
      name: selectedRow.name,
      address: selectedRow.address,
      tin: selectedRow.tin,
      contact_no: selectedRow.contact_no,
      distributor_attachment: selectedRow.distributor_attachment,
      id: selectedRow.id,
      email: selectedRow.email,
    }
    navigate("/businessunits/form", {
      state: {
        formPurpose: "EDIT",
        passedId: selectedRow.id,
        distributorDetails: rowData,
      },
    })
  }

  async function fetchData() {
    setShowLoader(true)
    setTableData([])
    const response = await getDistributor(filterConfig)
    if (response.data) {
      const result = response.data.data.map((row) => {
        return {
          ...row,
          logo: (
            <img
              src={row.distributor_attachment[0]?.base_64}
              alt=""
              className="image-preview-container"
            />
          ),
        }
      })
      setTableData(result)
    }
    setShowLoader(false)
  }

  async function handleDelete() {
    const response = await deleteDistributor(selectedRow.id)
    if (response.data) {
      toast.success(response.data.response, { style: toastStyle() })
      setShowDeleteModal(false)
      setShowViewCustomerModal(false)
      fetchData()
    } else {
      toast.error(response.error.data.messages.response, {
        style: toastStyle(),
      })
    }
  }

  useEffect(() => {
    fetchData()
  }, [filterConfig])

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive)
          }}
          active={"BUSINESS UNITS"}
        />
      </div>

      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        {/* headers */}
        <Row className="mb-4">
          <Col xs={6}>
            <h1 className="page-title">BUSINESS UNIT REGISTER</h1>
          </Col>
          <Col xs={6} className="d-flex justify-content-end">
            <input
              type="search"
              name="name"
              placeholder="Search business unit name"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setFilterConfig((prev) => ({ ...prev, name: searchText }))
                }
              }}
              // onChange={(e) => setFilterConfig((prev)=>({...prev, name: e.target.value}))}
              className="search-bar"
            />
            <button
              className="add-btn"
              onClick={() =>
                navigate("/businessunits/form", {
                  state: { formPurpose: "ADD" },
                })
              }
            >
              Add
            </button>
          </Col>
        </Row>

        <div className="tab-content">
          <Table
            tableHeaders={[
              "LOGO",
              "BUSINESS UNIT NAME",
              "ADDRESS",
              "TIN",
              "EMAIL",
            ]}
            headerSelector={["logo", "name", "address", "tin", "email"]}
            tableData={tableData}
            showLoader={showLoader}
            withActionData={false}
            handleRowClick={(row) => {
              setSelectedRow(row)
              setShowViewCustomerModal(true)
            }}
            isPointer
            screen={"business_unit"}
          />
        </div>
        <div className="mb-2" />
      </div>

      <DeleteModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        text="business unit"
        onDelete={handleDelete}
      />

      <ViewModal
        withButtons
        size="xl"
        show={showViewCustomerModal}
        onHide={handleCloseViewProjectModal}
        onEdit={handleRedirect}
        onDelete={() => setShowDeleteModal(true)}
      >
        <div className="mt-1">
          <span className="custom-modal-body-title">REVIEW BUSINESS UNIT</span>
          <Row className="mt-3">
            <Col lg={2}>
              {selectedRow.distributor_attachment &&
              selectedRow.distributor_attachment.length > 0 ? (
                <img
                  src={selectedRow.distributor_attachment[0].base_64}
                  alt=""
                  className="image-preview-container"
                />
              ) : (
                <img alt="" className="image-preview-placeholder" /> // Fallback content when no image is available
              )}
            </Col>
            <Col>
              <Row className="nc-modal-custom-row">
                <Col>
                  BUSINESS UNIT NAME
                  <Form.Control
                    type="text"
                    name="name"
                    value={selectedRow.name}
                    className="nc-modal-custom-input-edit"
                    disabled
                  />
                </Col>
                <Col>
                  ADDRESS
                  <Form.Control
                    type="text"
                    name="company"
                    value={selectedRow.address}
                    className="nc-modal-custom-input-edit"
                    disabled
                  />
                </Col>
                {/* <Col>
                  CONTACT PERSON
                  <Form.Control
                    type="text"
                    name="contact_number"
                    value={selectedRow.contact_person}
                    className="nc-modal-custom-input-edit"
                    disabled
                  />
                </Col> */}
                <Col>
                  TIN
                  <Form.Control
                    type="text"
                    value={selectedRow.tin}
                    className="nc-modal-custom-input-edit"
                    disabled
                  />
                </Col>
              </Row>
              <Row className="nc-modal-custom-row">
                <Col>
                  CONTACT NUMBER
                  <Form.Control
                    type="text"
                    name="company"
                    value={selectedRow.contact_no}
                    className="nc-modal-custom-input-edit"
                    disabled
                  />
                </Col>
                <Col>
                  EMAIL
                  <Form.Control
                    type="text"
                    value={selectedRow.email}
                    className="nc-modal-custom-input-edit"
                    disabled
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </ViewModal>
    </div>
  )
}
