import { getUser, getToken, getAPIKey } from "../Utils/Common";
import axios from "axios";
import toast from "react-hot-toast";

const postConfig = {
  headers: {
    "api-key": "daccfc89-ff47-4ce1-99bf-5ad2d8f57282",
    "user-key": getAPIKey(),
    "Content-Type": "application/json",
  },
};

const postConfigFormData = {
  headers: {
    "api-key": "daccfc89-ff47-4ce1-99bf-5ad2d8f57282",
    "user-key": getAPIKey(),
    "Content-Type": "multipart/form-data",
  },
};

// DON'T BE TRICKED, THIS MAY BE POST BUT THIS ACTUALLY IS A GET INSIDE A POST METHOD.
// BACKEDN tingz I have to conform to
export const UsePostRequest = async (endpoint, values, isFormData = false) => {
  try {
    var payload = isFormData ? new FormData() : {};

    if (isFormData) {
      payload.append("requester", getUser());
      payload.append("token", getToken());

      values.forEach((data) => {
        if (Array.isArray(data.value)) {
          // Check if the array contains arrays
          const hasArrays = data.value.some((item) => Array.isArray(item));
          if (hasArrays) {
            // If the array contains arrays, append each sub-array as a separate parameter
            data.value.forEach((itemArray, index) => {
              itemArray.forEach((item) => {
                payload.append(`${data.key}[${index}][]`, item);
              });
            });
          } else {
            // Modification to address missing payload variables if lengths are 0
            if (data.value && data.value.length > 0) {
              data.value.forEach((item) => {
                payload.append(`${data.key}[]`, item);
              });
            } else {
              payload.append(`${data.key}[]`, []);
            }
          }
        } else {
          payload.append(data.key, data.value);
        }
      });
    } else {
      payload = {
        requester: getUser(),
        token: getToken(),
        ...values,
      };
    }

    const response = await axios.post(
      `${process.env.REACT_APP_LINK}${endpoint}`,
      payload,
      isFormData ? postConfigFormData : postConfig
    );

    // if not delete and endpoint
    // if (endpoint.split("/")[1] !== "delete") {
    //   if ("has_errors" in response.data) {
    //     if (response.data.has_errors === false) {
    //       toast.success("Success!");
    //     } else {
    //       response.data.errors.forEach((message) => {
    //         toast.error(message);
    //       });
    //     }
    //   } else if (Array.isArray(response.data)) {
    //     response.data.forEach((message) => {
    //       toast.success(message);
    //     });
    //   } else {
    //     // if (response.data.data && response.data.data.message) {
    //     //   toast.success(response.data.data.message);
    //     // } else if (response.data.response && response.data.response.message) {
    //     //   toast.success(response.data.response.message);
    //     // } else if (response.data.message && response.data.message.message) {
    //     //   if (response.data.status===200) {
    //     //     toast.success(response.data.message.message)
    //     //   } else {
    //     //     toast.error(response.data.message.message)
    //     //   }
    //     // } else if (response.data.message) {
    //     //   toast.success(response.data.message);
    //     // } else if (response.data.response) {
    //     //   toast.success(response.data.response);
    //     // } else if (response.data.status && response.data.status===400) {
    //     //   toast.error("Validation error");
    //     // } else if (response.message) {
    //     //   toast.success(response.message);
    //     // } else if (response) {
    //     //   toast.success(response)
    //     // } else {
    //     //   toast.success('Success');
    //     // }
    //   }
    // }

    // setTimeout(() => {
    //   if (endpoint.split("/")[1] === "delete") {
    //     toast.success("Deleted successfully!");
    //   }
    // }, 500);
    return { data: response.data, response: response };
  } catch (error) {
    // return {error: error.response}
    return { error: error };
  }
};
