import React, { useState, useEffect, useRef } from "react"
import { Button, Col, Row, Table } from "react-bootstrap"
import { Modal } from "antd"
import { CheckCircleOutlined } from "@ant-design/icons"
import { useNavigate, useParams } from "react-router-dom"
import Navbar from "../../../Components/Navbar/Navbar"
import {
  dateFormat,
  formatDateSlash,
  formatAmount,
  toastStyle,
  TokenExpiry,
  getType,
} from "../../../Helpers/Utils/Common"
import toast from "react-hot-toast"
import { AttachFile } from "@mui/icons-material"
import {
  changeProjectPiStatus,
  getSingleProjectPI,
} from "../../../Helpers/apiCalls/projectApi"
import { UsePostRequest } from "../../../Helpers/hooks/UsePostApiCall"
import PiPdfContent from "../PiPdfContent"
import jsPDF from "jspdf"
import html2canvas from "html2canvas"
// import { saveAs } from "file-saver";

export default function ReviewOperationalExpense() {
  const { id } = useParams()
  let navigate = useNavigate()
  const [modal, contextModal] = Modal.useModal()
  const pdfRef = useRef()

  const [inactive, setInactive] = useState(true)
  const [reviewSE, setReviewSE] = useState([])
  const [items, setItems] = useState([])
  const [saving, setSaving] = useState(false)

  async function fetchSingleOperationalExpense() {
    const response = await getSingleProjectPI(id)
    if (response.data) {
      var data = response.data.data[0]
      data.invoice_no = data.invoice_no?.map((invoice) => {
        return invoice.invoice_no ? invoice.invoice_no : "N/A"
      })
      data.invoice_id = data.invoice_no?.map((invoice) => {
        return invoice.id ? invoice.id : ""
      })
      var items = data.project_invoice_items.map((item) => {
        var info = item
        info.qty = parseInt(item.qty)
        info.received_qty = item.received_qty ? parseInt(item.received_qty) : 0
        info.remaining_qty = item.remaining_qty
          ? parseInt(item.remaining_qty)
          : 0
        info.current_qty = item.current_qty ? parseInt(item.current_qty) : 0
        info.unit = item.unit
        info.price = `PHP ${formatAmount(item.price)}`
        info.amount = `PHP ${formatAmount(item.total)}`
        info.remarks = item.remarks || "-"
        info.last_received_date = item.last_received_date
          ? formatDateSlash(item.last_received_date)
          : "--/--/---"
        info.last_received_by = item.last_received_by
          ? item.last_received_by
          : "N/A"
        return info
      })
      setReviewSE(data)
      setItems(items)
    } else if (response.error) {
      TokenExpiry(response)
    }
  }

  const confirmStatusChange = (status) => {
    let newStatus = status
    if (status === "pending" && getType() === "admin") {
      newStatus = "finalized"
    } else if (status === "pending" && getType() === "finance_2") {
      newStatus = "approved"
    }

    modal.confirm({
      title: `Are you sure you want to mark this purchase invoice ${status}?`,
      icon: <CheckCircleOutlined />,
      okText: "Proceed",
      cancelText: "Cancel",
      onOk: () => submitApproveSE(newStatus),
    })
  }

  async function handleSendInvoice() {
    if (pdfRef.current) {
      setSaving(true)
      const element = pdfRef.current
      html2canvas(element, { scale: 1.5 }).then(async (canvas) => {
        const imgData = canvas.toDataURL("image/jpeg", 0.7)
        const imgWidth = 210
        const imgHeight = (canvas.height * imgWidth) / canvas.width
        const pdf = new jsPDF("", "mm", "a4")
        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight)
        const pdfBlob = pdf.output("blob")
        const pdfFile = new File([pdfBlob], `Purchase_Invoice_${id}.pdf`, {
          type: "application/pdf",
        })
        // saveAs(pdfFile, `Purchase_Invoice_${id}.pdf`)

        const payload = {
          project_invoice_id: id,
          attachment: pdfFile,
        }
        const formValues = Object.entries(payload).map(([key, value]) => ({
          key,
          value,
        }))
        const response = await UsePostRequest(
          "project_invoices/send_to_client",
          formValues,
          true
        )
        if (response.data) {
          toast.success(response.data.response, { style: toastStyle() })
          setTimeout(() => navigate("/projectpurchaseinvoice"), 500)
        } else {
          setSaving(false)
          toast.error("Error sending invoice", { style: toastStyle() })
        }
      })
    }
  }

  const headersIncompleteOrComplete = [
    "Item",
    "Qty",
    "Unit",
    "Price",
    "Dsc",
    "VAT In",
    "WHT (%)",
    "WHT Amt",
    "Grand Total",
    "Account Type",
    "Remarks",
  ]

  const selectorsIncompleteOrComplete = [
    "name",
    "qty",
    "unit",
    "price",
    "discount",
    "vat_in",
    "wht_percent",
    "wht_amount",
    "grand_total",
    "expense_type_name",
    "ce_no_name",
    "class_name",
    "remarks",
  ]

  const headers = [
    "Item",
    "Qty",
    "Unit",
    "Price",
    "Dsc",
    "VAT In",
    "WHT (%)",
    "WHT Amt",
    "Grand Total",
    "Account Type",
    "PC Number",
    "Class",
    "Remarks",
  ]

  const selectors = [
    "name",
    "qty",
    "unit",
    "price",
    "discount",
    "vat_in",
    "wht_percent",
    "wht_amount",
    "grand_total",
    "expense_type_name",
    "ce_no",
    "class_name",
    "remarks",
  ]

  function renderTable() {
    return (
      <>
        <Table>
          <thead>
            <tr>
              {reviewSE.order_status === "incomplete" ||
              reviewSE.order_status === "complete" ? (
                <>
                  {headersIncompleteOrComplete.map((header) => {
                    return <th>{header}</th>
                  })}
                </>
              ) : (
                <>
                  {headers.map((header) => {
                    return <th>{header}</th>
                  })}
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {items.map((item) => {
              return reviewSE.order_status === "incomplete" ||
                reviewSE.order_status === "complete" ? (
                <tr key={item.id}>
                  {selectorsIncompleteOrComplete.map((selector) => {
                    return <td>{item[selector]}</td>
                  })}
                </tr>
              ) : (
                <tr key={item.id}>
                  {selectors.map((selector) => {
                    return <td>{item[selector]}</td>
                  })}
                </tr>
              )
            })}
          </tbody>
        </Table>
      </>
    )
  }

  async function submitApproveSE(newStatus) {
    const response = await changeProjectPiStatus([id], newStatus)
    var text
    if (newStatus === "pending") {
      text = "Returned to Pending"
    } else {
      text = newStatus
    }

    if (response.data) {
      toast.success("Project Invoice " + text + " Successfully", {
        style: toastStyle(),
      })
      setTimeout(() => navigate("/projectpurchaseinvoice"), 500)
    } else if (response.error) {
      toast.error("Error Changing Status for Project Invoice No. " + id, {
        style: toastStyle(),
      })
    }
  }

  function renderButtons() {
    switch (reviewSE.status) {
      case "pending":
        return (
          <>
            <button
              type="button"
              className="button-secondary me-3"
              onClick={() => navigate("/projectpurchaseinvoice")}
            >
              Close
            </button>
            <button
              type="button"
              className="button-tertiary me-3"
              onClick={() =>
                navigate("/projectpurchaseinvoice/edit/reviewed/" + id)
              }
            >
              Edit
            </button>
            <button
              type="button"
              className="button-primary me-3"
              onClick={() => confirmStatusChange("reviewed")}
              disabled={
                (getType() === "finance_1" &&
                  (!reviewSE.reviewed_by_bu_head ||
                    !reviewSE.reviewed_by_bu_director)) ||
                getType() === "finance_2" ||
                getType() === "requester" ||
                getType() === "accounting_officer" ||
                getType() === "general_manager" ||
                getType() === "business_unit_manager"
              }
            >
              Mark Reviewed
            </button>
          </>
        )
      case "reviewed":
        return (
          <>
            <button
              type="button"
              className="button-secondary me-3"
              onClick={() => navigate("/projectpurchaseinvoice")}
            >
              Close
            </button>
            <button
              type="button"
              className="button-primary me-3"
              onClick={() => confirmStatusChange("approved")}
              disabled={getType() !== "admin" && getType() !== "finance_2"}
            >
              Approve
            </button>
            <button
              type="button"
              className="button-warning me-3"
              onClick={() => confirmStatusChange("disapproved")}
              disabled={getType() !== "admin" && getType() !== "finance_2"}
            >
              Disapprove
            </button>
          </>
        )
      case "approved":
        return (
          <>
            <button
              type="button"
              className="button-secondary me-3"
              onClick={() => navigate("/projectpurchaseinvoice")}
            >
              Close
            </button>
            <button
              type="button"
              className="button-primary me-3"
              onClick={() => confirmStatusChange("finalized")}
              disabled={getType() !== "admin"}
            >
              Mark Finalized
            </button>
            <button
              type="button"
              className="button-primary me-3"
              onClick={() => confirmStatusChange("hold")}
              disabled={getType() !== "admin"}
            >
              Mark As Hold
            </button>
          </>
        )
      case "finalized":
        return (
          <>
            <button
              type="button"
              className="button-secondary me-3"
              onClick={() => navigate("/projectpurchaseinvoice")}
            >
              Close
            </button>
            <button
              type="button"
              className="button-primary me-3"
              // onClick={() => confirmStatusChange("sent")}
              onClick={handleSendInvoice}
              disabled={getType() !== "admin" || saving}
            >
              SEND
            </button>
          </>
        )
      case "hold":
        return (
          <>
            <button
              type="button"
              className="button-secondary me-3"
              onClick={() => navigate("/projectpurchaseinvoice")}
            >
              Close
            </button>
            <button
              type="button"
              className="button-primary me-3"
              onClick={() => confirmStatusChange("finalized")}
              disabled={getType() !== "admin"}
            >
              Mark As Finalized
            </button>
          </>
        )
      default:
        return (
          <>
            <button
              type="button"
              className="button-secondary me-3"
              onClick={() => navigate("/projectpurchaseinvoice")}
            >
              Close
            </button>
          </>
        )
    }
  }

  useEffect(() => {
    fetchSingleOperationalExpense()
  }, [])

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive)
          }}
          active={"PROJECT EXPENSES"}
        />
      </div>
      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        <div className="d-flex justify-content-between">
          <h1 className="page-title mb-4">REVIEW PURCHASE INVOICE</h1>
          <div className="review-po">
            <span className="pe-5">PURCHASE INVOICE NO.</span>
            <span>{id}</span>
          </div>
        </div>

        {/* content */}
        <div className="review-form mb-3">
          <Row className="review-container py-3">
            <Row className="d-flex align-items-md-start">
              <Col sm={3}>
                <span className="review-label">Due Date</span>
                <br />
                <span className="review-data">
                  {dateFormat(reviewSE.due_date)}
                </span>
              </Col>
              <Col sm={3}>
                <span className="review-label">Business Unit</span>
                <br />
                <span className="review-data">{reviewSE.distributor_name}</span>
              </Col>
              <Col sm={3}>
                <span className="review-label">Supplier Name</span>
                <br />
                <span className="review-data">
                  {reviewSE.supplier_trade_name ||
                    reviewSE.vendor_trade_name ||
                    "N/A"}
                </span>
              </Col>
              <Col sm={3}>
                <span className="review-label">Request Date</span>
                <br />
                <span className="review-data">
                  {dateFormat(reviewSE.request_date)}
                </span>
              </Col>
            </Row>
            <Row className="d-flex align-items-start mt-4">
              <Col xs={12} lg={12}>
                <span className="review-label">Remarks</span>
                <br />
                <span className="review-data">{reviewSE.remark}</span>
              </Col>
            </Row>
            <Row className="d-flex align-items-start mt-4">
              <Col xs={12} lg={12}>
                <span className="review-label">File Attachment/s</span>
                <br />
                {reviewSE.project_invoice_attachment?.map((file, index) => (
                  <>
                    <span className="review-data p-2 mt-2" key={index}>
                      <AttachFile />
                      {file.name}
                    </span>
                    <br />
                  </>
                ))}
              </Col>
            </Row>
          </Row>

          <div className="mt-4 d-flex flex-column">
            <span className="review-data mb-2">PURCHASED ITEMS</span>
            <div className="review-purchased-items overflow-scroll">
              {renderTable()}
            </div>
            <Row className="align-right pt-3 me-3">
              <Col xs={2} className="text-end">
                <span className="edit-label color-gray">SUBTOTAL</span>
              </Col>
              <Col xs={1} className="text-end">
                <span className="edit-label vat-total-text align-middle">
                  PHP
                </span>
              </Col>
              <Col xs={1} className="text-end">
                <span className="edit-label align-middle vat-total-text text-end">
                  {formatAmount(reviewSE.subtotal)}
                </span>
              </Col>
            </Row>
            <Row className="align-right pt-1 me-3">
              <Col xs={2} className="text-end">
                <span className="edit-label color-gray">DISCOUNT</span>
              </Col>
              <Col xs={1} className="text-end">
                <span className="edit-label vat-total-text align-middle">
                  PHP
                </span>
              </Col>
              <Col xs={1} className="text-end">
                <span className="edit-label align-middle vat-total-text text-end">
                  {formatAmount(reviewSE.discount)}
                </span>
              </Col>
            </Row>
            <Row className="align-right pt-1 me-3">
              <Col xs={2} className="text-end">
                <span className="edit-label color-gray">WHT %</span>
              </Col>
              <Col xs={1} className="text-end">
                <span className="edit-label vat-total-text align-middle"></span>
              </Col>
              <Col xs={1} className="text-end">
                <span className="edit-label align-middle vat-total-text text-end">
                  {reviewSE.wht_percent ?? 0}
                </span>
              </Col>
            </Row>
            <Row className="align-right pt-1 me-3">
              <Col xs={2} className="text-end">
                <span className="edit-label color-gray">WHT AMOUNT</span>
              </Col>
              <Col xs={1} className="text-end">
                <span className="edit-label vat-total-text align-middle">
                  PHP
                </span>
              </Col>
              <Col xs={1} className="text-end">
                <span className="edit-label align-middle vat-total-text text-end">
                  {formatAmount(reviewSE.wht_amount)}
                </span>
              </Col>
            </Row>
            <Row className="align-right pt-1 me-3">
              <Col xs={2} className="text-end">
                <span className="edit-label color-gray">GRAND TOTAL</span>
              </Col>
              <Col xs={1} className="text-end">
                <span className="edit-label vat-total-text align-middle">
                  PHP
                </span>
              </Col>
              <Col xs={1} className="text-end">
                <span className="edit-label align-middle vat-total-text text-end">
                  {formatAmount(reviewSE.grand_total)}
                </span>
              </Col>
            </Row>
          </div>

          <div className="d-flex justify-content-end pt-5">
            {renderButtons()}
          </div>
        </div>
      </div>

      <div style={{ position: "absolute", left: "-99999px" }}>
        <div ref={pdfRef}>
          <PiPdfContent invoiceDetails={reviewSE} items={items} />
        </div>
      </div>
      {contextModal}
    </div>
  )
}
