import React, { useEffect, useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import Select from "react-select"
import {
  refreshPage,
  selectDropdownStyle,
  toastStyle,
} from "../../Helpers/Utils/Common"
import Table from "../../Components/TableTemplate/DataTable"

// Components
import Navbar from "../../Components/Navbar/Navbar"
import AddModal from "../../Components/Modals/AddModal"
import EditModal from "../../Components/Modals/EditModal"
import ViewModal from "../../Components/Modals/ViewModal"
import DeleteModal from "../../Components/Modals/DeleteModal"
import { getDistributor } from "../../Helpers/apiCalls/DistributorApi"

//API CALL HELPERS
import { UsePostRequest } from "../../Helpers/hooks/UsePostApiCall"
import { UseGetRequest } from "../../Helpers/hooks/GetRequests"
import toast from "react-hot-toast"

const ItemsList = () => {
  const [inactive, setInactive] = useState(true)
  const [showLoader, setShowLoader] = useState(false)
  const [selectedRow, setSelectedRow] = useState({})
  const [showModal, setShowModal] = useState("")
  const [details, setDetails] = useState({
    details: "",
  })
  const [submittableForm, setSubmittableForm] = useState(false)
  const [isClicked, setIsClicked] = useState(false)
  const [businessUnits, setBusinesUnits] = useState([])
  const [tableData, setTableData] = useState([])
  const [itemOptions, setItemOptions] = useState([])

  const [classificationOptions, setClassificationOptions] = useState([
    { label: "Test", value: "test", for: "classification" },
  ])

  function validate(formDetails) {
    const { name, classification_id, beginning_inventory } = formDetails

    const isFormValid =
      name &&
      classification_id &&
      beginning_inventory &&
      beginning_inventory > 0

    setSubmittableForm(isFormValid)
  }

  function validateStockIn(formDetails) {
    const { item_id, quantity } = formDetails

    const isFormValid = item_id && quantity && quantity > 0

    setSubmittableForm(isFormValid)
  }

  function validateStockOut(formDetails) {
    const { item_id, quantity, business_unit_id, requisitioner } = formDetails

    const isFormValid =
      business_unit_id && requisitioner && item_id && quantity && quantity > 0

    setSubmittableForm(isFormValid)
  }

  function search() {
    // Add your search functionality here
  }

  async function handleDelete() {
    setIsClicked(true)
    const response = await UsePostRequest(
      "items/delete",
      {
        item_id: selectedRow.id,
      },
      false
    )

    //display any errors
    if (response.error) {
      toast.error("Something went wrong", {
        style: toastStyle(),
      })
      return
    }

    if (response) {
      toast.success(response?.data?.response, {
        style: toastStyle(),
      })
      setTimeout(() => {
        refreshPage()
      }, 1000)
    } else {
      setIsClicked(false)
      toast.success(response.error.messages.message, {
        style: toastStyle(),
      })
    }
  }

  async function handleCreate() {
    setIsClicked(true)
    const response = await UsePostRequest("items/create", { ...details }, false)

    if (response.error) {
      if (
        response.error.response.data.messages.status?.code &&
        response.error.response.data.messages.status?.code === 1062
      ) {
        setIsClicked(false)
        toast.error(`Item "${details.name}" already exists.`, {
          style: toastStyle(),
        })
      } else {
        toast.error(`Something went wrong.`, {
          style: toastStyle(),
        })
      }
    } else {
      toast.success(response?.data?.response, {
        style: toastStyle(),
      })
      setTimeout(() => {
        refreshPage()
      }, 1000)
    }
  }

  async function handleUpdate() {
    setIsClicked(true)
    const payload = {
      item_id: details.id,
      name: details.name,
      details: details.details,
      beginning_inventory: details.beginning_inventory,
      classification_id: details.classification_id,
    }
    const response = await UsePostRequest(
      "items/update",
      {
        ...payload,
      },
      false
    )

    if (response.error) {
      if (
        response.error.response.data.messages.status?.code &&
        response.error.response.data.messages.status?.code === 1062
      ) {
        setIsClicked(false)
        toast.error(`Classification "${details.name}" already exists.`, {
          style: toastStyle(),
        })
      } else {
        toast.error(`Something went wrong.`, {
          style: toastStyle(),
        })
      }
    } else {
      toast.success(response?.data?.response, {
        style: toastStyle(),
      })
      setTimeout(() => {
        refreshPage()
      }, 1000)
    }
  }

  async function handleCreateStockIn() {
    setIsClicked(true)
    const response = await UsePostRequest(
      "stock_ins/create",
      { ...details },
      false
    )

    if (response.error) {
      toast.error(`Something went wrong.`, {
        style: toastStyle(),
      })
    } else {
      toast.success(response?.data?.response, {
        style: toastStyle(),
      })
      setTimeout(() => {
        refreshPage()
      }, 1000)
    }
    setIsClicked(false)
  }

  async function handleCreateStockOut() {
    setIsClicked(true)
    const response = await UsePostRequest(
      "stock_outs/create",
      { ...details },
      false
    )

    if (response.error) {
      toast.error(`Something went wrong.`, {
        style: toastStyle(),
      })
    } else {
      toast.success(response?.data?.response, {
        style: toastStyle(),
      })
      setTimeout(() => {
        refreshPage()
      }, 1000)
    }
    setIsClicked(false)
  }

  function handleSelectOption(option) {
    if (option === "add") {
      setShowModal("add")
    } else if (option === "stock-in") {
      setShowModal("stock-in")
    } else if (option === "stock-out") {
      setShowModal("stock-out")
    }
  }

  function handleChange(e, fieldType) {
    const { name, value } = e.target || {}
    let updatedDetails = {}

    if (fieldType === "input") {
      updatedDetails = { ...details, [name]: value }
    } else if (fieldType === "dropdown") {
      updatedDetails = { ...details, [e.for]: e.value }
    }

    setDetails(updatedDetails)
  }

  async function fetchBusinessUnits() {
    // setShowLoader(true)
    try {
      const response = await getDistributor({ name: "" })
      if (response.data && Array.isArray(response.data.data)) {
        let data = response.data.data.map((bu) => {
          let info = bu
          info.for = "business_unit_id"
          info.label = bu.name
          info.value = bu.id
          return info
        })
        setBusinesUnits(data)
      } else {
        setBusinesUnits([])
      }
    } catch (error) {
      setBusinesUnits([])
    }
    // setShowLoader(false)
  }

  async function fetchClassifications() {
    const response = await UseGetRequest(
      "classifications/get_all_classification"
    )
    if (response.data) {
      const data = response.data.data.map((item) => {
        return {
          for: "classification_id",
          label: item.name,
          value: item.id,
        }
      })
      setClassificationOptions(data)
    } else {
      setClassificationOptions([])
    }
  }

  async function fetchTableData() {
    setShowLoader(true)
    const response = await UseGetRequest("items/get_all_item")
    if (response.data) {
      const data = response.data.data.map((item) => {
        let info = item
        info.current_inventory =
          item.current_inventory !== "0"
            ? item.current_inventory
            : item.beginning_inventory
        return info
      })

      const items = response.data.data.map((item) => {
        let info = item
        info.for = "item_id"
        info.label = item.name
        info.value = item.id
        return info
      })
      setItemOptions(items)
      setTableData(data)
    } else {
      setTableData([])
    }
    setShowLoader(false)
  }

  useEffect(() => {
    fetchClassifications()
    fetchBusinessUnits()
    fetchTableData()
  }, [])

  useEffect(() => {
    if (showModal === "stock-in") {
      validateStockIn(details)
    } else if (showModal === "stock-out") {
      validateStockOut(details)
    } else {
      validate(details)
    }
  }, [details, showModal])

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => setInactive(inactive)}
          active={"OTHERS"}
        />
      </div>
      <div
        className={`manager-container pt-4 ${inactive ? "inactive" : "active"}`}
      >
        <Row className="mb-1 d-flex flex-row gap-2 gap-lg-0">
          <Col xs={12} lg={7}>
            <h1 className="page-title m-0 w-full">ITEMS LIST</h1>
          </Col>
          <Col xs={12} lg={3} className="pe-4">
            {/* <input
              type="search"
              name="name"
              placeholder="Search item"
              className="search-bar m-0"
              onChange={(e) => {}}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  search(e.target.value)
                }
              }}
            /> */}
          </Col>
          <Col xs={12} lg={2} className="d-flex justify-content-end">
            <Select
              className="dropsearch-filter px-0 py-0 me-2 w-full"
              classNamePrefix="react-select"
              placeholder="Select"
              styles={selectDropdownStyle}
              onChange={(e) => handleSelectOption(e.value)}
              options={[
                { value: "add", label: "Add Item" },
                { value: "stock-in", label: "Stock In" },
                { value: "stock-out", label: "Stock Out" },
              ]}
            />
          </Col>
        </Row>
        <Row className="tab-content pt-3">
          <Col>
            <div className="below">
              <Table
                tableHeaders={[
                  "ITEM NAME",
                  "CLASSIFICATION",
                  // "BEGINNING INVENTORY",
                  "CURRENT INVENTORY",
                  "DETAILS",
                ]}
                headerSelector={[
                  "name",
                  "classification_name",
                  // "beginning_inventory",
                  "current_inventory",
                  "details",
                ]}
                tableData={tableData}
                showLoader={showLoader}
                handleRowClick={(row) => {
                  setSelectedRow(row)
                  setShowModal("view")
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
      <AddModal
        title="ITEM"
        size="xl"
        type="branch"
        show={showModal === "add"}
        onHide={() => setShowModal("")}
        onSave={handleCreate}
        isClicked={isClicked}
        disableSave={!submittableForm}
      >
        <div className="d-flex justify-content-center align-items-center flex-column">
          <Row className="nc-modal-custom-row d-flex flex-column flex-md-row gap-3 gap-md-0">
            <Col>
              NAME<span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="name"
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleChange(e, "input")}
                required
              />
            </Col>
            <Col>
              CLASSIFICATION<span className="required-icon">*</span>
              <Select
                name="classification"
                className="w-full"
                classNamePrefix="react-select"
                placeholder="Select classification"
                onChange={(e) => handleChange(e, "dropdown")}
                options={classificationOptions}
              />
            </Col>
            <Col>
              BEGINNING INVENTORY<span className="required-icon">*</span>
              <Form.Control
                type="number"
                name="beginning_inventory"
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleChange(e, "input")}
                onWheel={(e) => e.target.blur()}
                required
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col>
              ITEM DETAILS
              <Form.Control
                type="text"
                name="details"
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleChange(e, "input")}
              />
            </Col>
          </Row>
        </div>
      </AddModal>
      <EditModal
        title="ITEM"
        size="xl"
        type="branch"
        show={showModal === "edit"}
        onHide={() => setShowModal("")}
        onSave={() => handleUpdate()}
        disableSave={!submittableForm}
        isClicked={isClicked}
      >
        <div className="d-flex justify-content-center align-items-center flex-column">
          <Row className="nc-modal-custom-row d-flex flex-column flex-md-row gap-3 gap-md-0">
            <Col>
              NAME<span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="name"
                value={details.name}
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleChange(e, "input")}
                required
              />
            </Col>
            <Col>
              CLASSIFICATION<span className="required-icon">*</span>
              <Select
                name="classification"
                className="w-full"
                value={classificationOptions.find(
                  (option) => details.classification_id === option.value
                )}
                classNamePrefix="react-select"
                placeholder="Select classification"
                onChange={(e) => handleChange(e, "dropdown")}
                options={classificationOptions}
              />
            </Col>
            <Col>
              CURRENT INVENTORY<span className="required-icon">*</span>
              <Form.Control
                type="number"
                name="beginning_inventory"
                value={details.current_inventory}
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleChange(e, "input")}
                required
                disabled
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col>
              ITEM DETAILS
              <Form.Control
                type="text"
                name="details"
                value={details.details}
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleChange(e, "input")}
              />
            </Col>
          </Row>
        </div>
      </EditModal>
      <ViewModal
        withButtons
        size="lg"
        show={showModal === "view"}
        onHide={() => setShowModal("")}
        onEdit={() => {
          setDetails(selectedRow)
          setShowModal("edit")
        }}
        onDelete={() => setShowModal("delete")}
      >
        <div className="d-flex justify-content-center align-items-center flex-column">
          <Row className="nc-modal-custom-row d-flex flex-column flex-md-row gap-3 gap-md-0">
            <Col>
              NAME<span className="required-icon">*</span>
              <Form.Control
                type="text"
                value={selectedRow.name}
                className="nc-modal-custom-input-edit"
                disabled
              />
            </Col>
            <Col>
              CLASSIFICATION<span className="required-icon">*</span>
              <Form.Control
                type="text"
                value={selectedRow.classification_name}
                className="nc-modal-custom-input-edit"
                disabled
              />
            </Col>
            <Col>
              CURRENT INVENTORY<span className="required-icon">*</span>
              <Form.Control
                type="number"
                value={selectedRow.current_inventory}
                className="nc-modal-custom-input-edit"
                disabled
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col>
              ITEM DETAILS
              <Form.Control
                type="text"
                value={selectedRow.details}
                className="nc-modal-custom-input-edit"
                disabled
              />
            </Col>
          </Row>
        </div>
      </ViewModal>
      <DeleteModal
        text="item"
        show={showModal === "delete"}
        onHide={() => setShowModal("edit")}
        onDelete={handleDelete}
      />
      <AddModal
        title="STOCK IN"
        size="xl"
        type="branch"
        show={showModal === "stock-in"}
        onHide={() => setShowModal("")}
        onSave={handleCreateStockIn}
        isClicked={isClicked}
        disableSave={!submittableForm}
      >
        <div className="d-flex justify-content-center align-items-center flex-column">
          <Row className="nc-modal-custom-row d-flex flex-column flex-md-row gap-3 gap-md-0">
            <Col>
              ITEM <span className="required-icon">*</span>
              <Select
                name="item_id"
                className="w-full"
                classNamePrefix="react-select"
                placeholder="Select item"
                onChange={(e) => handleChange(e, "dropdown")}
                options={itemOptions}
              />
            </Col>
            <Col>
              QUANTITY<span className="required-icon">*</span>
              <Form.Control
                name="quantity"
                type="number"
                onWheel={(e) => e.target.blur()}
                onChange={(e) => handleChange(e, "input")}
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col>
              REMARKS
              <Form.Control
                name="remarks"
                type="text"
                onChange={(e) => handleChange(e, "input")}
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
        </div>
      </AddModal>
      <AddModal
        title="STOCK OUT"
        size="xl"
        type="branch"
        show={showModal === "stock-out"}
        onHide={() => setShowModal("")}
        onSave={handleCreateStockOut}
        isClicked={isClicked}
        disableSave={!submittableForm}
      >
        <div className="d-flex justify-content-center align-items-center flex-column">
          <Row className="nc-modal-custom-row d-flex flex-column flex-md-row gap-3 gap-md-0">
            <Col>
              ITEM <span className="required-icon">*</span>
              <Select
                name="item_id"
                className="w-full"
                classNamePrefix="react-select"
                placeholder="Select item"
                onChange={(e) => handleChange(e, "dropdown")}
                options={itemOptions}
              />
            </Col>
            <Col>
              QUANTITY<span className="required-icon">*</span>
              <Form.Control
                name="quantity"
                type="number"
                onWheel={(e) => e.target.blur()}
                onChange={(e) => handleChange(e, "input")}
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row d-flex flex-column flex-md-row gap-3 gap-md-0">
            <Col>
              BUSINESS UNIT<span className="required-icon">*</span>
              <Select
                name="classification"
                className="w-full"
                classNamePrefix="react-select"
                placeholder="Select classification"
                onChange={(e) => handleChange(e, "dropdown")}
                options={businessUnits}
              />
            </Col>
            <Col>
              REQUISITIONER<span className="required-icon">*</span>
              <Form.Control
                name="requisitioner"
                onChange={(e) => handleChange(e, "input")}
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col>
              REMARKS
              <Form.Control
                name="remarks"
                type="text"
                onChange={(e) => handleChange(e, "input")}
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
        </div>
      </AddModal>
    </div>
  )
}

export default ItemsList
