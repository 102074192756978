import dayjs from "dayjs"
import { getToken, getUser } from "../Utils/Common"
import { getAPICall, postAPICall } from "./axiosMethodCalls"

export const getSingleBillingSI = async (id) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK +
        "billing_sales_invoices/get_billing_sales_invoices",
      {
        requester: getUser(),
        token: getToken(),
        billing_sales_invoice_id: id,
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}

export const getAllBillingSI = async (filters) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK +
        "billing_sales_invoices/get_all_billing_sales_invoice",
      {
        requester: getUser(),
        token: getToken(),
        ...filters,
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}

export const deleteBillingSI = async (id) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "billing_sales_invoices/delete",
      {
        requester: getUser(),
        token: getToken(),
        billing_sales_invoice_id: id,
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}

export const changeBillingSIStatus = async (data) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "billing_sales_invoices/change_status",
      {
        requester: getUser(),
        token: getToken(),
        billing_sales_invoice_id: data.invoice_id,
        new_status: data.new_status,
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}

export const createBillingSalesInvoice = async (data, particulars, files) => {
  let form_data = new FormData()
  form_data.append("requester", getUser())
  form_data.append("token", getToken())
  form_data.append("project_id", data.project_id)
  form_data.append("project_name", data.project_name)
  form_data.append("invoice_no", data.invoice_no)
  form_data.append(
    "invoice_date",
    data.invoice_date ? dayjs(data.invoice_date).format("YYYY-MM-DD") : ""
  )
  form_data.append(
    "due_date",
    data.due_date ? dayjs(data.due_date).format("YYYY-MM-DD") : ""
  )
  form_data.append(
    "project_date",
    data.project_date ? dayjs(data.project_date).format("YYYY-MM-DD") : ""
  )
  form_data.append("address", data.address)
  form_data.append("company", data.company)
  form_data.append("remark", data.remark)
  form_data.append("sub_total", data.subtotal)
  form_data.append("other_fees", data.other_fees)
  form_data.append("delivery_fee", data.delivery_fee)
  form_data.append("grand_total", data.grand_total)
  form_data.append("vat_type", data.vat_type)
  form_data.append("twelve_percent_vat", data.vat_twelve)
  form_data.append("net_vat", data.vat_net)
  form_data.append("wht_percentage", data.wht_percent)
  form_data.append("wht_amount", data.wht_amount)
  form_data.append("discount", data.discount)
  form_data.append("po_no", data.po_no)

  Array.from(files).map((val) => form_data.append("file[]", val))

  particulars.map((item) => {
    form_data.append("descriptions[]", item.description)
    form_data.append("amounts[]", item.amount)
    form_data.append("types[]", item.account_title)
  })

  try {
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "billing_sales_invoices/create",
      form_data
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}

export const updateBillingSalesInvoice = async (
  id,
  data,
  particulars
  // files
) => {
  let form_data = new FormData()
  form_data.append("requester", getUser())
  form_data.append("token", getToken())
  form_data.append("billing_sales_invoice_id", id)
  form_data.append("project_id", data.project_id)
  form_data.append("project_name", data.project_name)
  form_data.append("invoice_no", data.invoice_no)
  form_data.append(
    "invoice_date",
    data.invoice_date ? dayjs(data.invoice_date).format("YYYY-MM-DD") : ""
  )
  form_data.append(
    "due_date",
    data.due_date ? dayjs(data.due_date).format("YYYY-MM-DD") : ""
  )
  form_data.append(
    "project_date",
    data.project_date ? dayjs(data.project_date).format("YYYY-MM-DD") : ""
  )
  form_data.append("address", data.address)
  form_data.append("company", data.company)
  form_data.append("remark", data.remark)
  form_data.append("sub_total", data.subtotal)
  form_data.append("other_fees", data.other_fees)
  form_data.append("delivery_fee", data.delivery_fee)
  form_data.append("grand_total", data.grand_total)
  form_data.append("vat_type", data.vat_type)
  form_data.append("twelve_percent_vat", data.vat_twelve)
  form_data.append("net_vat", data.vat_net)
  form_data.append("wht_percentage", data.wht_percent)
  form_data.append("wht_amount", data.wht_amount)
  form_data.append("discount", data.discount)
  form_data.append("po_no", data.po_no)

  // Array.from(files).map((val) => form_data.append("file[]", val))

  particulars.map((item) => {
    form_data.append("descriptions[]", item.description)
    form_data.append("amounts[]", item.amount)
    form_data.append("types[]", item.account_title)
  })

  try {
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "billing_sales_invoices/update",
      form_data
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}
