import React, { useState } from "react"
import { Col, Form, Row } from "react-bootstrap"

//components
import Table from "../../Components/TableTemplate/DataTable"
import Navbar from "../../Components/Navbar/Navbar"
import DeleteModal from "../../Components/Modals/DeleteModal"
import AddModal from "../../Components/Modals/AddModal"
import EditModal from "../../Components/Modals/EditModal"
import ViewModal from "../../Components/Modals/ViewModal"
import { toastStyle } from "../../Helpers/Utils/Common"
import toast from "react-hot-toast"
import { UseGetRequest } from "../../Helpers/hooks/GetRequests"
import { UsePostRequest } from "../../Helpers/hooks/UsePostApiCall"

export default function CostCenter() {
  const [inactive, setInactive] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [isClicked, setIsClicked] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const handleCloseDeleteModal = () => setShowDeleteModal(false)
  const [showViewModal, setShowViewModal] = useState(false)
  const handleCloseViewExpenseTypeModal = () => setShowViewModal(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const handleShowEditExpenseTypeModal = () => setShowEditModal(true)
  const handleCloseEditExpenseTypeModal = () => setShowEditModal(false)
  const [showAddModal, setShowAddModal] = useState(false)
  const handleShowAddExpenseTypeModal = () => setShowAddModal(true)
  const handleCloseAddExpenseTypeModal = () => {
    setShowAddModal(false)
    setFormValues({})
  }
  const [tempSearchName, setTempSearchTerm] = useState("")

  //API
  const [allData, setAllData] = useState([])
  const [selectedRow, setSelectedRow] = useState({})
  const [formValues, setFormValues] = useState({
    name: "",
    description: "",
  })

  //onEdit
  function handleOnEdit() {
    handleCloseViewExpenseTypeModal()
    handleShowEditExpenseTypeModal()
  }

  //DELETE or REMOVE FORWARDER
  function handleDeleteExpenseType() {
    removeExpenseType(selectedRow.id)
  }

  //API CALL
  async function fetchAllExpenseType() {
    setShowLoader(true)
    const response = await UseGetRequest("cost_centers/search", {
      name: searchText,
    })
    if (response.data) {
      setAllData(response.data.data)
    } else {
      setAllData([])
    }
    setShowLoader(false)
  }

  async function handleSaveExpenseType() {
    // if (formValues.name.trim() !== "") {
    setIsClicked(true)
    const response = await UsePostRequest("cost_centers/create", formValues)

    if (response.data) {
      toast.success(response.data.response, {
        style: toastStyle(),
      })
      handleCloseAddExpenseTypeModal()
      setSearchText("")
    } else {
      let error = response.error.response.data.messages.response
      toast.error(error, { style: toastStyle() })
    }
    setIsClicked(false)
    // } else {
    //   toast.error("Please fill in required fields", { style: toastStyle() })
    //   setIsClicked(false)
    // }
  }

  async function handleEditExpenseType() {
    setIsClicked(true)
    const response = await UsePostRequest("cost_centers/update", {
      ...selectedRow,
      cost_center_id: selectedRow.id,
    })
    if (response.data) {
      toast.success("Successfully updated cost center!", {
        style: toastStyle(),
      })
      handleCloseEditExpenseTypeModal()
      setShowViewModal(false)
      setSearchText("")
    } else {
      toast.error("Error in updating cost center", { style: toastStyle() })
    }
    setIsClicked(false)
  }

  async function removeExpenseType(id) {
    const response = await UsePostRequest("cost_centers/delete", {
      cost_center_id: id,
    })
    if (response.data) {
      toast.success("Class deleted successfully!", {
        style: toastStyle(),
      })
      handleCloseDeleteModal()
      setShowViewModal(false)
      setSearchText("")
    } else {
      toast.success("Error deleting cost center!", {
        style: toastStyle(),
      })
    }
  }

  React.useEffect(() => {
    fetchAllExpenseType()
  }, [searchText])

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive)
          }}
          active={"CLASS"}
        />
      </div>
      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        <Row className="mb-4">
          <Col>
            <h1 className="page-title">CLASS</h1>
          </Col>
          <Col className="d-flex justify-content-end">
            <input
              type="search"
              name="name"
              placeholder="Search class"
              value={searchText}
              onChange={(e) => setTempSearchTerm(e.target.value)}
              className="search-bar"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setSearchText(tempSearchName)
                }
              }}
            />
            <button className="add-btn" onClick={handleShowAddExpenseTypeModal}>
              Add
            </button>
          </Col>
        </Row>
        <div className="tab-content">
          {/* TABLE */}
          <Table
            tableHeaders={["MAIN TYPE"]}
            headerSelector={["name"]}
            tableData={allData}
            // ActionBtn={(row) => ActionBtn(row)}
            showLoader={showLoader}
            handleRowClick={(row) => {
              setFormValues(row)
              setSelectedRow(row)
              setShowViewModal(true)
            }}
            isPointer
          />
        </div>
      </div>

      {/* MODALS */}
      <DeleteModal
        text="class"
        show={showDeleteModal}
        onHide={handleCloseDeleteModal}
        onDelete={handleDeleteExpenseType}
      />

      <AddModal
        title="CLASS"
        size="lg"
        type="expensetype"
        show={showAddModal}
        onHide={handleCloseAddExpenseTypeModal}
        onSave={handleSaveExpenseType}
        isClicked={isClicked}
        data={formValues}
        disableSave={formValues.name === "" || formValues.name?.trim() === ""}
      >
        <div className="mt-3">
          <Row className="nc-modal-custom-row mt-0">
            <Col>
              MAIN TYPE
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="name"
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setFormValues((prevState) => ({
                    ...prevState,
                    name: e.target.value,
                  }))
                }
                required
              />
            </Col>
          </Row>
        </div>
      </AddModal>

      <EditModal
        title="CLASS"
        size="lg"
        show={showEditModal}
        onHide={handleCloseEditExpenseTypeModal}
        onSave={handleEditExpenseType}
        data={formValues}
        disableSave={formValues.name === "" || formValues.name?.trim() === ""}
      >
        <div className="mt-3">
          <Row className="nc-modal-custom-row mt-0">
            <Col>
              MAIN TYPE
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="name"
                value={formValues.name}
                className="nc-modal-custom-input-edit"
                onChange={(e) =>
                  setFormValues((prevState) => ({
                    ...prevState,
                    name: e.target.value,
                  }))
                }
                required
              />
            </Col>
          </Row>
        </div>
      </EditModal>

      <ViewModal
        withButtons
        show={showViewModal}
        onHide={handleCloseViewExpenseTypeModal}
        onEdit={handleOnEdit}
        size="lg"
        onDelete={() => setShowDeleteModal(true)}
      >
        <div className="mt-1">
          <span className="custom-modal-body-title">CLASS DETAILS</span>
          <Row className="nc-modal-custom-row mt-3">
            <Col>
              MAIN TYPE
              <Form.Control
                type="text"
                name="name"
                value={formValues.name}
                className="nc-modal-custom-input-edit"
                disabled
              />
            </Col>
          </Row>
        </div>
      </ViewModal>
    </div>
  )
}
