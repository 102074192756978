import { Modal, Container, Row, Col } from "react-bootstrap"
import React, { useState } from "react"
import ReactLoading from "react-loading"

//css
import "./Modal.css"

function EditModal(props) {
  return (
    <div>
      <Modal show={props.show} onHide={props.onHide} size={props.size} centered>
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="col-sm-12">
            <p className="custom-modal-body-title"> EDIT {props.title} </p>
            <Container fluid className="modal-cont justify-content-center">
              {props.children}
            </Container>

            <Row className="col-sm-12 mt-4 d-flex justify-content-end ms-sm-2 ms-lg-0 px-lg-2">
              <Col xs={12} md={6} lg={2}>
                <button
                  className="button-default w-full"
                  onClick={props.onHide}
                >
                  Cancel
                </button>
              </Col>
              {/* <button className="button-primary" onClick={props.onSave} disabled={props.disableSave}>
                  Save
                </button> */}
              <Col xs={12} md={6} lg={2}>
                {props.isClicked === true ? (
                  <div className="button-primary d-flex justify-content-center w-full">
                    <ReactLoading
                      type="bubbles"
                      color="#FFFFFF"
                      height={30}
                      width={30}
                    />
                  </div>
                ) : (
                  <button
                    className="button-primary w-full"
                    onClick={props.onSave}
                    disabled={props.disableSave || props.isClicked}
                  >
                    Save
                  </button>
                )}
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

EditModal.defaultProps = {
  title: "",
  // type:"",
  size: "xl",
  show: () => {},
  onHide: () => {},
  onSave: () => {},
}

export default EditModal
