import React, { useState, useEffect, Fragment } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { useNavigate, useLocation } from "react-router-dom"
import toast from "react-hot-toast"
import "react-autocomplete-input/dist/bundle.css"
import "react-bootstrap-typeahead/css/Typeahead.css"
import ReactLoading from "react-loading"
import { toastStyle } from "../../Helpers/Utils/Common"
import Navbar from "../../Components/Navbar/Navbar"
import { Upload } from "antd"
import { UsePostRequest } from "../../Helpers/hooks/UsePostApiCall"
import Compressor from "compressorjs"

function DistributorForm({ add, edit }) {
  const navigate = useNavigate()
  const location = useLocation()
  const formPurpose = location.state.formPurpose
  const distributorDetails = location.state.distributorDetails
  const [filePreview, setFilePreview] = useState(null)
  const [inactive, setInactive] = useState(true)
  const [isSubmitClicked, setIsSubmitClicked] = useState(false)
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    contact_no: "",
    contact_person: "",
    file: "",
    address: "",
    tin: "",
  })

  const handleChange = (e) => {
    var newData = { ...formValues }
    newData[e.target.name] = e.target.value
    setFormValues(newData)
  }

  async function handleSubmit() {
    if (isSubmitClicked) {
      return
    }

    // if (validateDistributor(formValues, setIsError)) {
      if (formPurpose === "ADD") {
        if (formValues.file === undefined) {
          toast.error("Profile photo is required")
          return
        }
        setIsSubmitClicked(true)
        const payload = {
          name: formValues.name,
          address: formValues.address,
          contact_no: formValues.contact_no,
          contact_person: formValues.contact_person,
          tin: formValues.tin,
          email: formValues.email,
          file: formValues.file,
        }
        const formDataValues = Object.entries(payload).map(([key, value]) => ({
          key,
          value,
        }))

        const response = await UsePostRequest(
          "distributors/create",
          formDataValues,
          true
        )
        if (response.data) {
          toast.success(response.data.response, { style: toastStyle() })
          navigate("/businessunits")
          // setTimeout(() => navigate("/businessunits"), 1000)
        } else {
          toast.error(response.error.data.messages.response, {
            style: toastStyle(),
          })
        }
      } else {
        setIsSubmitClicked(true)
        const payload = {
          name: formValues.name,
          distributor_id: formValues.id,
          address: formValues.address ? formValues.address : "",
          contact_no: formValues.contact_no ? formValues.contact_no : "",
          contact_person: formValues.contact_person
            ? formValues.contact_person
            : "",
          email: formValues.email,
          tin: formValues.tin,
          file: formValues.file ? formValues.file : "",
        }
        const formDataValues = Object.entries(payload).map(([key, value]) => ({
          key,
          value,
        }))
        const response = await UsePostRequest(
          "distributors/update",
          formDataValues,
          true
        )
        if (response.data) {
          toast.success(response.data.response, { style: toastStyle() })
          navigate("/businessunits")
          // setTimeout(() => navigate("/businessunits"), 1000)
        } else {
          toast.error(response.error.data.messages.response, {
            style: toastStyle(),
          })
        }
      }
    // }
    setIsSubmitClicked(false)
  }

  async function getDistributorDetails() {
    setFormValues(distributorDetails)
    
    if (
      distributorDetails.distributor_attachment &&
      distributorDetails.distributor_attachment.length > 0
    ) {
      const base64Value = distributorDetails.distributor_attachment[0].base_64
      setFilePreview(base64Value) // Set the base64 value to your preview file state
    }

    // const inputArray =
    //   distributorDetails.distributor_clients.length > 0
    //     ? distributorDetails.distributor_clients
    //     : []
    // const mergedMap = new Map()

    // inputArray.forEach((item) => {
    //   const customerID = item.customer_id
    //   const project = {
    //     project_id: item.project_id,
    //     project_date: item.distributor_client_date,
    //   }

    //   if (mergedMap.has(customerID)) {
    //     mergedMap.get(customerID).projects.push(project)
    //   } else {
    //     mergedMap.set(customerID, {
    //       customer_id: customerID,
    //       projects: [project],
    //     })
    //   }
    // })
    // const mergedArray = Array.from(mergedMap.values())
    // setClientDetails(mergedArray)
  }

  // const getBase64 = (file) => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader()
  //     reader.readAsDataURL(file)
  //     reader.onload = () => resolve(reader.result)
  //     reader.onerror = (error) => reject(error)
  //   })
  // }

  const handleUploadChange = async (info) => {
    const file = info.file.originFileObj
    setFilePreview(URL.createObjectURL(file))
    const compressedImage = await new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.6,
        success(result) {
          resolve(result);
        },
        error(err) {
          reject(err);
        },
      });
    });
    setFormValues((prevDetails) => ({
      ...prevDetails,
      file: compressedImage,
    }))
  }

  const props = {
    name: "file",
    headers: {
      authorization: "authorization-text",
    },
    onChange: handleUploadChange,
    showUploadList: false,
    accept: "image/*",
  }

  useEffect(() => {
    if (formPurpose === "EDIT") {
      getDistributorDetails()
    }
  }, [])

  // VALIDATION
  const [submittableForm, setSubmittableForm] = useState(false);
  React.useEffect(() => {
    const requiredFieldKeys = ["name", "email"];
    let isValid = true;
    requiredFieldKeys.forEach((key) => {
      if (
        !formValues[key] ||
        (formValues[key] &&
          typeof formValues[key] === "string" &&
          formValues[key]?.trim() === "")
      ) {
        isValid = false;
      }
    });
    setSubmittableForm(isValid);
  }, [formValues.name, formValues.email]);

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive)
          }}
          active={"MANAGE"}
        />
      </div>

      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        <div className="d-flex justify-content-between align-items-center">
          <h1 className="page-title mb-0">{formPurpose} BUSINESS UNIT</h1>
        </div>
        <div className="edit-form mt-2">
          <Fragment>
            <Row className="mt-2 mb-2 d-flex align-items-start">
              <Col xs={12} lg={9}>
                <Row className="mb-2">
                  <Col>
                    <span htmlFor="customer_id" className="edit-label">
                      Business Unit Name
                      <label className="badge-required">*</label>
                    </span>
                    <Form.Control
                      value={formValues.name}
                      name="name"
                      onChange={handleChange}
                    />
                
                  </Col>

                  <Col>
                    <span htmlFor="customer_id" className="edit-label">
                      Address
                    </span>
                    <Form.Control
                      type="text"
                      name="address"
                      value={formValues.address}
                      onChange={(e) => handleChange(e)}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  {/* <Col>
                    <span htmlFor="customer_id" className="edit-label">
                      CONTACT PERSON
                    </span>
                    <Form.Control
                      type="text"
                      name="contact_person"
                      value={formValues.contact_person}
                      onChange={(e) => handleChange(e)}
                    />
                  </Col> */}
                  <Col>
                    <span htmlFor="customer_id" className="edit-label">
                      TIN
                    </span>
                    <Form.Control
                      type="text"
                      name="tin"
                      value={formValues.tin}
                      onChange={(e) => handleChange(e)}
                    />
                  </Col>
                  <Col>
                    <span htmlFor="customer_id" className="edit-label">
                      CONTACT NUMBER
                    </span>
                    <Form.Control
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      name="contact_no"
                      value={formValues.contact_no}
                      onChange={(e) => handleChange(e)}
                    />
                  </Col>
                  <Col>
                    <span htmlFor="customer_id" className="edit-label">
                      EMAIL <label className="badge-required">*</label>
                    </span>
                    <Form.Control
                      type="text"
                      name="email"
                      value={formValues.email}
                      onChange={(e) => handleChange(e)}
                    />
                  
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col>
                    {filePreview && (
                      <div style={{ marginTop: 16, textAlign: "center" }}>
                        <img
                          src={filePreview}
                          alt="preview"
                          className="image-preview-container"
                        />
                      </div>
                    )}
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col className="d-flex justify-content-center">
                    <Upload {...props}>
                      <button className="button-fourth">
                        Upload Profile Picture
                      </button>
                    </Upload>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Fragment>

          <Row className="pt-5 pb-3">
            <Col xs={6}></Col>
            <Col xs={3}>
              <button
                type="button"
                className="button-secondary w-100"
                onClick={() => navigate(-1)}
              >
                Cancel
              </button>
            </Col>
            <Col xs={3}>
              {isSubmitClicked ? (
                <div className="button-primary d-flex justify-content-center w-100">
                  <ReactLoading
                    type="bubbles"
                    color="#FFFFFF"
                    height={50}
                    width={50}
                  />
                </div>
              ) : (
                <button
                  type="button"
                  className="button-primary w-100"
                  onClick={handleSubmit}
                  disabled={!submittableForm}
                >
                  Submit
                </button>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

DistributorForm.defaultProps = {
  add: false,
  edit: false,
}

export default DistributorForm
