import React, { useEffect, useState } from "react"
import Navbar from "../../Components/Navbar/Navbar"
import { Col, Form, Row, Tab, Tabs } from "react-bootstrap"
import Table from "../../Components/TableTemplate/DataTable"
import AddModal from "../../Components/Modals/AddModal"
import ViewModal from "../../Components/Modals/ViewModal"
import DeleteModal from "../../Components/Modals/DeleteModal"
import Select from "react-select"
import { getDistributor } from "../../Helpers/apiCalls/DistributorApi"

//API CALL HELPERS
import { UsePostRequest } from "../../Helpers/hooks/UsePostApiCall"
import { UseGetRequest } from "../../Helpers/hooks/GetRequests"
import { dateFormat, refreshPage, toastStyle } from "../../Helpers/Utils/Common"
import toast from "react-hot-toast"
import { formatDate } from "date-fns"

const StockOut = () => {
  const [inactive, setInactive] = useState(true)
  const [isClicked, setIsClicked] = useState(false)
  const [showModal, setShowModal] = useState("")
  const [businessUnits, setBusinesUnits] = useState([])
  const [submittableForm, setSubmittableForm] = useState(false)
  const [itemOptions, setItemOptions] = useState([])
  const [filterConfig, setFilterConfig] = useState({
    tab: "processed",
  })
  const [details, setDetails] = useState({
    name: "",
  })

  const [tableData, setTableData] = useState([])
  const [showLoader, setShowLoader] = useState(false)

  function handleChange(e, fieldType) {
    const { name, value } = e.target || {}
    let updatedDetails = {}

    if (fieldType === "input") {
      updatedDetails = { ...details, [name]: value }
    } else if (fieldType === "dropdown") {
      updatedDetails = { ...details, [e.for]: e.value }
    }
    setDetails(updatedDetails)
  }

  function validate(formDetails) {
    const validationRules = {
      add: () => {
        const { item_id, quantity, requisitioner, business_unit_id } =
          formDetails
        return item_id && quantity > 0 && requisitioner && business_unit_id
      },
      return: () => {
        const { return_quantity } = formDetails
        return (
          return_quantity > 0 && return_quantity <= parseFloat(details.quantity)
        )
      },
    }

    const isFormValid = validationRules[showModal]?.() || false
    setSubmittableForm(isFormValid)
  }

  useEffect(() => {
    validate(details)
  }, [details])

  async function handleCreate() {
    setIsClicked(true)
    const response = await UsePostRequest(
      "stock_outs/create",
      { ...details },
      false
    )

    if (response.error) {
      toast.error(`Something went wrong.`, {
        style: toastStyle(),
      })
    } else {
      toast.success(response?.data?.response, {
        style: toastStyle(),
      })
      setTimeout(() => {
        refreshPage()
      }, 1000)
    }
    setIsClicked(false)
  }

  async function handleDelete() {
    setIsClicked(true)
    const response = await UsePostRequest(
      "items/delete",
      {
        item_id: details.id,
      },
      false
    )

    //display any errors
    if (response.error) {
      toast.error("Something went wrong", {
        style: toastStyle(),
      })
      return
    }

    if (response) {
      toast.success(response?.data?.response, {
        style: toastStyle(),
      })
      setTimeout(() => {
        refreshPage()
      }, 1000)
    } else {
      setIsClicked(false)
      toast.success(response.error.messages.message, {
        style: toastStyle(),
      })
    }
  }

  async function handleReturn() {
    setIsClicked(true)
    const response = await UsePostRequest(
      "stock_outs/return",
      { stock_out_id: details.id, return_quantity: details.return_quantity },
      false
    )

    if (response.error) {
      toast.error(`Something went wrong.`, {
        style: toastStyle(),
      })
    } else {
      toast.success(response?.data?.response, {
        style: toastStyle(),
      })
      setTimeout(() => {
        refreshPage()
      }, 1000)
    }
    setIsClicked(false)
  }

  async function fetchTableData() {
    setShowLoader(true)
    const response = await UseGetRequest("stock_outs/get_all_stock_out", {
      status: filterConfig.tab,
    })
    if (response.data) {
      const data = response.data.data.map((stockItem) => {
        const info = stockItem
        info.added_on = dateFormat(stockItem.added_on)
        return info
      })
      setTableData(data)
    } else {
      setTableData([])
    }
    setShowLoader(false)
  }

  async function fetchItems() {
    const response = await UseGetRequest("items/get_all_item")
    if (response.data) {
      const items = response.data.data.map((item) => ({
        ...item,
        for: "item_id",
        label: item.name,
        value: item.id,
      }))

      setItemOptions(items)
    } else {
      setItemOptions([])
    }
  }

  async function fetchBusinessUnits() {
    try {
      const response = await getDistributor({ name: "" })
      if (response.data && Array.isArray(response.data.data)) {
        let data = response.data.data.map((bu) => {
          let info = bu
          info.for = "business_unit_id"
          info.label = bu.name
          info.value = bu.id
          return info
        })
        setBusinesUnits(data)
        return data
      } else {
        setBusinesUnits([])
        return []
      }
    } catch (error) {
      setBusinesUnits([])
      return []
    }
    // setShowLoader(false)
  }

  useEffect(() => {
    fetchItems()
    fetchTableData()
  }, [filterConfig])

  useEffect(() => {
    fetchBusinessUnits()
  }, [])

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => setInactive(inactive)}
          active={"OTHERS"}
        />
      </div>
      <div
        className={`manager-container pt-4 ${inactive ? "inactive" : "active"}`}
      >
        <Row className="mb-1 d-flex flex-row gap-2 gap-lg-0">
          <Col xs={12} lg={7}>
            <h1 className="page-title m-0 w-full">STOCK OUT</h1>
          </Col>
          <Col xs={12} lg={3}>
            {/* <input
              type="search"
              name="name"
              placeholder="Search item name"
              className="search-bar m-0 w-full"
              onChange={(e) => {}}
              onKeyDown={(e) => {
                // if (e.key === "Enter") {
                //   search(e.target.value)
                // }
              }}
            /> */}
          </Col>
          <Col xs={12} lg={2} className="d-flex justify-content-end">
            <button
              className="button-primary"
              onClick={() => {
                setShowModal("add")
              }}
            >
              Add
            </button>
          </Col>
        </Row>
        <Tabs
          activeKey={filterConfig.status}
          defaultActiveKey={filterConfig.status}
          id="SE-tabs"
          onSelect={(e) => {
            setFilterConfig((prev) => ({
              ...prev,
              tab: e,
            }))
          }}
          className="TabStyle4"
        >
          <Tab eventKey="processed" title="PROCESSED">
            <div className="pt-3">
              <Table
                tableHeaders={[
                  "DATE REQUESTED",
                  "ITEM NAME",
                  "QUANTITY",
                  "BUSINESS UNIT",
                  "REQUISITIONER",
                ]}
                headerSelector={[
                  "added_on",
                  "item_name",
                  "quantity",
                  "bu_name",
                  "requisitioner",
                ]}
                tableData={tableData}
                showLoader={showLoader}
                handleRowClick={(row) => {
                  setDetails(row)
                  setShowModal("view")
                }}
              />
            </div>
          </Tab>
          <Tab eventKey="partial_return" title="PARTIAL RETURN">
            <div className="pt-3">
              <Table
                tableHeaders={[
                  "DATE REQUESTED",
                  "ITEM NAME",
                  "QUANTITY",
                  "RETURNED QTY",
                  "RETURNABLE QTY",
                  "BUSINESS UNIT",
                  "REQUISITIONER",
                ]}
                headerSelector={[
                  "added_on",
                  "item_name",
                  "quantity",
                  "return_quantity",
                  "return_balance",
                  "bu_name",
                  "requisitioner",
                ]}
                tableData={tableData}
                showLoader={showLoader}
                handleRowClick={(row) => {
                  setDetails(row)
                  setShowModal("view")
                }}
              />
            </div>
          </Tab>
          <Tab eventKey="returned" title="RETURNED">
            <div className="pt-3">
              <Table
                tableHeaders={[
                  "DATE REQUESTED",
                  "ITEM NAME",
                  "STOCKED OUT QTY",
                  "RETURNED QTY",
                  "BUSINESS UNIT",
                  "REQUISITIONER",
                ]}
                headerSelector={[
                  "added_on",
                  "item_name",
                  "quantity",
                  "return_quantity",
                  "bu_name",
                  "requisitioner",
                ]}
                tableData={tableData}
                showLoader={showLoader}
                handleRowClick={(row) => {
                  setDetails(row)
                  setShowModal("view")
                }}
              />
            </div>
          </Tab>
        </Tabs>
      </div>
      <AddModal
        title="STOCK OUT"
        size="xl"
        type="branch"
        show={showModal === "add"}
        onHide={() => setShowModal("")}
        onSave={handleCreate}
        isClicked={isClicked}
        disableSave={!submittableForm}
      >
        <div className="d-flex justify-content-center align-items-center flex-column">
          <Row className="nc-modal-custom-row d-flex flex-column flex-md-row gap-3 gap-md-0">
            <Col>
              ITEM <span className="required-icon">*</span>
              <Select
                name="item_id"
                className="w-full"
                classNamePrefix="react-select"
                placeholder="Select item"
                onChange={(e) => handleChange(e, "dropdown")}
                options={itemOptions}
              />
            </Col>
            <Col>
              QUANTITY<span className="required-icon">*</span>
              <Form.Control
                name="quantity"
                type="number"
                onWheel={(e) => e.target.blur()}
                onChange={(e) => handleChange(e, "input")}
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row d-flex flex-column flex-md-row gap-3 gap-md-0">
            <Col>
              BUSINESS UNIT<span className="required-icon">*</span>
              <Select
                name="classification"
                className="w-full"
                classNamePrefix="react-select"
                placeholder="Select business unit"
                onChange={(e) => handleChange(e, "dropdown")}
                options={businessUnits}
              />
            </Col>
            <Col>
              REQUISITIONER<span className="required-icon">*</span>
              <Form.Control
                name="requisitioner"
                onChange={(e) => handleChange(e, "input")}
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col>
              REMARKS
              <Form.Control
                name="remarks"
                type="text"
                onChange={(e) => handleChange(e, "input")}
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
        </div>
      </AddModal>
      <ViewModal
        withButtons
        size="lg"
        editable={false}
        can_delete={false}
        can_return={
          filterConfig.tab === "processed" ||
          filterConfig.tab === "partial_return"
        }
        isClicked={isClicked}
        onReturn={() => {
          setShowModal("return")
        }}
        show={showModal === "view"}
        onHide={() => setShowModal("")}
        onEdit={() => {
          setShowModal("edit")
        }}
        onDelete={() => setShowModal("delete")}
      >
        <div className="d-flex justify-content-center align-items-center flex-column">
          <Row className="nc-modal-custom-row d-flex flex-column flex-md-row gap-3 gap-md-0">
            <Col>
              NAME<span className="required-icon">*</span>
              <Form.Control
                name="name"
                type="text"
                disabled
                value={details.item_name}
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col>
              QUANTITY<span className="required-icon">*</span>
              <Form.Control
                name="quantity"
                disabled
                value={
                  filterConfig.tab === "processed"
                    ? details.quantity
                    : details.return_quantity
                }
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col>
              BUSINESS UNIT<span className="required-icon">*</span>
              <Form.Control
                name="quantity"
                disabled
                value={details.bu_name}
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col>
              REMARKS
              <Form.Control
                name="remarks"
                type="text"
                disabled
                value={details.remarks}
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
        </div>
      </ViewModal>
      <AddModal
        title="RETURN"
        size="lg"
        type="branch"
        show={showModal === "return"}
        onHide={() => setShowModal("view")}
        onSave={handleReturn}
        isClicked={isClicked}
        disableSave={!submittableForm}
      >
        <div className="d-flex justify-content-center align-items-center flex-column">
          <Row className="nc-modal-custom-row d-flex flex-column flex-md-row gap-3 gap-md-0">
            <Col>
              BORROWED QUANTITY
              <Form.Control
                type="number"
                disabled
                value={
                  filterConfig.tab === "processed"
                    ? details.quantity
                    : details.return_balance
                }
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col>
              RETURN QUANTITY<span className="required-icon">*</span>
              <Form.Control
                name="return_quantity"
                type="number"
                onWheel={(e) => e.target.blur()}
                onChange={(e) => {
                  handleChange(e, "input")
                }}
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
        </div>
      </AddModal>
      <DeleteModal
        text="item"
        show={showModal === "delete"}
        onHide={() => setShowModal("edit")}
        onDelete={handleDelete}
      />
    </div>
  )
}

export default StockOut
