import React, { useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
//components
import Table from "../../Components/TableTemplate/DataTable"
import Navbar from "../../Components/Navbar/Navbar"
import DeleteModal from "../../Components/Modals/DeleteModal"
import AddModal from "../../Components/Modals/AddModal"
import EditModal from "../../Components/Modals/EditModal"
import ViewModal from "../../Components/Modals/ViewModal"
import {
  createCustomer,
  deleteCustomer,
  searchCustomer,
  updateCustomer,
} from "../../Helpers/apiCalls/CustomerAPI"
import toast from "react-hot-toast"
import { refreshPage, toastStyle } from "../../Helpers/Utils/Common"

export default function Customer() {
  const [inactive, setInactive] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [isClicked, setIsClicked] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [tempSearchText, setTempSearchText] = useState("")
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showViewCustomerModal, setShowViewCustomerModal] = useState(false)
  const [showEditCustomerModal, setShowEditCustomerModal] = useState(false)
  const [showAddCustomerModal, setShowAddCustomerModal] = useState(false)

  //API
  const [customerData, setCustomerData] = useState([])
  const [selectedRow, setSelectedRow] = useState([])
  const [formValues, setFormValues] = useState({
    name: "",
    company: "",
    address: "",
    contact_number: "",
    email: "",
    tin_no: "",
    contact_person: "",
  })

  function resetValues() {
    setFormValues({})
  }

  const handleAddChange = (e) => {
    const { name, value } = e.target
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleEditChange = (e) => {
    const { name, value } = e.target
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  async function handleCreate() {
    // if (validateCustomer(formValues, setIsError)) {
    setIsClicked(true)
    const response = await createCustomer(formValues)
    if (response) {
      if (response?.data?.status === "success") {
        toast.success("Successfully added customer!", {
          style: toastStyle(),
        })
        setShowAddCustomerModal(false)
        resetValues()
        setSearchText("")
        fetchCustomer()
      }
      if (response?.error?.data?.messages?.name) {
        toast.error(response.error.data.messages.name, {
          style: toastStyle(),
        })
      }
      if (response?.error?.data?.messages?.account_name) {
        toast.error(response.error.data.messages.account_name, {
          style: toastStyle(),
        })
      }
    }
    setIsClicked(false)
    // }
  }

  async function fetchCustomer() {
    setCustomerData([])
    setShowLoader(true)
    const response = await searchCustomer(searchText)
    if (response.data) {
      setCustomerData(response.data.data)
    }
    setShowLoader(false)
  }

  async function handleDelete() {
    const response = await deleteCustomer(selectedRow.id)
    if (response) {
      if (response?.data?.status === "success") {
        toast.success(response.data.response, {
          style: toastStyle(),
        })
        setShowDeleteModal(false)
        setShowViewCustomerModal(false)
        setSearchText("")
        refreshPage()
      } else {
        toast.error(response.error.data.messages.error, {
          style: toastStyle(),
        })
      }
    }
  }

  async function editCustomer() {
    // if (validateCustomer(formValues, setIsError)) {
    setIsClicked(true)
    const response = await updateCustomer(formValues)
    if (response) {
      if (response?.data?.status === "success") {
        toast.success(response.data.response, {
          style: toastStyle(),
        })
        setShowEditCustomerModal(false)
        setShowViewCustomerModal(false)
        resetValues()
        setSearchText("")
        refreshPage()
      } else {
        toast.error(response.error.data.messages.error, {
          style: toastStyle(),
        })
      }
    }
    setIsClicked(false)
    // }
  }

  React.useEffect(() => {
    fetchCustomer()
  }, [searchText])

  const [submittableForm, setSubmittableForm] = useState(false)
  React.useEffect(() => {
    const requiredFieldKeys = ["name", "company", "address", "email"]
    let isValid = true
    requiredFieldKeys.forEach((key) => {
      if (
        !formValues[key] ||
        (formValues[key] &&
          typeof formValues[key] === "string" &&
          formValues[key]?.trim() === "")
      ) {
        isValid = false
      }
    })
    setSubmittableForm(isValid)
  }, [
    formValues.name,
    formValues.company,
    formValues.address,
    formValues.email,
  ])

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive)
          }}
          active={"CUSTOMERS"}
        />
      </div>
      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        <Row className="mb-4">
          <Col xs={6}>
            <h1 className="page-title"> CUSTOMER </h1>
          </Col>
          <Col className="d-flex justify-content-end">
            <input
              type="search"
              name="name"
              placeholder="Search name"
              value={searchText}
              onChange={(e) => setTempSearchText(e.target.value)}
              className="search-bar"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setSearchText(tempSearchText)
                }
              }}
            />
            <button
              className="add-btn"
              onClick={() => setShowAddCustomerModal(true)}
            >
              Add
            </button>
          </Col>
        </Row>

        <div className="tab-content">
          <Table
            tableHeaders={[
              "NAME",
              "COMPANY",
              "ADDRESS",
              "PHONE NUMBER",
              // "TIN NUMBER",
              "CONTACT PERSON",
              // "ACTIONS",
            ]}
            headerSelector={[
              "name",
              "company",
              "address",
              "contact_number",
              // "tin_no",
              "contact_person",
              // "action_btn",
            ]}
            tableData={customerData}
            showLoader={showLoader}
            handleRowClick={(row) => {
              setSelectedRow(row)
              setFormValues(row)
              // setformValues(row)
              setShowViewCustomerModal(true)
            }}
            isPointer
            // withActionData={true}
          />
        </div>
      </div>

      {/* MODALS */}
      <DeleteModal
        text="customer"
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onDelete={handleDelete}
      />
      <AddModal
        title="CUSTOMER"
        size="xl"
        type="branch"
        show={showAddCustomerModal}
        onHide={() => setShowAddCustomerModal(false)}
        onSave={handleCreate}
        isClicked={isClicked}
        data={formValues}
        disableSave={!submittableForm}
      >
        <div className="mt-3">
          <Row className="nc-modal-custom-row">
            <Col>
              NAME
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="name"
                value={formValues.name}
                className="nc-modal-custom-input"
                onChange={(e) => handleAddChange(e)}
                required
              />
            </Col>
            <Col>
              COMPANY
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="company"
                value={formValues.company}
                className="nc-modal-custom-input"
                onChange={(e) => handleAddChange(e)}
                required
              />
            </Col>
            <Col>
              BUSINESS STYLE
              <Form.Control
                type="text"
                name="business_style"
                value={formValues.business_style}
                className="nc-modal-custom-input"
                onChange={(e) => handleAddChange(e)}
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col>
              PHONE NUMBER
              <Form.Control
                type="text"
                name="contact_number"
                value={formValues.contact_number}
                className="nc-modal-custom-input"
                onChange={(e) => handleAddChange(e)}
              />
            </Col>
            <Col>
              EMAIL<span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="email"
                value={formValues.email}
                className="nc-modal-custom-input"
                onChange={(e) => handleAddChange(e)}
              />
            </Col>
            <Col>
              TIN NUMBER
              <Form.Control
                type="text"
                name="tin_no"
                value={formValues.tin_no}
                className="nc-modal-custom-input"
                onChange={(e) => handleAddChange(e)}
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col>
              CONTACT PERSON
              <Form.Control
                type="text"
                name="contact_person"
                value={formValues.contact_person}
                className="nc-modal-custom-input"
                onChange={(e) => handleAddChange(e)}
                required
              />
            </Col>
            <Col>
              ADDRESS<span className="required-icon"> *</span>
              <Form.Control
                type="text"
                name="address"
                value={formValues.address}
                className="nc-modal-custom-input"
                onChange={(e) => handleAddChange(e)}
                required
              />
            </Col>
          </Row>
          {/* <Row className="nc-modal-custom-row"></Row> */}
        </div>
      </AddModal>

      <EditModal
        title="CUSTOMER"
        size="xl"
        type="project"
        show={showEditCustomerModal}
        onHide={() => {
          setShowEditCustomerModal(false)
          resetValues()
        }}
        onSave={() => editCustomer()}
        data={formValues}
        disableSave={!submittableForm}
        isClicked={isClicked}
      >
        <div className="mt-3 ">
          <Row className="nc-modal-custom-row">
            <Col>
              NAME
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="name"
                value={formValues.name}
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleEditChange(e)}
                required
              />
            </Col>
            <Col>
              COMPANY
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="company"
                value={formValues.company}
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleEditChange(e)}
                required
              />
            </Col>
            <Col>
              BUSINESS STYLE
              <Form.Control
                type="text"
                name="business_style"
                value={formValues.business_style}
                className="nc-modal-custom-input"
                onChange={(e) => handleAddChange(e)}
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col>
              PHONE NUMBER
              <Form.Control
                type="text"
                name="contact_number"
                value={formValues.contact_number}
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleEditChange(e)}
              />
            </Col>
            <Col>
              EMAIL<span className="required-icon"> *</span>
              <Form.Control
                type="text"
                name="email"
                value={formValues.email}
                className="nc-modal-custom-input"
                onChange={(e) => handleEditChange(e)}
              />
            </Col>
            <Col>
              TIN NUMBER
              <Form.Control
                type="text"
                name="tin_no"
                value={formValues.tin_no}
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleEditChange(e)}
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col>
              CONTACT PERSON
              {/* <span className="required-icon">*</span> */}
              <Form.Control
                type="text"
                name="contact_person"
                value={formValues.contact_person}
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleEditChange(e)}
                required
              />
            </Col>
            <Col>
              ADDRESS
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="address"
                value={formValues.address}
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleEditChange(e)}
                required
              />
            </Col>
          </Row>
        </div>
      </EditModal>

      <ViewModal
        withButtons
        size="xl"
        show={showViewCustomerModal}
        onHide={() => {
          setShowViewCustomerModal(false)
          resetValues()
        }}
        onEdit={() => {
          setShowEditCustomerModal(true)
          setShowViewCustomerModal(false)
        }}
        onDelete={() => setShowDeleteModal(true)}
      >
        <div className="mt-1">
          <span className="custom-modal-body-title">CUSTOMER DETAILS</span>
          <Row className="nc-modal-custom-row mt-3">
            <Col>
              NAME
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="name"
                value={formValues.name}
                className="nc-modal-custom-input-edit"
                disabled
              />
            </Col>
            <Col>
              COMPANY
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="company"
                value={formValues.company}
                className="nc-modal-custom-input-edit"
                disabled
              />
            </Col>
            <Col>
              BUSINESS STYLE
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="business_style"
                value={formValues.business_style}
                className="nc-modal-custom-input-edit"
                disabled
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col>
              PHONE NUMBER
              <Form.Control
                type="text"
                name="contact_number"
                value={formValues.contact_number}
                className="nc-modal-custom-input-edit"
                disabled
              />
            </Col>
            <Col>
              EMAIL
              <Form.Control
                type="text"
                name="email"
                value={formValues.email}
                className="nc-modal-custom-input"
                disabled
              />
            </Col>
            <Col>
              TIN NUMBER
              <Form.Control
                type="text"
                name="tin_no"
                value={formValues.tin_no}
                className="nc-modal-custom-input-edit"
                disabled
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col>
              CONTACT PERSON
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="contact_person"
                value={formValues.contact_person}
                className="nc-modal-custom-input-edit"
                disabled
              />
            </Col>
            <Col>
              ADDRESS
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="address"
                value={formValues.address}
                className="nc-modal-custom-input-edit"
                disabled
              />
            </Col>
          </Row>
        </div>
      </ViewModal>
    </div>
  )
}
