import { Modal, Container } from "react-bootstrap"
import React, { useEffect } from "react"
import ReactLoading from "react-loading"

//css
import "./Modal.css"

function ViewModal(props) {
  // const [viewData, setViewData] = useState({});
  // const handleEditChange = (e) => {
  //   const {name,value} = e.target;
  //   // setEditData((prev)=>{...prev, name: [value]})
  // }
  return (
    <div>
      <Modal show={props.show} onHide={props.onHide} size={props.size} centered>
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="col-sm-12">
            {props.withHeader && (
              <div className="col-sm-12">
                <span className="custom-modal-body-title">
                  VIEW {props.title}
                </span>
              </div>
            )}
            <Container fluid className="modal-cont justify-content-center">
              {props.children}
            </Container>
            {props.withButtons && (
              <div
                className={`col-sm-12 mt-4 d-flex ${
                  props.showViewBtn || props.can_return
                    ? "justify-content-between"
                    : " justify-content-end"
                }`}
              >
                {props.showViewBtn && (
                  <button
                    className="button-primary ms-2"
                    onClick={props.onView}
                  >
                    View
                  </button>
                )}

                {props.can_return && props.can_return !== undefined && (
                  <>
                    {props.isClicked ? (
                      <div className="button-primary d-flex justify-content-center ms-4">
                        <ReactLoading
                          type="bubbles"
                          color="#FFFFFF"
                          height={30}
                          width={30}
                        />
                      </div>
                    ) : (
                      <button
                        className="button-primary ms-4"
                        onClick={props.onReturn}
                      >
                        Return
                      </button>
                    )}
                  </>
                )}
                <div>
                  <button
                    className="button-default me-3"
                    onClick={props.onHide}
                  >
                    Close
                  </button>
                  {(props.can_delete || props.can_delete === undefined) && (
                    <button
                      className="button-warning-fill"
                      onClick={props.onDelete}
                    >
                      Delete
                    </button>
                  )}
                  {(props.editable || props.editable === undefined) && (
                    <button
                      className="button-primary ms-3 me-3"
                      onClick={props.onEdit}
                    >
                      Edit
                    </button>
                  )}
                </div>
              </div>
            )}
            {props.withCloseButtons && (
              <div className="col-sm-12 mt-4 d-flex justify-content-end">
                <button className="button-default mr-3" onClick={props.onHide}>
                  Close
                </button>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

ViewModal.defaultProps = {
  title: "",
  type: "",
  size: "xl",
  withButtons: false,
  withHeader: false,
  show: () => {},
  onHide: () => {},
  onEdit: () => {},
}

export default ViewModal
