import React, { useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { Select as AntSelect, Select } from "antd"
//components
import Table from "../../Components/TableTemplate/DataTable"
import Navbar from "../../Components/Navbar/Navbar"
import DeleteModal from "../../Components/Modals/DeleteModal"
import AddModal from "../../Components/Modals/AddModal"
import EditModal from "../../Components/Modals/EditModal"
import ViewModal from "../../Components/Modals/ViewModal"
import toast from "react-hot-toast"
import { showWarningToast, toastStyle } from "../../Helpers/Utils/Common"
import { UseGetRequest } from "../../Helpers/hooks/GetRequests"
import { UsePostRequest } from "../../Helpers/hooks/UsePostApiCall"
import { deleteUser } from "../../Helpers/apiCalls/usersApi"
import { getDistributor } from "../../Helpers/apiCalls/DistributorApi"

export default function Users() {
  const [inactive, setInactive] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [isClicked, setIsClicked] = useState(false)
  const [updatePass, setUpdatePass] = useState(false)
  const [businessUnits, setBusinessUnits] = useState(false)

  // DELETE
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const handleCloseDeleteModal = () => setShowDeleteModal(false)

  // VIEW
  const [showViewCustomerModal, setShowViewCustomerModal] = useState(false)

  // EDIT
  const [showEditCustomerModal, setShowEditCustomerModal] = useState(false)
  const handleCloseEditProjectModal = () => {
    setShowEditCustomerModal(false)
    resetValues()
  }

  const roleOptions = [
    {
      label: "Admin",
      value: "admin",
    },
    {
      label: "Business Unit Head",
      value: "business_unit_head",
    },
    {
      label: "Business Unit Manager",
      value: "business_unit_manager",
    },
    {
      label: "Business Unit Director",
      value: "business_unit_director",
    },
    {
      label: "Finance 1",
      value: "finance_1",
    },
    {
      label: "Finance 2",
      value: "finance_2",
    },
    {
      label: "Requester",
      value: "requester",
    },
    {
      label: "Finance Officer",
      value: "finance_officer",
    },
    {
      label: "General Manager",
      value: "general_manager",
    },
    {
      label: "Accounting Officer",
      value: "accounting_officer",
    },
  ]

  const [showAddCustomerModal, setShowAddCustomerModal] = useState(false)

  //API
  const [tableData, setTableData] = useState([])
  const [selectedRow, setSelectedRow] = useState([])
  const [formValues, setFormValues] = useState({
    first_name: "",
    last_name: "",
    middle_name: "",
    username: "",
    type: "",
    password: "",
    confirm_password: "",
  })

  const [requiredFieldKeys, setRequiredFieldKeys] = useState([
    "first_name",
    "last_name",
    "username",
    "type",
    "password",
    "confirm_password",
  ])

  function resetValues() {
    setFormValues({
      first_name: "",
      last_name: "",
      middle_name: "",
      username: "",
      type: "",
      password: "",
      confirm_password: "",
    })
  }

  const handleAddChange = (e) => {
    const { name, value } = e.target
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleEditChange = (e) => {
    const { name, value } = e.target
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  async function create() {
    if (formValues.password !== formValues.confirm_password) {
      toast.error("Password and confirm password should match")
      return
    }
    setIsClicked(true)

    const payload = {
      ...formValues,
      business_unit_ids: formValues.business_unit_ids || [],
    }

    const response = await UsePostRequest("users/create", payload)
    if (response) {
      if (response?.data?.status === "success") {
        toast.success("Successfully added customer!", {
          style: toastStyle(),
        })
        setShowAddCustomerModal(false)
        resetValues()
        fetchCustomer()
      }
      if (response?.error?.data?.messages?.name) {
        toast.error(response.error.data.messages.name, {
          style: toastStyle(),
        })
      }
      if (response?.data?.message) {
        showWarningToast(response?.data?.message)
      }
      if (response?.error?.data?.messages?.account_name) {
        toast.error(response.error.data.messages.account_name, {
          style: toastStyle(),
        })
      }
    }
    setIsClicked(false)
  }

  async function fetchCustomer() {
    setTableData([])
    setShowLoader(true)
    const response = await UseGetRequest("users/get_all_user")
    if (response.data) {
      const formattedData = response.data.data.map((user) => ({
        ...user,
        user: `${user.first_name} ${user.last_name}`,
        type_value:
          user.type === "business_unit_head"
            ? "Business Unit Head"
            : user.type === "business_unit_director"
            ? "Business Unit Director"
            : user.type === "finance_1"
            ? "Finance 1"
            : user.type === "admin"
            ? "Admin"
            : user.type === "finance_2"
            ? "Finance 2"
            : user.type === "requester"
            ? "Requester"
            : "",
      }))
      setTableData(formattedData)
    }
    setShowLoader(false)
  }

  async function fetchBusinessUnits() {
    try {
      const response = await getDistributor({ name: "" })
      if (response.data && Array.isArray(response.data.data)) {
        let data = response.data.data.map((bu) => {
          return {
            label: bu.name,
            value: bu.id,
          }
        })
        setBusinessUnits(data)
      } else {
        setBusinessUnits([])
      }
    } catch (error) {
      console.error("Failed to fetch business units:", error)
      setBusinessUnits([])
    }
  }

  async function searchUser(text) {
    setTableData([])
    setShowLoader(true)
    const response = await UseGetRequest("users/search", { name: text })
    if (response.data) {
      const formattedData = response.data.data.map((user) => ({
        ...user,
        user: `${user.first_name} ${user.last_name}`,
        type_value:
          user.type === "business_unit_head"
            ? "Business Unit Head"
            : user.type === "business_unit_director"
            ? "Business Unit Director"
            : user.type === "finance_1"
            ? "Finance 1"
            : user.type === "admin"
            ? "Admin"
            : user.type === "finance_2"
            ? "Finance 2"
            : user.type === "requester"
            ? "Requester"
            : "",
      }))
      setTableData(formattedData)
    }
    setShowLoader(false)
  }

  async function del() {
    const response = await deleteUser(selectedRow.id)
    if (response) {
      toast.success("User deleted successfully", {
        style: toastStyle(),
      })
      handleCloseDeleteModal()
      setShowViewCustomerModal(false)
      fetchCustomer()
    } else {
      toast.error(response.error?.data.messages.error, {
        style: toastStyle(),
      })
    }
  }

  async function editCustomer() {
    if (updatePass && formValues.password !== formValues.confirm_password) {
      toast.error("Password and confirm password should match")
      return
    }
    setIsClicked(true)
    const payload = {
      user_id: formValues.id,
      first_name: formValues.first_name,
      last_name: formValues.last_name,
      type: formValues.type,
      middle_name: formValues.middle_name,
      username: formValues.username,
      password: formValues.password,
      business_unit_ids: formValues.business_unit_ids,
    }
    const response = await UsePostRequest("users/update", payload)
    if (response) {
      if (response) {
        toast.success("User details updates successfully", {
          style: toastStyle(),
        })
        setShowEditCustomerModal(false)
        setShowViewCustomerModal(false)
        resetValues()
        fetchCustomer()
      } else {
        toast.error(response.error.data.messages.error, {
          style: toastStyle(),
        })
      }
    }
    setIsClicked(false)
  }

  React.useEffect(() => {
    fetchCustomer()
    fetchBusinessUnits()
  }, [])

  const [submittableForm, setSubmittableForm] = useState(false)
  React.useEffect(() => {
    let isValid = true
    requiredFieldKeys.forEach((key) => {
      if (
        !formValues[key] ||
        (formValues[key] &&
          typeof formValues[key] === "string" &&
          formValues[key]?.trim() === "")
      ) {
        isValid = false
      }
    })
    setSubmittableForm(isValid)
  }, [
    formValues.first_name,
    formValues.last_name,
    formValues.username,
    formValues.type,
    formValues.password,
    formValues.confirm_password,
  ])
  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive)
          }}
          active={"USERS"}
        />
      </div>
      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        <Row className="mb-4">
          <Col xs={6}>
            <h1 className="page-title"> USERS </h1>
          </Col>
          <Col className="d-flex justify-content-end">
            <input
              type="search"
              name="name"
              placeholder="Search name"
              className="search-bar"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  searchUser(e.target.value)
                }
              }}
            />
            <button
              className="add-btn"
              onClick={() => setShowAddCustomerModal(true)}
            >
              Add
            </button>
          </Col>
        </Row>

        <div className="tab-content">
          <Table
            tableHeaders={[
              "NAME",
              "USERNAME",
              // "EMAIL",
              "ROLE",
              // "ACTIONS",
            ]}
            headerSelector={["user", "username", "type_value"]}
            tableData={tableData}
            showLoader={showLoader}
            handleRowClick={(row) => {
              const editcustomer = {
                ...row,
                business_unit_ids: row.business_units?.map((item) => {
                  return {
                    label: item.name,
                    value: item.business_unit_id,
                  }
                }),
                password: "",
              }
              setSelectedRow(row)
              setFormValues(editcustomer)
              setRequiredFieldKeys([
                "first_name",
                "last_name",
                "username",
                "type",
              ])
              setShowViewCustomerModal(true)
            }}
            isPointer
          />
        </div>
      </div>

      {/* MODALS */}
      <DeleteModal
        text="user"
        show={showDeleteModal}
        onHide={handleCloseDeleteModal}
        onDelete={del}
      />
      <AddModal
        title="USER"
        size="xl"
        type="branch"
        show={showAddCustomerModal}
        onHide={() => {
          setShowAddCustomerModal(false)
          resetValues()
        }}
        onSave={() => create()}
        isClicked={isClicked}
        data={formValues}
        disableSave={!submittableForm}
      >
        <div className="mt-3">
          <Row className="nc-modal-custom-row">
            <Row>
              <Col>
                FIRST NAME
                <span className="required-icon">*</span>
                <Form.Control
                  type="text"
                  name="first_name"
                  value={formValues.first_name}
                  className="nc-modal-custom-input"
                  onChange={(e) => handleAddChange(e)}
                  required
                />
              </Col>
              <Col>
                LAST NAME
                <span className="required-icon">*</span>
                <Form.Control
                  type="text"
                  name="last_name"
                  value={formValues.last_name}
                  className="nc-modal-custom-input"
                  onChange={(e) => handleAddChange(e)}
                  required
                />
              </Col>
              <Col>
                MIDDLE NAME
                <Form.Control
                  type="text"
                  name="middle_name"
                  value={formValues.middle_name}
                  className="nc-modal-custom-input"
                  onChange={(e) => handleAddChange(e)}
                  required
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                USER NAME
                <span className="required-icon">*</span>
                <Form.Control
                  type="text"
                  name="username"
                  value={formValues.username}
                  className="nc-modal-custom-input"
                  onChange={(e) => handleAddChange(e)}
                  required
                />
              </Col>
              <Col>
                USER ROLE
                <span className="required-icon">*</span>
                <Col className="mb-2 mb-lg-0">
                  <AntSelect
                    className="react-select-container w-100"
                    classNamePrefix="react-select"
                    placeholder="Select Role..."
                    value={formValues.type}
                    onChange={(e) => {
                      setFormValues((prev) => ({ ...prev, type: e }))
                    }}
                    options={roleOptions}
                    dropdownStyle={{ zIndex: 10000 }}
                  />
                </Col>
              </Col>
            </Row>
            {formValues.type !== "admin" && (
              <Row className="mt-3">
                <Col>
                  BUSINESS UNITS
                  <span className="required-icon">*</span>
                  <Col className="mb-2 mb-lg-0">
                    <Select
                      showSearch
                      mode="multiple"
                      className="react-select-container w-100"
                      classNamePrefix="react-select"
                      placeholder="Select business unit(s)"
                      onChange={(e) => {
                        setFormValues((prev) => ({
                          ...prev,
                          business_unit_ids: e,
                        }))
                      }}
                      options={businessUnits}
                      dropdownStyle={{ zIndex: 10000 }}
                      filterOption={(input, option) =>
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    />
                  </Col>
                </Col>
              </Row>
            )}

            <Row className="mt-3">
              <Col>
                PASSWORD
                <span className="required-icon">*</span>
                <Form.Control
                  type="password"
                  name="password"
                  value={formValues.password}
                  className="nc-modal-custom-input"
                  onChange={(e) => handleAddChange(e)}
                  required
                />
              </Col>
              <Col>
                CONFIRM PASSWORD
                <span className="required-icon">*</span>
                <Form.Control
                  type="password"
                  name="confirm_password"
                  value={formValues.confirm_password}
                  className="nc-modal-custom-input"
                  onChange={(e) => handleAddChange(e)}
                  required
                />
              </Col>
            </Row>
          </Row>
        </div>
      </AddModal>

      <EditModal
        title="USER"
        size="xl"
        type="project"
        show={showEditCustomerModal}
        onHide={handleCloseEditProjectModal}
        onSave={() => editCustomer()}
        data={formValues}
        isClicked={isClicked}
        disableSave={!submittableForm}
      >
        <div className="mt-3">
          <Row className="nc-modal-custom-row">
            <Row>
              <Col>
                FIRST NAME
                <span className="required-icon">*</span>
                <Form.Control
                  type="text"
                  name="first_name"
                  value={formValues.first_name}
                  className="nc-modal-custom-input"
                  onChange={(e) => handleEditChange(e)}
                  required
                />
              </Col>
              <Col>
                LAST NAME
                <span className="required-icon">*</span>
                <Form.Control
                  type="text"
                  name="last_name"
                  value={formValues.last_name}
                  className="nc-modal-custom-input"
                  onChange={(e) => handleEditChange(e)}
                  required
                />
              </Col>
              <Col>
                MIDDLE NAME
                <Form.Control
                  type="text"
                  name="middle_name"
                  value={formValues.middle_name}
                  className="nc-modal-custom-input"
                  onChange={(e) => handleEditChange(e)}
                  required
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                USER NAME
                <span className="required-icon">*</span>
                <Form.Control
                  type="text"
                  name="username"
                  value={formValues.username}
                  className="nc-modal-custom-input"
                  onChange={(e) => handleEditChange(e)}
                  required
                />
              </Col>
              <Col>
                USER ROLE
                <span className="required-icon">*</span>
                <Col className="mb-2 mb-lg-0">
                  <AntSelect
                    className="react-select-container w-100"
                    classNamePrefix="react-select"
                    placeholder="Select Role..."
                    value={formValues.type}
                    onChange={(e) => {
                      setFormValues((prev) => ({ ...prev, type: e }))
                    }}
                    options={roleOptions}
                    dropdownStyle={{ zIndex: 10000 }}
                  />
                </Col>
              </Col>
            </Row>
            {formValues.type !== "admin" && (
              <Row className="mt-3">
                <Col>
                  BUSINESS UNITS
                  <span className="required-icon">*</span>
                  <Col className="mb-2 mb-lg-0">
                    <Select
                      showSearch
                      mode="multiple"
                      className="react-select-container w-100"
                      classNamePrefix="react-select"
                      placeholder="Select business unit(s)"
                      value={formValues.business_unit_ids}
                      onChange={(e) => {
                        setFormValues((prev) => ({
                          ...prev,
                          business_unit_ids: e,
                        }))
                      }}
                      options={businessUnits}
                      dropdownStyle={{ zIndex: 10000 }}
                      filterOption={(input, option) =>
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    />
                  </Col>
                </Col>
              </Row>
            )}

            <Row className="mt-3">
              <Col lg={3}>
                <button
                  className="button-fourth"
                  onClick={() => setUpdatePass(true)}
                >
                  Reset Password
                </button>
              </Col>
            </Row>
            {updatePass && (
              <Row className="mt-3">
                <Col>
                  PASSWORD
                  <span className="required-icon">*</span>
                  <Form.Control
                    type="password"
                    name="password"
                    value={formValues.password}
                    className="nc-modal-custom-input"
                    onChange={(e) => handleEditChange(e)}
                    required
                  />
                </Col>
                <Col>
                  CONFIRM PASSWORD
                  <span className="required-icon">*</span>
                  <Form.Control
                    type="password"
                    name="confirm_password"
                    value={formValues.confirm_password}
                    className="nc-modal-custom-input"
                    onChange={(e) => handleEditChange(e)}
                    required
                  />
                </Col>
              </Row>
            )}
          </Row>
        </div>
      </EditModal>

      <ViewModal
        withButtons
        size="xl"
        show={showViewCustomerModal}
        onHide={() => {
          resetValues()
          setShowViewCustomerModal(false)
        }}
        onEdit={() => {
          setShowEditCustomerModal(true)
          setShowViewCustomerModal(false)
        }}
        onDelete={() => setShowDeleteModal(true)}
      >
        <div className="mt-1">
          <span className="custom-modal-body-title">USER DETAILS</span>
          <Row className="nc-modal-custom-row">
            <Row>
              <Col>
                FIRST NAME
                <Form.Control
                  type="text"
                  name="name"
                  value={formValues.first_name}
                  className="nc-modal-custom-input"
                  disabled
                />
              </Col>
              <Col>
                LAST NAME
                <Form.Control
                  type="text"
                  name="account_no"
                  value={formValues.last_name}
                  className="nc-modal-custom-input"
                  disabled
                />
              </Col>
              <Col>
                MIDDLE NAME
                <Form.Control
                  type="text"
                  name="account_no"
                  value={formValues.middle_name}
                  className="nc-modal-custom-input"
                  disabled
                />
              </Col>
            </Row>
            {formValues.type !== "admin" && (
              <Row className="mt-3">
                <Col>
                  BUSINESS UNITS
                  <span className="required-icon">*</span>
                  {formValues.business_units?.map((item, index) => (
                    <Form.Control
                      key={index}
                      type="text"
                      name="account_no"
                      defaultValue={item.name}
                      className="nc-modal-custom-input mb-2"
                      disabled
                    />
                  ))}
                </Col>
              </Row>
            )}

            <Row className="mt-3">
              <Col>
                USER NAME
                <span className="required-icon">*</span>
                <Form.Control
                  type="text"
                  name="account_no"
                  value={formValues.username}
                  className="nc-modal-custom-input"
                  disabled
                />
              </Col>
              <Col>
                USER ROLE
                <Form.Control
                  type="text"
                  name="name"
                  value={formValues.type_value}
                  className="nc-modal-custom-input"
                  disabled
                />
              </Col>
            </Row>
          </Row>
        </div>
      </ViewModal>
    </div>
  )
}
