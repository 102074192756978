import React, { useContext, useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import WarehouseLogo from "../../Assets/Images/_warehouse.png"
import { BarLoader } from "react-spinners"
import { useNavigate } from "react-router-dom"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { UseGetRequest } from "../../Helpers/hooks/GetRequests"
import { Context } from "../../Helpers/Context/Context"
// import { UseGetRequest } from "../../Helpers/hooks/GetRequests"

export default function BuPerWarehouse() {
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const [businessUnits, setBusinessUnits] = useState([])
  const { warehouseId } = useContext(Context)

  async function fetchWarehouses() {
    setBusinessUnits([])
    setLoading(true)
    const response = await UseGetRequest("distributors/get_all_distributor", {
      warehouse_id: warehouseId,
    })
    if (response.data && Array.isArray(response.data.data)) {
      let data = response.data.data.map((bu) => {
        let info = bu
        info.logo = bu.distributor_attachment[0].base_64
        return info
      })
      setBusinessUnits(data)
    } else {
      setBusinessUnits([])
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchWarehouses()
    setTimeout(() => {
      setLoading(false)
    }, 3000)
  }, [])

  return (
    <div className="landing-page-container">
      <div className="fixed-top landing-page-header">
        <Row className="d-flex flex-row justify-content-center align-items-center">
          <Col
            className="d-flex flex-row text-white justify-content-start align-items-center pe-4 font-primary cursor gap-2 me-5 px-5 pt-3"
            onClick={() => navigate(`/warehouse-list`)}
          >
            <ArrowBackIcon />
            <p className="font-primary m-0 text-white">BACK</p>
          </Col>
          <Col className="w-full d-flex landing-page-logo ms-2 px-5">
            {/* <img src={Logo} alt="" /> */}
          </Col>
        </Row>
      </div>
      <div className="bu-units-container">
        {loading ? (
          <Row className="d-flex flex-row justify-content-center align-items-center w-full mt-5 pt-5">
            <BarLoader color="white" />
          </Row>
        ) : (
          <Row className="d-flex flex-wrap px-5 pt-2 card-row overflow-scroll">
            {Array.isArray(businessUnits) &&
              businessUnits.length !== 0 &&
              businessUnits.map((warehouse) => (
                <Col
                  key={warehouse.id}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  className="p-3 position-relative"
                  onClick={() => navigate(`/items-list`)}
                >
                  <div className="card-container d-flex flex-column justify-content-center align-items-center">
                    <img src={warehouse.logo} alt="" className="m-3" />
                    <h6 className="m-0 px-3 pb-2 text-center font-primary">
                      {warehouse.name}
                    </h6>
                  </div>
                </Col>
              ))}
          </Row>
        )}
      </div>
    </div>
  )
}
