import React, { useState } from "react";
import { UseGetRequest } from "../../Helpers/hooks/GetRequests";
import { Checkbox, Row, Col } from "antd";
import { formatAmount } from "../../Helpers/Utils/Common";
import { getSingleBillingSI } from "../../Helpers/apiCalls/BillingApi";
import moment from "moment";

export default function PdfContent(props) {
  const { billingId, setReadyToSend } = props;
  const [billingDetails, setBillingDetails] = useState([]);
  const [items, setItems] = useState([]);
  const [businessInfo, setBusinessInfo] = useState({});

  async function fetchBusinessInfo(projectId) {
    // setReadyToSend(false)
    const response = await UseGetRequest("distributors/get_all_distributor", {
      project_id: projectId,
    });
    if (response.data) {
      const result = response.data.data[0];
      setBusinessInfo(result);
      if (
        businessInfo?.distributor_attachment &&
        businessInfo?.distributor_attachment[0]?.base_64
      ) {
   
      } else {
        setReadyToSend(true);
      }
    }
  }

  async function fetchProjectInvoice() {
    setBillingDetails({});
    setItems([]);
    setReadyToSend(false);

    const response = await getSingleBillingSI(billingId);

    if (response.data) {
      var tempData = response.data.data[0];
      fetchBusinessInfo(tempData.project_id);
      const tempTwelve = parseFloat(tempData.sub_total) * 0.12;
      const tempGross =
        parseFloat(tempData.sub_total ?? 0) -
        parseFloat(tempData.discount ?? 0);
      const tempVatIn = tempGross + tempTwelve;
      const tempWhtAmount =
        parseFloat(tempGross) * (parseFloat(tempData.wht_percent ?? 0) / 100);
      const tempNet = tempVatIn - tempTwelve;
      const tempAmountDue = tempNet - tempWhtAmount;

      tempData.vat_in = tempVatIn;
      tempData.amount_due = tempAmountDue;
      tempData.grand_total =
        tempData.vat_type === "vat_in"
          ? tempAmountDue + parseFloat(tempData.wht_percent ?? 0)
          : tempGross;
      setBillingDetails(tempData);
      setItems(tempData.billing_sales_invoice_items);
    }
  }

  React.useEffect(() => {
    fetchProjectInvoice();
  }, []);

  return (
    <div className="print-pdf-container p-5" id="printablediv">
      <div className="">
        <Row>
          {/* <Col
            xs={3} sm={3}
            className=""
          >
            {
                (businessInfo?.distributor_attachment && businessInfo?.distributor_attachment[0]?.base_64) ? (
                    <div className="">
                      <img
                        src={
                            businessInfo?.distributor_attachment && businessInfo?.distributor_attachment[0]?.base_64
                        }
                        alt=""
                        className="w-100"
                        onLoad={()=>setReadyToSend(true)}
                        />
                    </div>
                ) : (
                    <div></div>
                )
            }
          </Col> */}
          <Col xs={24}>
            <Row className="d-flex justify-content-center">
              <h4 className="print-pdf-header">{businessInfo.name}</h4>
            </Row>
            <Row className="d-flex justify-content-center text-center print-text">
              {businessInfo.address}
            </Row>
            <Row className="d-flex justify-content-center print-text">
              VAT Reg. TIN: {businessInfo.tin}
            </Row>
          </Col>
        </Row>
        <Row>
        <Col
            xs={3} sm={3}
            className=""
          >
            {
                (businessInfo?.distributor_attachment && businessInfo?.distributor_attachment[0]?.base_64) ? (
                    <div className="">
                      <img
                        src={
                            businessInfo?.distributor_attachment && businessInfo?.distributor_attachment[0]?.base_64
                        }
                        alt=""
                        className="w-100"
                        onLoad={()=>setReadyToSend(true)}
                        />
                    </div>
                ) : (
                    <div></div>
                )
            }
          </Col>
        </Row>
        <Row className="">
          <h5 className="print-pdf-header">SERVICE INVOICE</h5>
        </Row>
        <Row className="print-text">
          <Checkbox className="print-text">CASH</Checkbox>
          <Checkbox className="print-text ms-2">CHARGE</Checkbox>
        </Row>
        <Row className="mt-2 align-items-center">
          <Col xs={18} className="print-text">
            Billed to:{" "}
            <span className="ms-2">{billingDetails.project_name}</span>
          </Col>
          <Col xs={6} className="print-text">
            Date:{" "}
            <span className="ms-2">
              {moment(billingDetails.invoice_date).format("MMMM DD, YYYY")}
            </span>
          </Col>
        </Row>
        <Row className="mt-2 align-items-center">
          <Col xs={18} className="print-text">
            Address: <span className="ms-2">{billingDetails.address}</span>
          </Col>
          <Col xs={6} className="print-text">
            Invoice No.:{" "}
            <span className="ms-2">{billingDetails.invoice_no}</span>
          </Col>
        </Row>
        <Row className="mt-2 align-items-center print-text">
          <Col xs={18} className="print-text">
            TIN:
          </Col>
          <Col xs={6} className="print-text">
            Bus. Style:
          </Col>
        </Row>
        <Row className="mt-4">
          <div className="app-container w-100">
            <table>
              <thead>
                <tr>
                  <th
                    className="custom-td custom-th text-center print-text"
                    style={{ fontWeight: "bold", width: "10%" }}
                  >
                    Quantity
                  </th>
                  <th
                    className="custom-td custom-th text-center print-text"
                    style={{ fontWeight: "bold", width: "55%" }}
                  >
                    Description
                  </th>
                  <th
                    className="custom-td custom-th text-end print-text"
                    style={{ fontWeight: "bold", width: "15%" }}
                  >
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {items.map((item) => (
                  <tr key={item.id}>
                    <td className="custom-td"></td>
                    <td className="custom-td text-center">
                      <span className="print-text">{item.description}</span>
                    </td>
                    <td className="text-end custom-td">
                      <span className="print-text">
                        {formatAmount(item.amount)}
                      </span>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td className="custom-td"></td>
                  <td className="text-end custom-td">
                    <span className="print-text">
                      Total Sales (VAT Inclusive)
                    </span>
                  </td>
                  <td className="text-end custom-td">
                    <span className="print-text">
                      {formatAmount(billingDetails.vat_in)}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="custom-td"></td>
                  <td className="text-end print-text custom-td">
                    <span className="print-text">Less: VAT</span>
                  </td>
                  <td className="text-end custom-td">
                    <span className="print-text">
                      {formatAmount(billingDetails.twelve_percent_vat)}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="custom-td"></td>
                  <td className="custom-td">
                    <Row className="d-flex justify-content-between w-100">
                      <span className="print-text">VATable Sales</span>
                      <span className="print-text">Amount: Net of VAT</span>
                    </Row>
                  </td>
                  <td className="text-end print-text custom-td">
                    <span className="print-text">
                      {formatAmount(billingDetails.net_vat)}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="custom-td"></td>
                  <td className="custom-td">
                    <Row className="d-flex justify-content-between w-100">
                      <span className="print-text">VAT Exempty Sales</span>
                      <span className="print-text">Less: Withholding Tax</span>
                    </Row>
                  </td>
                  <td className="text-end print-text custom-td">
                    <span className="print-text">
                      {formatAmount(billingDetails.wht_amount)}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="custom-td"></td>
                  <td className="custom-td">
                    <Row className="d-flex justify-content-between w-100">
                      <span className="print-text">Zero Rated Sales</span>
                      <span className="print-text">Amount Due</span>
                    </Row>
                  </td>
                  <td className="text-end print-text custom-td">
                    <span className="print-text">
                      {formatAmount(billingDetails.amount_due)}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="custom-td"></td>
                  <td className="custom-td">
                    <Row className="d-flex justify-content-between w-100">
                      <span className="print-text">VAT Amount</span>
                      <span className="print-text">Add: VAT</span>
                    </Row>
                  </td>
                  <td className="text-end print-text custom-td">
                    <span className="print-text">
                      {formatAmount(billingDetails.twelve_percent_vat)}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="custom-td"></td>
                  <td className="text-end bold print-text custom-td">
                    <span className="print-text">Total Amount Due</span>
                  </td>
                  <td className="text-end bold print-text custom-td">
                    <span className="print-text">
                      {formatAmount(billingDetails.grand_total)}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Row>
        <Row className="d-flex jusitfy-content-between mt-3">
          <Col xs={16}>
            <Row className="print-text">10 Bklts. (50x3) 0001-0500</Row>
            <Row className="print-text">
              BIR Permit No. OCN: 043AU20240000007827
            </Row>
            <Row className="print-text">Date of ATP: 06-21-2024</Row>
            <Row className="print-text">R-BOS PRINTING PRESS</Row>
            <Row className="print-text">
              NON VAT Reg. TIN: 115-677-573-00000
            </Row>
            <Row className="print-text">
              163-B Bato-Bato St. Commonwealth, 1121 Quezon City,
            </Row>
            <Row className="print-text">
              NCR, Second District, Philippines Tel: 8681-4452/ CP #
              0967-9658201
            </Row>
          </Col>
          <Col xs={8}>
            <Row className="underline-col print-text">By:</Row>
            <Row className="d-flex justify-content-center print-text">
              Customer's Signature
            </Row>
            <Row className="d-flex justify-content-center smaller-print-text">
              Printer's Accreditation No.: 028MP20190000000033
            </Row>
            <Row className="d-flex justify-content-center smaller-print-text">
              Date Issued: July 12, 2019 Expiry Date: July 11, 2024
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
}
