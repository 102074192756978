import React, { useContext, useState } from "react"
import { useLocation } from "react-router-dom"
import { Button, Col, Form, Row, Table } from "react-bootstrap"
import { Select as AntSelect, DatePicker, Select } from "antd"
import { useNavigate, useParams } from "react-router-dom"
import Navbar from "../../Components/Navbar/Navbar"
import trash from "../../Assets/Images/trash.png"
import { toastStyle } from "../../Helpers/Utils/Common"
import toast from "react-hot-toast"
import { UsePostRequest } from "../../Helpers/hooks/UsePostApiCall"
import { UseGetRequest } from "../../Helpers/hooks/GetRequests"
import InputError from "../../Components/InputError/InputError"
import ReactLoading from "react-loading"
import dayjs from "dayjs"
import { Context } from "../../Helpers/Context/Context"

function GeneralLedgerForm({ add, edit, view }) {
  const [isSubmitClicked, setIsSubmitClicked] = useState(false)
  const { state } = useLocation()
  const navigate = useNavigate()
  const { selectedBuId } = useContext(Context)
  const { id, type } = useParams()
  const [inactive, setInactive] = useState(true)
  const [showLoader, setShowLoader] = useState(false)
  const [openForm, setOpenForm] = useState(add || edit)
  const [formValues, setFormValues] = useState({})
  const [costCenterOptions, setCostCenterOptions] = useState([])
  const [accountTypeOptions, setAccountTypeOptions] = useState([])
  const [balanced, setBalance] = useState(true)
  const [journalEntries, setJournalEntries] = useState([
    {
      credit: 0,
      debit: 0,
      account_title: "",
      cost_center: "",
      remarks: "",
    },
    {
      credit: 0,
      debit: 0,
      account_title: "",
      cost_center: "",
      remarks: "",
    },
  ])

  const [isError, setIsError] = useState({
    supplier_id: false,
    requisitioner: false,
    request_date: false,
    due_date: false,
  })

  const toggleOpenForm = () => {
    setOpenForm((prevOpenForm) => !prevOpenForm)
  }

  function AddItem() {
    const newItem = [
      {
        credit: 0,
        debit: 0,
        account_title: "",
        cost_center: "",
        remarks: "",
      },
      {
        credit: 0,
        debit: 0,
        account_title: "",
        cost_center: "",
        remarks: "",
      },
    ]
    setJournalEntries((prevItems) => [...prevItems, ...newItem])
  }

  function handleItemChange(field, value, index) {
    const tempItems = [...journalEntries]
    tempItems[index][field] = value
    if (field === "debit") {
      if (tempItems[index].debit > 0) {
        tempItems[index].credit = 0
      }
    } else if (field === "credit") {
      if (tempItems[index].credit > 0) {
        tempItems[index].debit = 0
      }
    }
    setJournalEntries(tempItems)
    validateJournalEntryBalancing(tempItems)
  }

  function handleRemoveItem(index) {
    setShowLoader(true)
    const newItemList = [...journalEntries]
    newItemList.splice(index, 1)
    setJournalEntries(newItemList)
  }

  function handleSEChange(e) {
    const { name, value } = e.target
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  function handleSubmit() {
    if (add) handleCreate()
    if (edit || view) handleUpdate()
  }

  function validateJournalEntryBalancing(entries) {
    const totalDebit = entries.reduce(
      (sum, entry) => sum + parseFloat(entry.debit || 0),
      0
    )
    const totalCredit = entries.reduce(
      (sum, entry) => sum + parseFloat(entry.credit || 0),
      0
    )
    return totalDebit === totalCredit
  }

  const requiredFields = ["date", "reference_no"]
  async function handleCreate() {
    if (isSubmitClicked) {
      return
    }

    if (validateJournalEntryBalancing(journalEntries) === false) {
      toast.error("Credit and Debit must be balance", { style: toastStyle() })
      return
    }

    // const entryValidationFailed = journalEntries.some(
    //   (entry) =>
    //     entry.debit === "" || entry.credit === "" || entry.account_title === ""
    // )
    // setIsError({ ...isError, journal_entries_content: entryValidationFailed })

    setIsSubmitClicked(true)
    const tempValues = {
      ...formValues,
      date: formValues.date.format("YYYY-MM-DD"),
      business_unit_id: selectedBuId,
    }

    // if (
    //   GeneralValidation(tempValues, setIsError, requiredFields) &&
    //   !entryValidationFailed
    // ) {
    const tempJournalEntries = {
      debit: journalEntries.map((row) => row.debit),
      credit: journalEntries.map((row) => row.credit),
      account_title: journalEntries.map((row) => row.account_title),
      cost_center: journalEntries.map((row) => row.cost_center),
      remarks: journalEntries.map((row) => row.remarks),
    }
    const response = await UsePostRequest(
      "general_ledgers/create",
      {
        ...tempValues,
        ...tempJournalEntries,
      },
      false
    )
    if (response.data) {
      toast.success("Successfully created general ledger", {
        style: toastStyle(),
      })
      setTimeout(() => {
        navigate("/generalledger")
      }, 500)
    } else {
      setIsSubmitClicked(false)
      toast.error("Error in creating general ledger", {
        style: toastStyle(),
      })
    }
    // } else {
    //   toast.error("Required fields cannot be empty", { style: toastStyle() })
    // }
  }

  /** POST API - Edit old opex purchase invoice **/
  async function handleUpdate() {
    if (isSubmitClicked) {
      return
    }
    // const entryValidationFailed = journalEntries.some(
    //   (entry) =>
    //     entry.debit === "" || entry.credit === "" || entry.account_title === ""
    // )
    // setIsError({ ...isError, journal_entries_content: entryValidationFailed })

    setIsSubmitClicked(true)
    // if (
    //   GeneralValidation(tempValues, setIsError, requiredFields) &&
    //   !entryValidationFailed
    // ) {
    const tempValues = {
      ...formValues,
      date: formValues.date.format("YYYY-MM-DD"),
      general_ledger_id: formValues.id,
    }

    const tempJournalEntries = {
      debit: journalEntries.map((row) => row.debit),
      credit: journalEntries.map((row) => row.credit),
      account_title: journalEntries.map((row) => row.account_title),
      cost_center: journalEntries.map((row) => row.cost_center),
      remarks: journalEntries.map((row) => row.remarks),
    }
    const response = await UsePostRequest(
      "general_ledgers/update",
      {
        ...tempValues,
        ...tempJournalEntries,
      },
      false
    )
    if (response.data) {
      toast.success("Successfully updated general ledger", {
        style: toastStyle(),
      })
      setTimeout(() => {
        navigate("/generalledger")
      }, 500)
    } else {
      setIsSubmitClicked(false)
      toast.error("Error in updating general ledger", {
        style: toastStyle(),
      })
    }
    // } else {
    //   toast.error("Required fields cannot be empty", { style: toastStyle() })
    // }
  }

  async function fetchDropdownOptions() {
    const costCenters = await UseGetRequest("cost_centers/search")
    if (costCenters.data) {
      const res = costCenters.data.data.map((row) => {
        return {
          value: row.id,
          label: row.name,
        }
      })
      setCostCenterOptions(res)
    } else {
      setCostCenterOptions([])
    }

    const accountTypes = await UseGetRequest(
      "expense_types/get_all_expense_type"
    )
    // if (accountTypes.data) {
    //   const res = accountTypes.data.data[0].expense_type_subtypes.map((row) => {
    //     return {
    //       value: row.id,
    //       label: row.name,
    //     }
    //   })
    //   setAccountTypeOptions(res)
    // } else {
    //   setAccountTypeOptions([])
    // }
    if (accountTypes.data && Array.isArray(accountTypes.data.data)) {
      let res = []

      accountTypes.data.data.forEach((account) => {
        if (Array.isArray(account.expense_type_subtypes)) {
          const subtypes = account.expense_type_subtypes.map((row) => ({
            value: row.id,
            label: row.name,
          }))
          res = [...res, ...subtypes]
        }
      })

      setAccountTypeOptions(res)
    } else {
      setAccountTypeOptions([])
    }
  }

  React.useEffect(() => {
    if ((edit || view) && state) {
      setFormValues({ ...state, date: dayjs(state.date) })
      setJournalEntries(state.general_ledger_items)
    }
  }, [state])

  React.useEffect(() => {
    fetchDropdownOptions()
  }, [])

  // VALIDATION LOGIC
  const [submittableForm, setSubmittableForm] = useState(false)
  // React.useEffect(() => {
  //   const requiredFieldKeys = ["reference_no", "date"]
  //   requiredFieldKeys.forEach((key) => {
  //     if (
  //       !formValues[key] ||
  //       (formValues[key] &&
  //         typeof formValues[key] === "string" &&
  //         formValues[key]?.trim() === "")
  //     ) {
  //       setSubmittableForm(false)
  //     }
  //   })
  // }, [formValues])

  // React.useEffect(() => {
  //   const itemInvalid = journalEntries.some(
  //     (entry) =>
  //       entry.debit === "" || entry.credit === "" || entry.account_title === ""
  //   )
  //   setSubmittableForm(!itemInvalid)
  // }, [journalEntries])

  //VALIDATION
  React.useEffect(() => {
    const requiredFieldKeys = ["reference_no", "date"]

    // Check if any required form fields are missing or empty
    const hasEmptyRequiredFields = requiredFieldKeys.some(
      (key) =>
        !formValues[key] ||
        (typeof formValues[key] === "string" && formValues[key].trim() === "")
    )

    // Check if any journal entry is invalid
    const hasInvalidJournalEntry = journalEntries.some(
      (entry) =>
        entry.debit === "" || entry.credit === "" || entry.account_title === ""
    )

    // Check if credit and debit are balanced
    const totalDebit = journalEntries.reduce(
      (sum, entry) => sum + parseFloat(entry.debit || 0),
      0
    )
    const totalCredit = journalEntries.reduce(
      (sum, entry) => sum + parseFloat(entry.credit || 0),
      0
    )
    const isBalanced = totalDebit === totalCredit
    setBalance(isBalanced)

    // Set form as submittable if all conditions are met
    setSubmittableForm(
      !hasEmptyRequiredFields && !hasInvalidJournalEntry && isBalanced
    )
  }, [formValues, journalEntries])

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive)
          }}
          active={"FINANCIAL STATEMENTS"}
        />
      </div>
      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        <div className="row">
          <h1 className="page-title mb-4">
            {add && "ADD GENERAL LEDGER"}
            {edit && "EDIT GENERAL LEDGER"}{" "}
          </h1>
        </div>

        {/* content */}

        <div className="edit-form mt-2">
          <Row className="pt-3 mb-2">
            <Col xs={3}>
              <span className="edit-label">
                Date <label className="badge-required">{` *`}</label>
              </span>
              <DatePicker
                format="YYYY-MM-DD"
                className="nc-modal-custom-text w-100"
                value={formValues.date}
                onChange={(e) =>
                  setFormValues((prev) => ({
                    ...prev,
                    date: e,
                  }))
                }
                disabled={!openForm}
              />
              <InputError
                isValid={isError.date}
                message={"Request date is required"}
              />
            </Col>
            <Col xs={3}>
              <span className="edit-label">
                Reference No <label className="badge-required">{` *`}</label>
              </span>
              <Form.Control
                className="nc-modal-custom-input"
                number="text"
                name="reference_no"
                value={formValues.reference_no}
                onChange={(e) => handleSEChange(e)}
                disabled={!openForm}
              />
              <InputError
                isValid={isError.reference_no}
                message={"Reference no. is required"}
              />
            </Col>
            <Col xs={3}>
              <span className="edit-label">
                Amount
                <span className="edit-optional px-2"></span>
              </span>
              <Form.Control
                className="nc-modal-custom-input"
                type="number"
                name="amount"
                value={formValues.amount}
                onChange={(e) => handleSEChange(e)}
                disabled
              />
              <InputError
                isValid={isError.amount}
                message={"Amount is required"}
              />
            </Col>
            <Col xs={3}>
              <span className="edit-label">
                Particulars
                <span className="edit-optional px-2"></span>
              </span>
              <Form.Control
                className="nc-modal-custom-input"
                number="text"
                name="particulars"
                value={formValues.particulars}
                onChange={(e) => handleSEChange(e)}
                disabled={!openForm}
              />
              <InputError
                isValid={isError.particulars}
                message={"Amount is required"}
              />
            </Col>
          </Row>

          <Row className="mt-4">
            <Col xs={12}>
              <span className="edit-label">
                Remarks
                <span className="edit-optional px-2">(Optional)</span>
              </span>
              <Form.Control
                className="nc-modal-custom-input"
                as="textarea"
                name="remark"
                value={formValues.remark}
                onChange={(e) => handleSEChange(e)}
                disabled={!openForm}
              />
            </Col>
          </Row>

          <Row className="mt-4 pt-3">
            <Col xs={12} md={6}>
              <span className="edit-label mb-2"> General Journal Entries</span>
              <label className="badge-required">{` *`}</label>
            </Col>
            <Col xs={12} md={6} className="text-end">
              {!balanced && (
                <label className="badge-required">{`Note: Debit and Credit must be balance`}</label>
              )}
            </Col>
          </Row>
          <Row>
            <div className="purchased-items-table-container overflow-scroll">
              {journalEntries?.length === 0 ? (
                <span>NO DATE</span>
              ) : (
                <>
                  <Table className="purchased-items-table">
                    <thead>
                      <tr>
                        <th>Debit</th>
                        <th>Credit</th>
                        <th>Account Type</th>
                        <th>Cost Center</th>
                        <th>Remarks</th>
                        {journalEntries?.length > 1 && openForm === true && (
                          <th className="display-hide">Action</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {journalEntries?.map((item, index) => {
                        return (
                          <tr key={item.id}>
                            <td className="w-mini">
                              <Form.Control
                                type="number"
                                name="debit"
                                className="w-mini"
                                onWheel={(e) => e.target.blur()}
                                value={item.debit}
                                disabled={
                                  !openForm ||
                                  (item.credit !== "" && item.credit > 0)
                                }
                                onChange={(e) =>
                                  handleItemChange(
                                    "debit",
                                    e.target.value,
                                    index
                                  )
                                }
                              />
                            </td>
                            <td className="w-mini">
                              <Form.Control
                                type="number"
                                name="credit"
                                className="w-mini"
                                onWheel={(e) => e.target.blur()}
                                value={item.credit}
                                disabled={
                                  !openForm ||
                                  (item.debit !== "" && item.debit > 0)
                                }
                                onChange={(e) =>
                                  handleItemChange(
                                    "credit",
                                    e.target.value,
                                    index
                                  )
                                }
                              />
                            </td>
                            <td className="" style={{ width: "20%" }}>
                              <Select
                                showSearch
                                optionFilterProp="label"
                                placeholder="SELECT"
                                className="w-90"
                                value={item.account_title}
                                options={accountTypeOptions}
                                disabled={!openForm}
                                onChange={(e) =>
                                  handleItemChange("account_title", e, index)
                                }
                              />
                            </td>

                            <td className="" style={{ width: "15%" }}>
                              <Select
                                showSearch
                                optionFilterProp="label"
                                placeholder="SELECT %"
                                className="w-90"
                                value={item.cost_center}
                                options={costCenterOptions}
                                disabled={!openForm}
                                onChange={(e) =>
                                  handleItemChange("cost_center", e, index)
                                }
                              />
                            </td>
                            <td className="w-lg">
                              <Form.Control
                                type="text"
                                name="remarks"
                                className="w-lg"
                                value={item.remarks}
                                disabled={!openForm}
                                onChange={(e) =>
                                  handleItemChange(
                                    "remarks",
                                    e.target.value,
                                    index
                                  )
                                }
                              />
                            </td>
                            {journalEntries?.length > 1 &&
                              openForm === true && (
                                <td>
                                  <img
                                    src={trash}
                                    onClick={() => handleRemoveItem(index)}
                                    className="cursor-pointer"
                                    alt=""
                                  />
                                </td>
                              )}
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                  <Row className="pt-3 PO-add-item">
                    <Button type="button" onClick={() => AddItem()}>
                      Add Item
                    </Button>
                  </Row>
                </>
              )}
              <InputError
                isValid={isError.journal_entries}
                message={"Please fill in required fields"}
              />
            </div>
          </Row>

          <div
            className={`d-flex pt-5 pb-3 ${
              view ? "justify-content-between" : "justify-content-end"
            }`}
          >
            {view && (
              <button
                type="button"
                className="button-secondary me-3"
                onClick={toggleOpenForm}
              >
                {!openForm ? " Edit Ledger" : " Close Edit"}
              </button>
            )}
            <div className="d-flex flex-row">
              <button
                type="button"
                className="button-secondary me-3"
                onClick={() => navigate("/generalledger")}
              >
                Close
              </button>
              {!isSubmitClicked && (
                <button
                  type="button"
                  className="button-primary"
                  onClick={() => {
                    formValues["is_save"] = 0
                    handleSubmit()
                  }}
                  disabled={!submittableForm}
                >
                  {type === "for_approval" ? "Done" : "Submit"}
                </button>
              )}
              {isSubmitClicked && (
                <div className="button-primary d-flex justify-content-center">
                  <ReactLoading
                    type="bubbles"
                    color="#FFFFFF"
                    height={50}
                    width={50}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

GeneralLedgerForm.defaultProps = {
  add: false,
  edit: false,
  defaultValues: {},
}

export default GeneralLedgerForm
