import React, { useState, useEffect, useRef, useContext } from "react"
import { Col, Form, Row, Tab, Tabs, Modal, Container } from "react-bootstrap"
import { Row as AntRow, Select, Button } from "antd"

import { useNavigate } from "react-router-dom"
import toast from "react-hot-toast"
import Navbar from "../../Components/Navbar/Navbar"
import Table from "../../Components/TableTemplate/OneTable"
// import Table from "../../Components/TableTemplate/Table";
import DeleteModal from "../../Components/Modals/DeleteModal"
import {
  toastStyle,
  TokenExpiry,
  dateFormat,
  // refreshPage,
  // capitalizeFirstLetter,
  formatAmount,
  formatAmountInt,
  removeUnderscoreToSentence,
  refreshPage,
} from "../../Helpers/Utils/Common"
import {
  deleteBillingSI,
  getAllBillingSI,
} from "../../Helpers/apiCalls/BillingApi"
import moment from "moment"
import { UsePostRequest } from "../../Helpers/hooks/UsePostApiCall"
import { UseGetRequest } from "../../Helpers/hooks/GetRequests"
import PdfContent from "./PdfContent"
import jsPDF from "jspdf"
import html2canvas from "html2canvas"
import { saveAs } from "file-saver"
import { Context } from "../../Helpers/Context/Context"

export default function BillingSalesInvoice() {
  let navigate = useNavigate()
  const { selectedBuId } = useContext(Context)
  const pdfRef = useRef()
  const [inactive, setInactive] = useState(true)
  const [allData, setAllData] = useState([])
  const [bankOptions, setBankOptions] = useState([])
  const dateToday = moment().format("YYYY-MM-D")
  // const [sending, setSending] = useState(false);
  const [showLoader, setShowLoader] = useState(false)
  const [selectedRow, setSelectedRow] = useState({})
  const [openViewModal, setOpenViewModal] = useState(false)
  // const [totalBalance, setTotalBalance] = useState(0);
  const [openPaymentModal, setOpenPaymentModal] = useState(false)
  const [paymentForm, setPaymentForm] = useState({
    payment_date: dateToday,
    bank_id: "",
    paid_amount: 0,
    balance: 0,
  })
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const handleCloseDeleteModal = () => setShowDeleteModal(false)
  const [searchTerm, setSearchTerm] = useState("")
  const [selectedIds, setSelectedIds] = useState([])
  const [readyToSend, setReadyToSend] = useState(false)

  /* FILTER CONFIGS */
  const [filterConfig, setFilterConfig] = useState({
    status: "pending",
    project_id: "",
    company: "",
    term: "",
    business_unit_id: selectedBuId,
  })

  // async function handleSentToClient(id) {
  //   handleSendToClient(id, "sent");
  // }

  function resetValues() {
    setSelectedRow({})
    setPaymentForm({
      payment_date: dateToday,
      payment_method: "cash",
      paid_amount: 0,
      bank_id: "",
      balance: 0,
    })
    // setTotalBalance(0);
    setSelectedIds([])
  }

  const handleTabSelect = (tabKey) => {
    var newFilterConfig = {
      status: tabKey,
      business_unit_id: selectedBuId,
    }

    setAllData([])
    resetValues()

    switch (tabKey) {
      case "pending":
        newFilterConfig.status = "pending"
        newFilterConfig.payment_status = "" // both open and closed bills
        setFilterConfig(() => {
          return newFilterConfig
        })
        break
      case "sent":
        newFilterConfig.status = "sent"
        newFilterConfig.payment_status = "" // both open and closed bills
        setFilterConfig(() => {
          return newFilterConfig
        })
        break
      case "open_bill":
        newFilterConfig.status = "open_bill"
        newFilterConfig.payment_status = ""
        setFilterConfig(() => {
          return newFilterConfig
        })
        break
      case "closed_bill":
        const date = new Date()
        date.setDate(date.getDate() - 7)

        newFilterConfig.status = "closed_bill"
        newFilterConfig.payment_status = ""
        newFilterConfig.date_from = new Date(date)
        newFilterConfig.date_to = new Date()
        setFilterConfig(() => {
          return newFilterConfig
        })
        break
      default:
        break
    }
  }

  async function fetchData() {
    setShowLoader(true)
    setAllData([])

    const response = await getAllBillingSI(filterConfig)
    if (response.error) {
      if (response.error.data.status === 401) {
        TokenExpiry(response.error)
      }
    } else {
      var allData = response?.data?.data?.map((data) => {
        let info = data
        let tempPaid = parseFloat(data.grand_total) - parseFloat(data.balance)
        info.formatted_status = removeUnderscoreToSentence(data.status)
        info.invoice_date = dateFormat(data.invoice_date)
        info.grand_total = formatAmount(data.grand_total)
        info.balance = formatAmount(data.balance)
        info.paid_amount = formatAmount(tempPaid)
        return info
      })
      setAllData(allData)
    }

    setShowLoader(false)
  }

  async function handleDeletePI() {
    const response = await deleteBillingSI(selectedRow.id)

    if (response.data.status === "success") {
      toast.success("Billing Sales Invoice Deleted Successfully!", {
        style: toastStyle(),
      })
      setShowDeleteModal(false)
      fetchData()
    } else {
      toast.error("Error Deleting Billing Sales Invoice", {
        style: toastStyle(),
      })
    }
  }

  // async function markPending() {
  //   const payload = {
  //     billing_sales_invoice_id: selectedRow.id,
  //     new_status: "pending",
  //     attachment: null,
  //   }
  //   const formValues = Object.entries(payload).map(([key, value]) => ({key, value}))
  //   const response = await UsePostRequest(
  //     "billing_sales_invoices/change_status",
  //     formValues, true
  //   );
  // }

  async function handleSendToClient(passedId, status) {
    if (pdfRef.current) {
      const element = pdfRef.current
      html2canvas(element, { scale: 2.5 }).then(async (canvas) => {
        const imgData = canvas.toDataURL("image/jpeg", 1)
        const imgWidth = 210
        const imgHeight = (canvas.height * imgWidth) / canvas.width
        const pdf = new jsPDF("", "mm", "a4")
        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight)
        const pdfBlob = pdf.output("blob")
        const pdfFile = new File(
          [pdfBlob],
          `Billing_Sales_Invoice_${selectedRow.id}.pdf`,
          { type: "application/pdf" }
        )
        // saveAs(pdfFile, `Billing_Sales_Invoice_${selectedRow.id}.pdf`)
        // setTimeout(() => {refreshPage()}, 500)

        const payload = {
          billing_sales_invoice_id: passedId,
          new_status: status,
          attachment: pdfFile,
        }
        const formValues = Object.entries(payload).map(([key, value]) => ({
          key,
          value,
        }))
        const response = await UsePostRequest(
          "billing_sales_invoices/change_status",
          formValues,
          true
        )
        if (response.data) {
          toast.success("Successfully sent email", {
            style: toastStyle(),
          })
          resetValues()
          setFilterConfig((prev) => ({
            ...prev,
            status: "sent",
            tab: "sent",
          }))
          handleTabSelect("sent")
        } else {
          toast.error("Error in updating status", {
            style: toastStyle(),
          })
        }
      })
    }
  }

  async function handleSavePayment() {
    const payload = {
      ...paymentForm,
      billing_sales_invoice_id: selectedRow.id,
    }
    const response = await UsePostRequest(
      "billing_sales_invoice_payments/create",
      payload
    )
    if (response.data) {
      toast.success("Successfully created billing sales invoice payment", {
        style: toastStyle(),
      })
      resetValues()
      setOpenPaymentModal(false)
      setFilterConfig((prev) => ({
        ...prev,
        status: "closed_bill",
        tab: "closed_bill",
      }))
    } else {
      toast.error("Error creating billing sales invoice payment", {
        style: toastStyle(),
      })
    }
  }

  function renderPdfContent() {
    if (selectedRow && selectedRow.id && selectedRow.status === "pending") {
      return (
        <PdfContent
          billingId={selectedRow.id}
          setReadyToSend={setReadyToSend}
        />
      )
    }
  }

  useEffect(() => {
    renderPdfContent()
  }, [selectedRow])

  useEffect(() => {
    fetchData()
  }, [filterConfig])

  useEffect(() => {
    async function fetchBankOptions() {
      const response = await UseGetRequest("banks/search")
      if (response.data) {
        const res = response.data.data.map((row) => {
          return {
            value: row.id,
            label: row.name,
          }
        })
        setBankOptions(res)
      } else {
        setBankOptions([])
      }
    }
    fetchBankOptions()
  }, [])

  function commonTabContent(passedTab) {
    return (
      <>
        <div className="sales-tbl">
          <Table
            tableHeaders={[
              "SI NO.",
              "INVOICE DATE",
              "PROJECT NAME",
              // "STATUS",
              "REMARKS",
              "AMOUNT",
            ]}
            headerSelector={[
              "id",
              "invoice_date",
              "project_name",
              // "formatted_status",
              "remark",
              "grand_total",
            ]}
            tableData={allData}
            showLoader={showLoader}
            handleRowClick={(row) => {
              setSelectedRow({
                ...row,
                invoice_date: moment(row.invoice_date).format("YYYY-MM-DD"),
              })
              setOpenViewModal(true)
            }}
            handleRowHover={(row) => {
              setSelectedRow({
                ...row,
                invoice_date: moment(row.invoice_date).format("YYYY-MM-DD"),
              })
              setOpenViewModal(true)
            }}
            isPointer
            selectedIds={selectedIds}
            onSelectedIdsChange={(ids) => {
              setSelectedIds(ids)
            }}
          />
        </div>
      </>
    )
  }

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive)
          }}
          active={"BILLING"}
        />
      </div>

      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        {/* headers */}
        <Row className="mb-4">
          <Col xs={6}>
            <h1 className="page-title">BILLING SALES INVOICE</h1>
          </Col>
          <Col xs={6} className="d-flex justify-content-end">
            <input
              type="search"
              placeholder="Search project..."
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setFilterConfig((prev) => ({ ...prev, term: searchTerm }))
                }
              }}
              className="search-bar"
            />
            <button
              className="add-btn"
              onClick={() => navigate("/billingsalesinvoice/add")}
            >
              Add
            </button>
          </Col>
        </Row>

        <Tabs
          activeKey={filterConfig.status}
          defaultActiveKey={filterConfig.status}
          id="SE-tabs"
          onSelect={handleTabSelect}
          className="TabStyle1"
        >
          <Tab eventKey="pending" title="PENDING" className="TabStyle2">
            {commonTabContent()}
          </Tab>
          <Tab eventKey="sent" title="SENT" className="TabStyle2">
            <div className="sales-tbl">
              <Table
                tableHeaders={[
                  "SI NO.",
                  "INVOICE DATE",
                  "PROJECT NAME",
                  // "STATUS",
                  "REMARKS",
                  "AMOUNT",
                ]}
                headerSelector={[
                  "id",
                  "invoice_date",
                  "project_name",
                  // "formatted_status",
                  "remark",
                  "grand_total",
                ]}
                tableData={allData}
                showLoader={showLoader}
                handleRowClick={(row) => {
                  // let tempBalance = 0;
                  //   if (selectedIds.includes(row.id)) {
                  //     tempBalance = totalBalance;
                  //   } else {
                  //     setSelectedIds([...selectedIds, row.id]);
                  //     tempBalance = formatAmountInt(row.grand_total) + totalBalance;
                  //   }
                  const tempBalance = formatAmountInt(row.balance)
                  setPaymentForm((prev) => ({
                    ...prev,
                    paid_amount: tempBalance,
                    balance: tempBalance,
                  }))
                  setSelectedRow({
                    ...row,
                    invoice_date: moment(row.invoice_date).format("YYYY-MM-DD"),
                  })
                  setOpenViewModal(true)
                }}
                handleRowHover={(row) => {
                  // let tempBalance = 0;
                  //   if (selectedIds.includes(row.id)) {
                  //     tempBalance = totalBalance;
                  //   } else {
                  //     setSelectedIds([...selectedIds, row.id]);
                  //     tempBalance = formatAmountInt(row.grand_total) + totalBalance;
                  //   }
                  const tempBalance = formatAmountInt(row.balance)
                  setPaymentForm((prev) => ({
                    ...prev,
                    paid_amount: tempBalance,
                    balance: tempBalance,
                  }))
                  setSelectedRow({
                    ...row,
                    invoice_date: moment(row.invoice_date).format("YYYY-MM-DD"),
                  })
                  setOpenViewModal(true)
                }}
                isPointer
                selectedIds={selectedIds}
                onSelectedIdsChange={(ids) => {
                  setSelectedIds(ids)
                }}
                // setTotalBalance={setTotalBalance}
              />
            </div>
          </Tab>

          <Tab eventKey="open_bill" title="OPEN" className="TabStyle2">
            <div className="sales-tbl">
              <Table
                tableHeaders={[
                  "SI NO.",
                  "INVOICE DATE",
                  "PROJECT NAME",
                  // "STATUS",
                  "REMARKS",
                  "AMOUNT",
                  "PAID AMOUNT",
                  "BALANCE",
                ]}
                headerSelector={[
                  "id",
                  "invoice_date",
                  "project_name",
                  // "formatted_status",
                  "remark",
                  "grand_total",
                  "paid_amount",
                  "balance",
                ]}
                tableData={allData}
                showLoader={showLoader}
                handleRowClick={(row) => {
                  const tempBalance = formatAmountInt(row.balance)
                  setPaymentForm((prev) => ({
                    ...prev,
                    paid_amount: tempBalance,
                    balance: tempBalance,
                  }))
                  setSelectedRow({
                    ...row,
                    invoice_date: moment(row.invoice_date).format("YYYY-MM-DD"),
                  })
                  setOpenViewModal(true)
                }}
                handleRowHover={(row) => {
                  const tempBalance = formatAmountInt(row.balance)
                  setPaymentForm((prev) => ({
                    ...prev,
                    paid_amount: tempBalance,
                    balance: tempBalance,
                  }))
                  setSelectedRow({
                    ...row,
                    invoice_date: moment(row.invoice_date).format("YYYY-MM-DD"),
                  })
                  setOpenViewModal(true)
                }}
                isPointer
                selectedIds={selectedIds}
                onSelectedIdsChange={(ids) => {
                  setSelectedIds(ids)
                }}
              />
            </div>
          </Tab>

          <Tab eventKey="closed_bill" title="CLOSED" className="TabStyle2">
            {commonTabContent()}
          </Tab>
        </Tabs>

        <div style={{ position: "absolute", left: "-999999px" }}>
          <div ref={pdfRef}>{renderPdfContent()}</div>
        </div>
      </div>

      <DeleteModal
        show={showDeleteModal}
        onHide={() => handleCloseDeleteModal()}
        text="billing sales invoice"
        onDelete={() => handleDeletePI()}
      />

      <Modal
        footer={null}
        show={openViewModal}
        onHide={() => {
          setOpenViewModal(false)
          resetValues()
        }}
        size="lg"
        centered
      >
        <Modal.Body>
          <div className="col-sm-12">
            <span className="custom-modal-body-title">
              REVIEW BILLING SALES INVOICE
            </span>
          </div>
          <Container fluid className="modal-cont justify-content-center px-4">
            <Row className="mt-3">
              <Col xs={4}>
                <span className="review-form-label">Invoice Date</span>
                <Form.Control
                  className="review-form-input"
                  type="date"
                  value={selectedRow.invoice_date ?? ""}
                  disabled
                />
              </Col>
              <Col xs={4}>
                <span className="review-form-label">Project Name</span>
                <Form.Control
                  className="review-form-input"
                  type="text"
                  value={selectedRow.project_name ?? ""}
                  disabled
                />
              </Col>
              <Col xs={4}>
                <span className="review-form-label">Amount</span>
                <Form.Control
                  className="review-form-input"
                  type="text"
                  value={selectedRow.grand_total ?? ""}
                  disabled
                />
              </Col>
            </Row>
          </Container>

          <AntRow className="d-flex justify-content-between mt-5 px-4">
            <button
              className="button-primary"
              onClick={() => {
                window.open(
                  "/billingsalesinvoice/print/" + selectedRow.id,
                  "_blank"
                )
              }}
            >
              View
            </button>
            <div>
              <button
                onClick={() => {
                  resetValues()
                  setOpenViewModal(false)
                }}
                className="me-2 button-default"
              >
                Cancel
              </button>
              {selectedRow.status === "pending" && (
                <button
                  className="button-warning-fill me-2"
                  onClick={() => {
                    setOpenViewModal(false)
                    setShowDeleteModal(true)
                  }}
                >
                  Delete
                </button>
              )}
              {selectedRow.status === "pending" && (
                <button
                  className="button-primary me-2"
                  onClick={() => {
                    setOpenViewModal(false)
                    navigate("edit/" + selectedRow.id)
                  }}
                >
                  Edit
                </button>
              )}
              {selectedRow.status === "pending" && (
                <Button
                  className="button-primary"
                  onClick={() => {
                    setOpenViewModal(false)
                    // handleSentToClient(selectedRow.id);
                    handleSendToClient(selectedRow.id, "sent")
                  }}
                  disabled={!readyToSend}
                  loading={!readyToSend}
                >
                  Send
                </Button>
              )}
              {(selectedRow.status === "sent" ||
                selectedRow.status === "open_bill") && (
                <button
                  className="button-primary"
                  onClick={() => {
                    setOpenViewModal(false)
                    setOpenPaymentModal(true)
                    // markPending()
                  }}
                >
                  Pay
                </button>
              )}
            </div>
          </AntRow>
        </Modal.Body>
      </Modal>

      <Modal
        footer={null}
        show={openPaymentModal}
        onHide={() => {
          setOpenPaymentModal(false)
          setFilterConfig((prev) => ({ ...prev, status: "sent" }))
        }}
        size="lg"
        centered
      >
        <Modal.Body>
          <span className="custom-modal-body-title">ADD PAYMENT</span>
          <Container fluid className="modal-cont justify-content-center px-4">
            <Row className="mt-3">
              <Col xs={4}>
                <span className="review-form-label">
                  Payment Date<span className="color-red"> *</span>
                </span>
                <Form.Control
                  className="review-form-input"
                  type="date"
                  value={paymentForm.payment_date}
                  onChange={(e) =>
                    setPaymentForm((prev) => ({
                      ...prev,
                      payment_date: e.target.value,
                    }))
                  }
                />
              </Col>
              <Col xs={4}>
                <span className="review-form-label">Balance</span>
                <Form.Control
                  className="review-form-input"
                  type="text"
                  // value={formatAmount(paymentForm.paid_amount)}
                  value={formatAmount(selectedRow.balance)}
                  disabled
                />
              </Col>
              <Col xs={4}>
                <span className="review-form-label">
                  Paid Amount<span className="color-red"> *</span>
                </span>
                <Form.Control
                  className="review-form-input"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  value={paymentForm.paid_amount}
                  onChange={(e) =>
                    setPaymentForm((prev) => ({
                      ...prev,
                      paid_amount: e.target.value,
                    }))
                  }
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={4}>
                <span className="review-form-label">
                  Bank<span className="color-red"> *</span>
                </span>
                <Select
                  placeholder="Select bank"
                  className="w-100"
                  options={bankOptions}
                  value={paymentForm.bank_id}
                  dropdownStyle={{ zIndex: 10000 }}
                  onChange={(e) =>
                    setPaymentForm((prev) => ({ ...prev, bank_id: e }))
                  }
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <span className="review-form-label">Remarks</span>
              <Form.Control
                className="review-form-input"
                as="textarea"
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setPaymentForm((prev) => ({
                    ...prev,
                    remarks: e.target.value,
                  }))
                }
              />
            </Row>
          </Container>
          <AntRow className="d-flex justify-content-end mt-5 px-4">
            <div>
              <button
                onClick={() => {
                  setOpenPaymentModal(false)
                  resetValues()
                  setFilterConfig((prev) => ({ ...prev, status: "sent" }))
                }}
                className="me-2 button-default"
              >
                Cancel
              </button>

              <button
                className="button-primary"
                onClick={handleSavePayment}
                disabled={
                  paymentForm.paid_amount === "" ||
                  paymentForm.bank_id === "" ||
                  parseFloat(paymentForm.paid_amount) >
                    parseFloat(paymentForm.balance) ||
                  parseFloat(paymentForm.paid_amount) <= 0
                  // formatAmountInt(paymentForm.paid_amount)!==paymentForm.balance
                }
              >
                SAVE
              </button>
            </div>
          </AntRow>
        </Modal.Body>
      </Modal>
    </div>
  )
}
